<div [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="modal-header">
        <h4 class="modal-title">Notitie wijzigen</h4>
        <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
    </div>
    <div class="modal-body p-1"> 
        <div class="row">
            <div class="col-12 p-5">
                Notitie:<br/>
                <textarea class="form-control" type="text" placeholder="" [(ngModel)]="text"></textarea>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="confirm()" class="btn btn-success with-icon">
            Opslaan <i class="bi bi-arrow-right"></i>
        </button>
    </div>  
</div>