<section class="table-grid" *ngIf="items && items.length > 0">
	<section class="table-content">
		<table class="table table-vertical-align-top desktop" style="margin-bottom:0;">
			<thead class="thead-dark">
				<tr>
					<th>Project</th>
					<th width="180">Datum</th>
					<th>Contactpersoon</th>
					<th width="140">Project nummer</th>
					<th>Omschrijving</th>
					<th>Weeknummer</th>
					<th>Bedrag ingediend</th>
					<th>IO nummer</th>
					<th>Factuurnummer</th>
					<th>Status</th>
					<th>Status laatst gewijzigd</th>
					<th>Actie</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let item of items; let index = index; let last = last;" class="user-hour-row">
					<td>
						{{item.projectNumber}}
					</td>
					<td>
						{{item.costsSentAtText}}
					</td>
					<td>
						{{item.contactPersonName}}
					</td>
					<td>
						{{item.projectNumber}}
					</td>
					<td>
						{{item.comment}}
					</td>
					<td>
						{{item.weekNumber}}
					</td>
					<td>
						€ {{toDutchFormat(item.amountExclVat)}}
					</td>
					<td>{{item.projectIoNumber}}</td>
					<td>
						{{item.invoiceNumber}}
					</td>
					<td>
						{{item.statusText}}
					</td>
					<td>
						{{item.statusChangedAtText}}
					</td>
					<td style="display: flex;flex-direction: column;">
						<span (click)="edit(item)" style="cursor: pointer;"><i
								class="bi bi-pencil-square empty"></i></span>
						<span (click)="remove(item)" style="cursor: pointer;"><i
								class="bi bi-trash empty"></i></span>
					</td>
				</tr>
				<tr>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td>Totaal:</td>
					<td>
						€ {{toDutchFormat(total)}}
					</td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
					<td></td>
				</tr>
			</tbody>
		</table>
	</section>
</section>

<section class="p-5">
	<p class="text-center text-muted" *ngIf="items && items.length < 1">Geen termijnstaten beschikbaar</p>
	<p class="text-center">
		<button type="button" class="btn btn-success" (click)="addRow()">
			<i class="bi bi-plus-lg text-white me-2"></i> Termijnstaat toevoegen
		</button>
	</p>
</section>
<section class="p-5" *ngIf="!items">
	laden
</section>