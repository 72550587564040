import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';


@Component({
	selector: 'app-week-form',
	templateUrl: './week-form.component.html',
	styleUrls: ['./week-form.component.scss']
})
export class WeekFormComponent implements OnInit {
	@Input() public title;
	@Input() public targetUrl;
	@Input() public button;
	@Input() public message;
	@Input() public year;
	@Input() public week;
	@Output() passEntry: EventEmitter<any> = new EventEmitter();

	public loaderOverlay;
	public years = [
	];

	public weeks = [];

	constructor(
		public activeModal: NgbActiveModal,
		public app: AppService,
		public api: ApiConnectionService
	) {
		var curr = new Date();
		let maxYear = curr.getFullYear();
		maxYear++;

		for (let i = maxYear; i > 2021; i--) {
			let str = i.toString();
			this.years.push(str);
		}

		this.updateWeeks(new Date().getFullYear());
	}

	private getWeeksInYear(year: number): number {
		const lastDayOfYear = new Date(year, 11, 31); // December 31st
		const day = lastDayOfYear.getDay();

		// Check if the last day of the year is part of the 53rd week
		return (day === 4 || (day === 3 && this.isLeapYear(year))) ? 53 : 52;
	}

	private isLeapYear(year: number): boolean {
		return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
	}

	public updateWeeks(year: number): void {
		this.weeks = []; // Reset weeks array
		const numberOfWeeks = this.getWeeksInYear(year);
		for (let i = 1; i <= numberOfWeeks; i++) {
			let str = i.toString();
			if (i < 10) {
				str = '0' + i;
			}
			this.weeks.push(str);
		}
	}

	ngOnInit(): void {
		const currentDate = new Date();
		this.year = currentDate.getFullYear();
		this.updateWeeks(this.year);

		const startDate = new Date(this.year, 0, 1);
		const days = Math.floor((currentDate.getTime() - startDate.getTime()) /
			(24 * 60 * 60 * 1000));
		const weekNumber = Math.ceil(days / 7);
		this.week = weekNumber;
	}

	confirm() {
		let self = this;

		self.loaderOverlay = true;
		self.app.setLoaderStatus(true);

		this.api.read(self.targetUrl + '/' + self.week + '/' + self.year + '').then(function (response) {
			self.loaderOverlay = false;
			self.app.setLoaderStatus(false);
			self.app.setSystemMessageLoading(true);
			self.app.setSystemMessageText('Week toegevoegd');
			self.app.setSystemMessageType('SUCCESS');
			self.activeModal.close(response);
		}).catch(function (response) {
			self.loaderOverlay = false;
			self.app.setLoaderStatus(false);
			self.app.setSystemMessageLoading(true);
			self.app.setSystemMessageText(response['error']['message']);
			self.app.setSystemMessageType('DANGER');
		});
	}

	onYearChange(selectedYear: string): void {
		this.year = parseInt(selectedYear, 10);
		this.updateWeeks(this.year);
	}

	cancel() {
		this.activeModal.close(false);
	}

	close() {
		this.activeModal.close(false);
	}

	dateString(week) {
		var curr = new Date; // get current date

		let y = this.year;
		var d = new Date("Jan 01, " + y + " 01:00:00");
		var dayMs = (24 * 60 * 60 * 1000);
		var offSetTimeStart = dayMs * (d.getDay() - 1);
		var w = d.getTime() + 604800000 * (week - 1) - offSetTimeStart; //reducing the offset here
		var n1 = new Date(w);
		var n2 = new Date(w + 518400000);

		let day = (n1.getDate() < 10 ? '0' + n1.getDate().toString() : n1.getDate().toString());
		let month = n1.getMonth();
		month = (month + 1);
		let monthStr = (month < 10 ? '0' + month.toString() : month.toString());

		var firstday = day + '-' + monthStr + '-' + n1.getFullYear();
		return firstday;
	}
}