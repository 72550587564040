import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganisationsService } from 'src/app/services/organisations.service';

@Component({
	selector: 'app-modal-swap-organisation',
	templateUrl: './modal-swap-organisation.component.html',
	styleUrls: ['./modal-swap-organisation.component.scss']
})
export class ModalSwapOrganisationComponent implements OnInit {
	@Input() public message;
	@Input() public organisations;
	@Output() passEntry: EventEmitter<any> = new EventEmitter();

	public searchVal = "";

	constructor(
		public activeModal: NgbActiveModal,
		public organisationsService: OrganisationsService
	) { }

	public selectedOrganisation = [];

	ngOnInit(): void {
		setTimeout(function () {
			document.getElementById('delete-btn').focus();
		}, 100);

		this.organisationsService.read('organisations').then((response) => {
			this.organisations = response['items'];
		}).catch(function (error) {
		});
	}

	ngAfterViewInit() {
	}

	confirm() {
		this.activeModal.close(true);
	}

	cancel() {
		this.activeModal.close(false);
	}

	close() {
		this.activeModal.close(false);
	}

	toggleList(user) {
		this.selectedOrganisation = user;
	}

	canShow(username) {
		if (this.searchVal != '') {
			if ((username).toLowerCase().includes(this.searchVal.toLowerCase())) {
				return true;
			}
			return false;
		} else {
			return true;
		}
	}
}
