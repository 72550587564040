import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { AuthenticationService } from 'src/app/services/core/authentication.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  item;
  changepassword = false;
  errors: any = [];
  passwordValid;
  password;
  errorPassword;
  loaderOverlay = false;

  constructor(
    private authenticationService: AuthenticationService,
    private api: ApiConnectionService,
    private app: AppService
  ) {	
    let self = this;
    self.authenticationService.data.subscribe(data => {
    if (Object.keys(data).length) {
      self.item = {};
      self.item['firstname'] = data['firstname'];
      self.item['lastname'] = data['lastname'];
      self.item['filePath'] = data['filePath'];
      self.item['email'] = data['email'];
      self.item['role'] = data['role'];
    } else {
      self.item = false;
    }
  });
  }

  ngOnInit(): void {
	this.app.setNavKey('my-profile');
  }

	save() {
		let self = this;
		self.errors = [];

		self.item.changepassword = this.changepassword;

		let item = JSON.parse(JSON.stringify(self.item));

		if (typeof(item['adminpassword']) === 'undefined') {

		} else {
			item['adminpassword'] = encodeURIComponent(item['adminpassword']);
		}

		if (typeof(item['repeatpassword']) === 'undefined') {

		} else {
			item['repeatpassword'] = encodeURIComponent(item['repeatpassword']);
		}

		if (typeof(item['newpassword']) === 'undefined') {

		} else {
			item['newpassword'] = encodeURIComponent(item['newpassword']);
		}

    self.loaderOverlay = true;
    self.app.setLoaderStatus(true);
		this.api.save('users/saveProfile', item).then((response) => {
			(window as any).scroll({
			  top: 200,
			  left: 0,
			  behavior: 'smooth'
			});

			self.changepassword = false;
			self.item.changepassword = false;

			self.item['adminpassword'] = null;
			self.item['newpassword'] = '';
			self.item['repeatpassword'] = '';

			self.passwordValid = false;
			self.password = '';
			self.errorPassword = false;

      self.loaderOverlay = false;

      self.app.setLoaderStatus(false);
      self.app.setSystemMessageLoading(true);
      self.app.setSystemMessageType('SUCCESS');
      self.app.setSystemMessageText('Profiel opgeslagen');

		}).catch(function (response) {
      self.loaderOverlay = false;
			for (let error in response['error']['errors']) {
				self.errors[error] = response['error']['errors'][error];
			}

      self.loaderOverlay = false;
      self.app.setLoaderStatus(false);
      self.app.setSystemMessageLoading(true);
      self.app.setSystemMessageType('DANGER');
      self.app.setSystemMessageText('Opslaan mislukt');
		});
	}

	checkChangepassword(event) {
		if (this.changepassword === true) {
			this.changepassword = false;
		} else {
			this.changepassword = true;
		}
	}

	checkPassword(event) {
		if (event.length < 4) {
			this.passwordValid = false;
			this.errorPassword = true;
		} else {
			this.passwordValid = true;
			this.errorPassword = false;
		}
	}
}
