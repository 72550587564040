import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { ConfigService } from 'src/app/services/core/config.service';

@Component({
	selector: 'app-edit-category-name-tree',
	templateUrl: './edit-category-name-tree.component.html',
	styleUrls: ['./edit-category-name-tree.component.scss']
})
export class EditCategoryNameTreeComponent implements OnInit {
	@Input() public categoryId;
	@Input() public target;
	@Input() public newRank = 'ADMIN';
	@Output() passEntry: EventEmitter<any> = new EventEmitter();

	public loaderOverlay = false;
	public statusOptions;
	public selectedStatus = 'NVT';
	public currentTab = 'info';

	public newName = '';
	public curName= '';

	constructor(
		public activeModal: NgbActiveModal,
		public appService: AppService,
		public api: ApiConnectionService,
		public config: ConfigService
	) {

	}

	ngOnInit(): void {
		this.newName = this.curName;
	}

	confirm() {
		let self = this;

		self.loaderOverlay = true;

		self.newName = self.newName.replace(/[ .:]$/, "");

		self.appService.setLoaderStatus(true);
		return this.api.save('projectFiles/editCategory/' + self.categoryId, {
			name: self.newName,
			role: self.newRank,
		}).then((response) => {
			self.loaderOverlay = false;

			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageType('SUCCESS');
			self.appService.setLoaderStatus(false);
			self.loaderOverlay = false;

			self.activeModal.close(true);

		}).catch(function (response) {
			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageText('DEFAULT_ERROR');
			self.appService.setSystemMessageType('DANGER');
			self.appService.setLoaderStatus(false);
			self.loaderOverlay = false;

			self.activeModal.close(true);
		});
	}

	cancel() {
		this.activeModal.close(false);
	}

	close() {
		this.activeModal.close(false);
	}

	delete() {
		let self = this;

		self.loaderOverlay = true;

		self.appService.setLoaderStatus(true);
		return this.api.save('projectFiles/deleteCategory/' + self.categoryId, {}).then((response) => {
			self.loaderOverlay = false;

			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageType('SUCCESS');
			self.appService.setLoaderStatus(false);
			self.loaderOverlay = false;

			self.activeModal.close(true);

		}).catch(function (response) {
			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageText('DEFAULT_ERROR');
			self.appService.setSystemMessageType('DANGER');
			self.appService.setLoaderStatus(false);
			self.loaderOverlay = false;

			self.activeModal.close(true);
		});
	}
}
