<div class="modal-header">
	<h4 class="modal-title" id="modal-basic-title">Verwijderen</h4>
    <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
</div>
<div class="modal-body">
	{{message}}
	<input type="text" style="opacity:0;">
</div>
<div class="modal-footer">
	<button (click)="cancel()" type="submit" class="btn btn btn-link" style="text-decoration:none ! important;color:#000000;">Annuleren</button>
	<button (click)="confirm()" type="submit" class="btn btn-danger" id="delete-btn">Verwijderen</button>
</div>