<div class="modal-header">
	<h5 class="modal-title">Wijzig status</h5>
    <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
</div>
<div class="modal-body">
    <div class="py-2" (click)="save('QUOTE_OPEN')" style="cursor:pointer;">
        <span class="btn btn-project-quote-accepted">Offerte open</span>
    </div>
    <div class="py-2" (click)="save('QUOTE_IN_PROGRESS')" style="cursor:pointer;">
        <span class="btn btn-project-quote-in-progress">Offerte in behandeling</span>
    </div>
    <div class="py-2" (click)="save('QUOTE_SEND')" style="cursor:pointer;">
        <span class="btn btn-project-quote-send">Offerte verstuurd</span>
    </div>
</div>