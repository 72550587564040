import { Component, OnInit } from '@angular/core';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from 'src/app/services/core/config.service';
import { UsersService } from 'src/app/services/users.service';
import { ModalConfirmDeleteComponent } from 'src/app/modals/modal-confirm-delete/modal-confirm-delete.component';

@Component({
  selector: 'app-certificate-types-form',
  templateUrl: './certificate-types-form.component.html',
  styleUrls: ['./certificate-types-form.component.scss']
})
export class CertificateTypesFormComponent implements OnInit {

  item;
  id = '';
  users = [];
  errors = [];
  colors = [];
  successmessage = false;
  errormessage = false;
  selectedItems = {};
  loaderOverlay = false;
  autographImage;

  filterName: any = '';

  constructor(
    public api: ApiConnectionService,
    public config: ConfigService,
    public usersService: UsersService,
    public modalService: NgbModal,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
    this.fetchItem();
  }

  ngOnChanges(changes: any) {
    this.fetchItem();
  }

  canShow(username) {
    if (this.filterName != '') {
      if ((username).toLowerCase().includes(this.filterName.toLowerCase())) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  }

  fetchItem() {
    if (this.id !== '') {
      let self = this;
      self.item = {};
      this.api.read('certificateTypes/' + self.id + '').then((response) => {
        self.item = response[0];
      });
    } else {
      this.item = {};
    }
  }

  save() {
    let self = this;
    self.errors = [];
    self.successmessage = false;
    self.errormessage = false;

    let type = 'added';
    if (!self.item['id'] || self.item['id'].length < 2) {
      type = 'edited';
    }

    console.log(self.item)
    this.api.save('certificateTypes/save', self.item).then((response) => {
      self.item = response;
      self.successmessage = true;
      self.activeModal.close({
        id: self.item['id'],
        type: 'edited',
      });
    }).catch(function (response) {
      self.errormessage = true;
      for (let error in response['error']['errors']) {
        self.errors[error] = response['error']['errors'][error];
      }
    });
  }

  toggleList(key) {
    if (this.selectedItems[key] == true) {
      delete this.selectedItems[key];
    } else {
      this.selectedItems[key] = true;
    }
  }

  dismiss() {
    this.activeModal.close(false);
  }

  close() {
    this.activeModal.close(false);
  }

  cancel() {
    this.activeModal.close(false);
  }
}
