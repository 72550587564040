import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';

@Component({
	selector: 'app-user-hour',
	templateUrl: './user-hour-registration.component.html',
	styleUrls: ['./user-hour-registration.component.scss']
})
export class UserHourRegistrationComponent implements OnInit {
	@Input() public title;
	@Input() public userGroupId;
	@Input() public projectId;
	@Input() public button;
	@Input() public message;
	@Input() public year;
	@Input() public week;
	@Output() passEntry: EventEmitter<any> = new EventEmitter();

	public loaderOverlay;
	public years = [
	];

	hoursWorked = 0;
	filterName = "";
	selectedItems = {};
	users = [];
	userGroupList = [];

	public weeks = [];

	constructor(
		public activeModal: NgbActiveModal,
		public app: AppService,
		public api: ApiConnectionService
	) {
		var curr = new Date;
		let maxYear = curr.getFullYear();
		maxYear++;
		for (let i = maxYear; i > 2021; i--) {
			let str = i.toString();
			this.years.push(str);
		}
	}

	canShow(username) {
		if (this.filterName != '') {
			if ((username).toLowerCase().includes(this.filterName.toLowerCase())) {
				return true;
			}
			return false;
		} else {
			return true;
		}
	}

	toggleList(key) {
		if (this.selectedItems[key] == true) {
			delete this.selectedItems[key];
		} else {
			this.selectedItems[key] = true;
		}
	}

	clearHoursWorked() {
		this.hoursWorked = null;
	}

	getDutchDate() {
		const now = new Date();
		const options: any = {
			weekday: 'long',
			year: 'numeric',
			month: 'long',
			day: 'numeric'
		};
		return now.toLocaleDateString('nl-NL', options);
	}

	ngOnInit(): void {
		let currentDate = new Date;
		this.week = this.getDutchDate();

		const self = this;

		this.api.read('users').then((data) => {
			this.users = data['items'];

			this.api.read('userGroups/details/' + this.userGroupId).then((data) => {
				this.userGroupList = data['userIds'];

				const newList = [];
				for (let i = 0; i < this.users.length; i++) {
					const sel = this.users[i];

					if (this.userGroupList.includes(sel.id)) {
						newList.push(sel);
						self.selectedItems[sel.id] = true;
					}
				}

				this.users = newList;
			});
		});
	}

	getFormattedDate() {
		const today = new Date();
		const year = today.getFullYear();
		let month: any = today.getMonth() + 1; // Months start at 0
		let day: any = today.getDate();

		// Pad month and day with zeros if necessary
		month = month.toString().padStart(2, "0");
		day = day.toString().padStart(2, "0");
	  
		return `${year}-${month}-${day}`;
	  }

	confirm() {
		let self = this;

		self.loaderOverlay = true;
		self.app.setLoaderStatus(true);

		let userIds = [];
		for(let i = 0; i < this.users.length; i++) {
			if(this.selectedItems[this.users[i].id] != undefined && this.selectedItems[this.users[i].id] == true) {
				userIds.push(this.users[i].id);
			}
		}

		this.api.save('projectHours/insertBulk/' + this.projectId, { "date": this.getFormattedDate(), "userIds": userIds, "hours": this.hoursWorked }).then(function (response) {
			self.loaderOverlay = false;
			self.app.setLoaderStatus(false);
			self.app.setSystemMessageLoading(true);
			self.app.setSystemMessageText('Urenregistratie toegevoegd');
			self.app.setSystemMessageType('SUCCESS');
			self.activeModal.close(response);
		}).catch(function (response) {
			self.loaderOverlay = false;
			self.app.setLoaderStatus(false);
			self.app.setSystemMessageLoading(true);
			self.app.setSystemMessageText(response['error']['message']);
			self.app.setSystemMessageType('DANGER');
		});
	}

	cancel() {
		this.activeModal.close(false);
	}

	close() {
		this.activeModal.close(false);
	}

	dateString(week) {
		var curr = new Date; // get current date

		let y = this.year;
		var d = new Date("Jan 01, " + y + " 01:00:00");
		var dayMs = (24 * 60 * 60 * 1000);
		var offSetTimeStart = dayMs * (d.getDay() - 1);
		var w = d.getTime() + 604800000 * (week - 1) - offSetTimeStart; //reducing the offset here
		var n1 = new Date(w);
		var n2 = new Date(w + 518400000);

		let day = (n1.getDate() < 10 ? '0' + n1.getDate().toString() : n1.getDate().toString());
		let month = n1.getMonth();
		month = (month + 1);
		let monthStr = (month < 10 ? '0' + month.toString() : month.toString());

		var firstday = day + '-' + monthStr + '-' + n1.getFullYear();
		return firstday;
	}
}