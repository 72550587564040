<div class="row" style="margin-top:-24px;">
    <div class="col-6 col-lg-8">
        <section class="filter-container">
            <span class="btn btn-filter btn-filter-add btn-white with-icon icon-right me-2" style="font-weight:600;"
                (click)="addFilter()"><i class="bi bi-plus text-success" style="font-weight:bold;"></i>
                Filter</span>
            <span class="btn btn-filter py-0 me-2" style="font-weight:600;" *ngFor="let filter of filters">
                <span class="edit-filter" (click)="editFilter(filter['key'])">
                    <i class="bi bi-sliders2 text-success with-icon icon-right me-2"></i> <span> {{filter['label']}}
                        <small class="text-muted">{{filter['valueText']}}</small></span>
                </span>
                <span class="remove-filter" (click)="removeFilter(filter['key'])"><i class="bi bi-trash"></i></span>
            </span>
        </section>
    </div>
</div>

<section style="margin-left:-10px;">
    <iframe style="width:100%;height:calc(100vh - 120px);" [src]="mapsUrl"></iframe>
</section>