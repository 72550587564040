import { Component, OnInit } from '@angular/core';

import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { AuthenticationService } from 'src/app/services/core/authentication.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalExportComponent } from 'src/app/modals/modal-export/modal-export.component';
import { CreateTermstateComponent } from '../projects-detail/create-termstate/create-termstate.component';
import { ModalConfirmDeleteComponent } from 'src/app/modals/modal-confirm-delete/modal-confirm-delete.component';

@Component({
  selector: 'app-financial',
  templateUrl: './financial.component.html',
  styleUrls: ['./financial.component.scss']
})
export class FinancialComponent implements OnInit {

  item = null;
  currentUser;

  link = "termStatements";
  editPath = 'projects/detail/';
  editParam = 'projectId';
  refresh = 0;
  mapping = [
    { key: 'costsSentAtText', label: 'Datum', width: '140px', sort: true, sortType: "date" },
    { key: 'organisationName', label: 'Klant', width: '160px', sort: true },
    { key: 'projectNumber', label: 'Project num.', width: '140px', sort: true, link: 'projects/detail/', linkParam: 'projectId' },
    { key: 'projectName', label: 'Omschrijving', width: '260px', sort: false },
    { key: 'weekNumber', label: 'Laatste werkweek', sort: true },
    { key: 'amountExclVat', label: 'Bedrag ingediend', sort: true, sortType: "currency" },
    { key: 'projectIoNumber', label: 'IO nummer', sort: true },
    // { key: 'invoiceNumber', label: 'Factuurnummer', sort: false },
    { key: 'statusChangedAtText', label: 'Status laatst gewijzigd', sort: true },
    { key: 'statusText', label: 'Status', sort: false },
  ];

  lastSort = {};

  constructor(
    private api: ApiConnectionService,
    private appService: AppService,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
  ) {
    const self = this;

    self.authenticationService.getCurrentUser();
    self.authenticationService.data.subscribe((data) => {
      if (Object.keys(data).length) {
        self.currentUser = data;
      } else {
        self.currentUser = false;
      }
    });
  }

  ngOnInit(): void {
    this.appService.setPageTitle('Financieel');
    this.appService.setNavKey('financial');
  }

  editTermstate(termData) {
    const self = this;

    const termstateModal = this.modalService.open(CreateTermstateComponent, {
    });

    termstateModal.componentInstance.project = { id: termData.projectId };
    termstateModal.componentInstance.data = termData;
    termstateModal.result.then((result) => {
      self.refresh = Math.random();

      this.refresh = 1;
    })
      .catch((error) => {

      });
  }

  deleteTermstate(event) {
    const filterModal = this.modalService.open(ModalConfirmDeleteComponent, {
    });
    filterModal.componentInstance.message = "Weet je zeker dat je deze termijnstaat wilt verwijderen?";
    filterModal.result.then((result) => {
      const self = this;
      if (result) {
        this.api.delete('termStatements/delete/' + event['id']).then(function (response) {
          self.refresh = 1;
        }).catch((err) => {
          console.log(err)
        });
      }
    })
      .catch((error) => {

      });
  }

  openExportModal() {
    let self = this;

    const exportModal = this.modalService.open(ModalExportComponent, {
    });

    exportModal.componentInstance.message = "Kies een bestandstype voor de export.";
    exportModal.componentInstance.exportType = "termStatements";

    exportModal.componentInstance.sortFilter = this.lastSort;

      exportModal.result.then((result) => {
        self.refresh = Math.random();
      })
      .catch((error) => {

      });
  }

  saveSort(e) {
    this.lastSort = e;
  }

  // all() {
  //   let self = this;
  //   this.api
  //     .read("termStatements")
  //     .then((response: any) => {
  //       self.item = response['items'];
  //       console.log(self.item)
  //     });
  // }

  // addHash(url) {
  //   return url + "&hash=" + this.currentUser['publicHash'];
  // }

}
