
    <section class="" *ngIf="edit == false">
        <span class="btn btn-dark mb-3 with-icon icon-left" (click)="editForm()"><i class="bi bi-pencil"></i> Abc wijzigen</span>
        <section class="table-grid">
            <section class="table-content">
                <table class="table table-striped">
                    <thead class="thead-dark">
                        <tr>
                            <th>Omschrijving</th>
                            <th>Vereist</th>
                            <th>
                                Afspraken
                            </th>
                        </tr>
                    </thead>
                    <tbody >
                        <tr *ngFor="let abcItem of projectAbcItems; let i = index">
                            <td>
                                {{abcItem['description']}}
                            </td>
                            <td style="width: 220px;" [innerHTML]="transform(abcItem['statusLabel'])">
                            </td>
                            <td style="width: 470px;">
                                {{abcItem['description']}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </section>
    </section>
    <section class="" *ngIf="edit == true">
        <span class="btn btn-dark mb-3 with-icon icon-left" (click)="saveAbcItems()"><i class="bi bi-save"></i> Wijzingen opslaan</span>
        <span class="btn btn-light mb-3 ms-3" (click)="closeForm()"> Annuleren</span>
        <section class="table-grid">
            <section class="table-content">
                <table class="table table-striped">
                    <thead class="thead-dark">
                        <tr>
                            <th></th> 
                            <th>Omschrijving</th>
                            <th style="width: 80px;">Ja</th>
                            <th style="width: 80px;">Nee</th>
                            <th >N.v.t.</th>
                            <th>
                                Afspraken
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody cdkDropList (cdkDropListDropped)="drop($event)" class="table-drag-list">
                        <tr cdkDrag cdkDragPreviewContainer="parent" *ngFor="let abcItem of projectAbcItems; let i = index">
                            <td style="width:30px;" cdkDragHandle class="dragHandle"><i class="bi bi-arrows-move"></i></td>
                            <td>
                                <input type="text" [(ngModel)]="abcItem['description']" placeholder="Omschrijving" style="width:100%;">
                            </td>
                            <td style="width: 80px;">
                                <div class="form-check form-check-icon pb-0 mb-0 pt-2" style="height:30px;overflow:hidden;">
                                <input class="form-check-input" [(ngModel)]="abcItem['status']" [value]="'REQUIRED'" type="radio" name="abc_status_{{i}}" id="abc_status_{{i}}_ja" >
                                <label class="form-check-label" for="abc_status_{{i}}_ja">
                                </label>
                                </div>
                            </td>
                            <td style="width: 80px;">
                                <div class="form-check form-check-icon pb-0 mb-0 pt-2" style="height:30px;overflow:hidden;">
                                <input class="form-check-input" [(ngModel)]="abcItem['status']" [value]="'NOT_REQUIRED'" type="radio" name="abc_status_{{i}}" id="abc_status_{{i}}_nee" >
                                <label class="form-check-label" for="abc_status_{{i}}_nee">
                                </label>
                                </div>
                            </td>
                            <td style="width: 120px;">
                                <div class="form-check form-check-icon pb-0 mb-0 pt-2" style="height:30px;overflow:hidden;">
                                <input class="form-check-input" [(ngModel)]="abcItem['status']" type="radio" [value]="'NVT'" name="abc_status_{{i}}" id="abc_status_{{i}}_nvt" >
                                <label class="form-check-label" for="abc_status_{{i}}_nvt">
                                </label>
                                </div>
                            </td>
                            <td style="width: 370px;">
                                <input type="text" [(ngModel)]="abcItem['obligation']" placeholder="Verplichting" style="width:100%;">
                                
                            </td>
                            <td style="width:30px;"><span (click)="deleteAbcItem(i, abcItem)" role="button" class="px-1 d-inline-block" style="padding-top:8px;"><i class="bi bi-trash"></i></span></td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colspan="7"><span class="btn btn-light with-icon icon-left" (click)="addAbcItem()"><i class="bi bi-plus"></i> Regel toevoegen</span></td>
                        </tr>
                    </tfoot>
                </table>
            </section>
        </section>
    </section>