import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ApiConnectionService }  from '../services/core/api-connection.service';
import { ConfigService }  from '../services/core/config.service';

import {Observable} from "rxjs/internal/Observable";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
	public configService: ConfigService,
	public apiConnectionService: ApiConnectionService
  ) { }
	
  topNavActive = new BehaviorSubject({});
  data = this.topNavActive.asObservable();
  
  pageDocument = new BehaviorSubject({});
  pageDocumentData = this.pageDocument.asObservable();
  
  currentAuthor = new BehaviorSubject({});
  currentAuthorData = this.currentAuthor.asObservable();
  
  activities = new BehaviorSubject({});
  activitiesData = this.activities.asObservable();

  toastList = [];

  toastObservable = new BehaviorSubject({});
  toasts = this.toastObservable.asObservable();
  
  defaultTitle = '';
  defaultDescription = '';

  pageDocumentObject = {
	  topNavActive:'active', 
	  navKey:'dashboard', 
	  loading: false, 
	  systemMessageType: 'SUCCESS', 
	  systemMessageText: 'Verwerkt', 
	  systemMessageLoading: false, 
	  title:'', 
	  description:'', 
	  showPasswordResetModal: false, 
	  showLoginModal: false,
	  showRegisterModal: false,
	  showCheckRequestAlert: true
  };
  
  navActive () {
	this.pageDocumentObject.topNavActive = 'active';
  	this.pageDocument.next(this.pageDocumentObject);
  }
  
  navInactive () {
	this.pageDocumentObject.topNavActive = 'inactive';
  	this.pageDocument.next(this.pageDocumentObject);
  }
  
  showLoginModal () {
	this.hideRegisterModal ();
	this.pageDocumentObject.showLoginModal = true;
  	this.pageDocument.next(this.pageDocumentObject);
  }
  
  hideLoginModal () {
	this.pageDocumentObject.showLoginModal = false;
  	this.pageDocument.next(this.pageDocumentObject);
  }
  
  showRegisterModal () {
	this.hideLoginModal();
	this.pageDocumentObject.showRegisterModal = true;
  	this.pageDocument.next(this.pageDocumentObject);
  }
  
  hideRegisterModal () {
	this.pageDocumentObject.showRegisterModal = false;
  	this.pageDocument.next(this.pageDocumentObject);
  }
  
  showPasswordResetModal () {
	this.pageDocumentObject.showLoginModal = false;
	this.pageDocumentObject.showPasswordResetModal = true;
  	this.pageDocument.next(this.pageDocumentObject);
  }
  
  hidePasswordResetModal () {
	this.pageDocumentObject.showPasswordResetModal = false;
  	this.pageDocument.next(this.pageDocumentObject);
  }
  
  setShowCheckRequestAlert () {
	this.pageDocumentObject.showCheckRequestAlert = true;
	this.pageDocument.next(this.pageDocumentObject);
  }
  
  hideCheckRequestAlert () {
	this.pageDocumentObject.showCheckRequestAlert = false;
	this.pageDocument.next(this.pageDocumentObject);
  }
  
  setPageTitle (title) {
	if (title == '') {
		title = this.defaultTitle;
	} else {
		title = title + ' - ' + this.configService.get('system_name');
	}
	
	this.pageDocumentObject.title = title;
	this.pageDocument.next(this.pageDocumentObject);
  }
  
  setPageDescription (description) {
	if (description == '') {
		description = this.defaultDescription;
	}
	
	this.pageDocumentObject.description = description;
	this.pageDocument.next(this.pageDocumentObject);
  }

  setNavKey (key)
  {
	this.pageDocumentObject.navKey = key;
	this.pageDocument.next(this.pageDocumentObject);
  }

  setLoaderStatus (status)
  {
	this.pageDocumentObject.loading = status;
	this.pageDocument.next(this.pageDocumentObject);
  }

  setSystemMessageLoading (loading)
  {
	this.pageDocumentObject.systemMessageLoading = loading;
	this.pageDocument.next(this.pageDocumentObject);
  }

  setSystemMessageText (message)
  {
	if (message == false) { message = ''; }
	if (message == 'DEFAULT_ERROR') { message = 'Er is een fout opgetreden'; }
	if (message == 'DEFAULT_DELETED') { message = 'Item verwijderd'; }
	if (message == 'DEFAULT_SAVED') { message = 'Wijzigingen opgeslagen'; }
	if (message == 'DEFAULT_ADDED') { message = 'Item toegevoegd'; }
	this.pageDocumentObject.systemMessageText = message;
	this.pageDocument.next(this.pageDocumentObject);
  }
  
  setSystemMessageType (type)
  {
	this.pageDocumentObject.systemMessageType = type;
	this.pageDocument.next(this.pageDocumentObject);
  }

  setCurrentAuthor (authorName) {
	this.currentAuthor.next({name: authorName});
  }
  
  setDefaultAuthor () {
	this.currentAuthor.next({name: this.configService.get('default_author') });  
  }
  
  setDefaultTitle (title) {
	  this.defaultTitle = title;
  }
  
  setDefaultDescription (description) {
	  this.defaultDescription = description;
  }
  
  setDefaultMeta () {
	  this.setPageTitle(this.defaultTitle);
	  this.setPageDescription(this.defaultDescription);
  }
  
  getActivities(currentUserId): Observable<any> {
	let self = this;
  	return <any>this.apiConnectionService.read('userfiles/'+ currentUserId +'').then(function (data) {
		self.activities.next({activities: data});
  	}).catch(function (data) {
  		self.activities.next({});
  	});
  }
  
  checkToken () {
	let self = this;
	return new Promise(function(resolve, reject) 
	{
		try {
			let search = location.hash.substring(1);
			let params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}');

			if (params['loginToken']) {
				self.loginWithHash(params['loginToken']).then(function () {
					resolve(true);
				}).catch(function (error) {
				});
			} else {
				resolve(true);
			}
		} catch (e) {
			resolve(true);
		}
	});
  }
    
  async loginWithHash (hash) {
	let self = this;
  	return <any>this.apiConnectionService.read('users/loginByToken/'+hash+'').then(function (data) {
		self.apiConnectionService.setAccessToken(data['accessToken']);
		self.apiConnectionService.setRefreshToken(data['refreshToken']);
		
  	}).catch(function (data) {
		window.location.hash = '';
		window.location.reload();  		
  	});	  
  }

  addToast (id, content, type, data = '')
  {
	  this.toastList.push({
		  'id': id,
		  'content': content,
		  'type' : type,
		  'data' : data
	  });

	  this.toastObservable.next(this.toastList);
  }

  removeToast (id) 
  {
	this.toastList = this.toastList.filter(item => item['id'] !== id);
	this.toastObservable.next(this.toastList);
  }

  scrollToTop ()
  {
	window.scroll({ 
		top: 0, 
		left: 0, 
		behavior: 'smooth' 
 	});
  }

  scrollToElementTop (elementId)
  {
	document.getElementById(""+elementId+"").scrollTo({ top: 0, behavior: 'smooth' });
  }
}