import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	Renderer2,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalConfirmDeleteComponent } from "src/app/modals/modal-confirm-delete/modal-confirm-delete.component";
import { FilePreviewComponent } from "src/app/modals/file-preview/file-preview.component";
import { AppService } from "src/app/services/app.service";
import { ApiConnectionService } from "src/app/services/core/api-connection.service";
import { ConfigService } from "src/app/services/core/config.service";
import { ProjectPermitStatusComponent } from "../project-permit-status/project-permit-status.component";
import { EditFileNameTreeComponent } from "./edit-file-name-tree/edit-file-name-tree.component";
import { AuthenticationService } from "src/app/services/core/authentication.service";

import { FileCategoryFormComponent } from "src/app/components/file-category-form/file-category-form.component";
import { EditCategoryNameTreeComponent } from "./edit-category-name-tree/edit-category-name-tree.component";
import { EditCategoryOrderComponent } from "./edit-category-order/edit-category-order.component";

@Component({
	selector: "app-project-files-tree",
	templateUrl: "./project-files-tree.component.html",
	styleUrls: ["./project-files-tree.component.scss"],
})
export class ProjectFilesTreeComponent implements OnInit {
	@Input() public project;
	@Input() public hideMeta = false;
	@Input() public reload;
	@Input() public target = "GENERAL";
	@Input() public type = "NORMAL";
	@Input() public fileType = "projectFiles";
	@Output() reloadAction = new EventEmitter();

	loading = false;
	loaderOverlay = false;
	showDropDown = false;
	showFilters = false;

	files: File[] = [];
	projectFiles;

	uploadUrl = "";
	addCategoryUrl = "";

	orderedProjectFiles = [];

	selectedFiles = {};

	dropzoneHovered = false;

	fileCategoryOptions;
	fileCategoryOptionsRaw;
	targetCategory = "";
	showCategoryChange = false;

	fileList: any = [];
	frontCollapse: any = {};

	currentUser;

	sortKey = 'name';
	sortDirection = 'asc';

	showOnlyUser = false;

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private authenticationService: AuthenticationService,
		private sanitizer: DomSanitizer,
		private config: ConfigService,
		private api: ApiConnectionService,
		private render: Renderer2,
		private modalService: NgbModal
	) {
		this.fileCategoryOptions = [];
		this.fileCategoryOptionsRaw = [];

		let self = this;
		this.render.listen("window", "click", (evt) => {
			if (!evt.target.classList.contains("dropdown-toggle")) {
				self.showDropDown = false;
				self.showFilters = false;
			}
		});
		self.authenticationService.getCurrentUser();
		self.authenticationService.data.subscribe((data) => {
			if (Object.keys(data).length) {
				self.currentUser = data;

				if (self.currentUser.role === "USER") {
					self.showOnlyUser = true;
				}
			} else {
				self.currentUser = false;
			}
		});
	}

	canShow(role) {
		if (this.showOnlyUser) {
			if (role !== "USER") {
				return false;
			} else {
				return true;
			}
		} else {
			return true;
		}
	}

	getFilterCode(val) {
		if (val) {
			return "asc";
		} else {
			return "desc";
		}
	}

	getCategories() {
		let self = this;
		if (!self.project) {
			return;
		}

		this.fileCategoryOptions = [];
		this.fileCategoryOptionsRaw = [];
		this.orderedProjectFiles = [];

		let id = self.project["id"];
		let target = self.target === "GENERAL" ? "" : self.target;

		self.uploadUrl = "/projectFiles/upload/" + self.project["id"] + "/";
		self.addCategoryUrl = self.api.getAddCategory();

		this.api
			.read("projectFiles/categories/" + id + "&sort=" + self.getFilterCode(self.sortKey) + "&direction=" + self.getFilterCode(self.sortDirection))
			.then((response: any) => {
				self.appService.setLoaderStatus(false);

				if (Object.keys(self.frontCollapse).length != 0) {
				} else {
					let frontCollapse = {};
					for (let i = 0; i < response.length; i++) {
						const sel = response[i];
						if (frontCollapse[sel['role']] == undefined) {
							frontCollapse[sel['role']] = true;
						}
						if (frontCollapse[sel['value']] == undefined) {
							frontCollapse[sel['value']] = true;
						}
					}
					self.frontCollapse = frontCollapse;
				}

				self.fileCategoryOptions = [];
				self.fileCategoryOptionsRaw = response;

				for (let i = 0; i < self.fileCategoryOptionsRaw.length; i++) {
					self.fileCategoryOptions.push(self.fileCategoryOptionsRaw[i]);
				}

				self.fileCategoryOptions = self.fileCategoryOptionsRaw;

				this.getFiles();
			});
	}

	ngOnInit(): void {
	}

	ngOnChanges(changes: any) {
		this.getCategories();
	}

	transform(value) {
		return this.sanitizer.bypassSecurityTrustHtml(value);
	}

	editCategoryName(categoryId, curName, rank) {
		let self = this;

		const filterModal = this.modalService.open(EditCategoryNameTreeComponent, {
			size: "lg",
			windowClass: "second-modal",
			backdropClass: "second-modal-backdrop",
			backdrop: "static",
		});
		filterModal.componentInstance.categoryId = categoryId;
		filterModal.componentInstance.curName = curName;
		filterModal.componentInstance.newRank = rank.role;
		filterModal.result.then((result) => { this.getCategories() }).catch((error) => { });
	}

	isAppropriateRole(itemRole) {
		const userRole = this.currentUser.role;

		itemRole = itemRole.role;

		if (userRole == "ADMIN") {
			return true;
		} else if (userRole == "MANAGER" && itemRole != "ADMIN") {
			return true;
		} else if (itemRole == "USER") {
			return true;
		}
		return false;
	}

	count(list) {
		let additive = "bestand";
		if (list.length > 1) {
			additive += "en";
		}

		return list.length + " " + additive;
	}

	translateRole(roleEnum) {
		if (roleEnum == "ADMIN") {
			return "Beheerder";
		} else if (roleEnum == "MANAGER") {
			return "Uitvoerder";
		} else if (roleEnum == "USER") {
			return "Gebruiker";
		}
	}

	filterName() {
		this.sortKey = 'name';
		if (this.sortDirection === 'asc') {
			this.sortDirection = 'desc';
		} else {
			this.sortDirection = 'asc';
		}

		this.getCategories();
		this.appService.setLoaderStatus(true);
	}

	filterDate() {
		this.sortKey = 'date';
		if (this.sortDirection === 'asc') {
			this.sortDirection = 'desc';
		} else {
			this.sortDirection = 'asc';
		}

		this.getCategories();
		this.appService.setLoaderStatus(true);
	}

	isAscOrDesc() {
		if (this.sortDirection === 'asc') {
			return "oplopend";
		} else {
			return "aflopend";
		}
	}

	adjustCategoryOrder() {
		const filterModal = this.modalService.open(EditCategoryOrderComponent, {
			size: "lg",
			windowClass: "second-modal",
			backdropClass: "second-modal-backdrop",
			backdrop: "static",
		});

		filterModal.componentInstance.projectId = this.project["id"];
		filterModal.result.then((result) => { this.getCategories() }).catch((error) => { });
	}

	toggleDropdown() {
		this.showDropDown = !this.showDropDown;
		this.showFilters = false;
	}

	toggleFilterDropdown() {
		this.showFilters = !this.showFilters;
		this.showDropDown = false;
	}

	handleCategoryChange(event) {
		this.targetCategory = event.target.value;
	}

	setShowCategory(value) {
		this.showCategoryChange = value;
		this.showDropDown = false;
	}

	categoryToId(categoryName: any) {
		for (let i = 0; i < this.fileCategoryOptions.length; i++) {
			if (this.fileCategoryOptions[i].value == categoryName) {
				return i;
			}
		}
	}

	getFiles() {
		let self = this;
		let id = self.project["id"];
		if (!self.project) {
			return;
		}
		self.projectFiles = [];
		self.dropzoneHovered = false;
		let target = self.target === "GENERAL" ? "" : self.target;

		if (typeof (target) === 'undefined' || target == 'undefined') {
			target = '';
		}

		let type = (self.fileType === 'projectPhotos' ? "photos" : "files");

		for (let i = 0; i < this.fileCategoryOptions.length; i++) {
			const sel = this.fileCategoryOptions[i];
			try {
				sel['files'] = [];
			} catch (e) {

			}
		}

		this.api
			.read("projects/details/" + id + "/" + type + "/" + target + "&sort=" + self.sortKey + "&direction=" + self.sortDirection)
			.then((response) => {
				self.projectFiles = response["items"];

				for (let i = 0; i < this.projectFiles.length; i++) {
					const sel = this.projectFiles[i];
					try {
						const directory = self.fileCategoryOptions[self.categoryToId(sel['category'])]['files'];
						if (directory == undefined) {
							self.fileCategoryOptions[self.categoryToId(sel['category'])]['files'] = [];
						}

						self.fileCategoryOptions[self.categoryToId(sel['category'])]['files'].push(sel);
					} catch (e) {

					}
				}
			});
	}

	onSelect(event) {
		let self = this;
		this.loading = true;
		this.files.push(...event.addedFiles);

		const formData = new FormData();

		for (var i = 0; i < this.files.length; i++) {
			formData.append("file[]", this.files[i]);
		}

		self.loaderOverlay = true;
		self.appService.setLoaderStatus(true);
		this.api
			.saveFile(
				"" + self.fileType + "/upload/" + this.project["id"] + "/" + self.target + "",
				formData
			)
			.then(function (response) {
				self.files = [];
				self.loading = false;
				self.getFiles();
				self.loaderOverlay = false;
				self.appService.setLoaderStatus(false);
				self.appService.setSystemMessageLoading(true);
				self.appService.setSystemMessageText("Bestanden zijn opgeslagen");
				self.appService.setSystemMessageType("SUCCESS");
				self.dropzoneHovered = false;
				self.reloadAction.emit(self.project);
			});
	}

	onRemove(event) {
		this.files.splice(this.files.indexOf(event), 1);
	}

	delete(object) {
		let self = this;
		self.appService.setLoaderStatus(true);
		this.api
			.delete("" + self.fileType + "/delete/" + object.id + "")
			.then((response) => {
				self.getCategories();
				self.appService.setLoaderStatus(false);
				self.appService.setSystemMessageLoading(true);
				self.appService.setSystemMessageType("DANGER");
				self.appService.setSystemMessageText("Bestand verwijderd");
				self.reloadAction.emit(self.project);
			})
			.catch(function (error) {
				self.appService.setLoaderStatus(false);
			});
	}

	deleteList() {
		this.loaderOverlay = true;
		this.showDropDown = false;
		let self = this;
		for (var prop in this.selectedFiles) {
			if (Object.prototype.hasOwnProperty.call(this.selectedFiles, prop)) {
				self.appService.setLoaderStatus(true);
				self.api
					.delete("" + self.fileType + "/delete/" + prop + "")
					.then((response) => {
						self.appService.setLoaderStatus(false);
						self.appService.setSystemMessageLoading(true);
						self.appService.setSystemMessageType("DANGER");
						self.appService.setSystemMessageText("Bestand verwijderd");
						self.reloadAction.emit(self.project);

						delete self.selectedFiles[prop];
						self.deleteList();
					})
					.catch(function (error) {
						self.appService.setLoaderStatus(false);
						delete self.selectedFiles[prop];
						self.getFiles();
						self.loaderOverlay = false;
					});
				return true;
			}
		}
		this.loaderOverlay = false;
		self.getFiles();
	}

	setSort(name, direction) {
		this.sortKey = name;
		this.sortDirection = direction;

		this.getCategories();
		this.appService.setLoaderStatus(true);
	}

	confirmDelete2(object) {
		let self = this;

		const deleteModal = this.modalService.open(ModalConfirmDeleteComponent, {
			windowClass: "second-modal",
			backdropClass: "second-modal-backdrop",
		});
		deleteModal.componentInstance.message =
			"Weet je zeker dat je bestanden wilt verwijderen?";
		deleteModal.result.then((result) => {
			if (result) {
				self.delete(object);
			}
		});
	}

	confirmDelete() {
		let self = this;

		const deleteModal = this.modalService.open(ModalConfirmDeleteComponent, {
			windowClass: "second-modal",
			backdropClass: "second-modal-backdrop",
		});
		deleteModal.componentInstance.message =
			"Weet je zeker dat je bestanden wilt verwijderen?";
		deleteModal.result.then((result) => {
			if (result) {
				self.deleteList();
			}
		});
	}

	onFilesAdded(event) {
		this.files.push(...event.addedFiles);
		this.dropzoneHovered = false;
		this.readFile(this.files[0]).then((fileContents) => { })
	}

	private async readFile(file: File): Promise<string | ArrayBuffer> {
		return new Promise<string | ArrayBuffer>((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = (e) => {
				return resolve((e.target as FileReader).result);
			};

			reader.onerror = (e) => {
				console.error(`FileReader failed on file ${file.name}.`);
				return reject(null);
			};

			if (!file) {
				console.error("No file to read.");
				return reject(null);
			}

			reader.readAsDataURL(file);
		});
	}

	toggleSelect(fileId) {
		if (this.selectedFiles && this.selectedFiles[fileId]) {
			delete this.selectedFiles[fileId];
		} else {
			this.selectedFiles[fileId] = fileId;
		}
	}

	showPreview(file, fileList) {
		let link =
			'<iframe src="' +
			file["viewUrl"] +
			'&hash=' + this.currentUser['publicHash'] + '" style="width:100%;height:600px;"></iframe>';
		if (file["isImage"]) {
			link =
				'<img src="' + file["viewUrl"] + '&hash=' + this.currentUser['publicHash'] + '" style="width:100%;height:100%;">';
		}
		const modal = this.modalService.open(FilePreviewComponent, {
			size: "xl",
			backdropClass: 'second-modal-backdrop',
			windowClass: 'second-modal'
		});

		modal.componentInstance.message = link;
		modal.componentInstance.file = file;
		modal.componentInstance.fileList = fileList;
		modal.result.then((result) => { }).catch((error) => { });
	}

	sanitize(url: string) {
		return this.sanitizer.bypassSecurityTrustUrl(url);
	}

	editPageProjectPermitStatus(project, type) {
		let self = this;

		const filterModal = this.modalService.open(ProjectPermitStatusComponent, {
			size: "lg",
			backdrop: "static",
		});

		filterModal.componentInstance.type = type;
		filterModal.componentInstance.project = project;
		filterModal.result
			.then((result) => {
				this.reloadAction.emit(project);
				self.getFiles();
			})
			.catch((error) => {
				this.reloadAction.emit(project);
			});
	}

	editFilename(project, projectFile) {
		let self = this;

		const filterModal = this.modalService.open(EditFileNameTreeComponent, {
			size: "lg",
			windowClass: "second-modal",
			backdropClass: "second-modal-backdrop",
			backdrop: "static",
		});

		filterModal.componentInstance.project = project;
		filterModal.componentInstance.target = "" + self.fileType + "";
		filterModal.componentInstance.projectFile = projectFile;
		filterModal.result.then((result) => { this.getCategories() }).catch((error) => { });
	}

	public afterFileOverviewDrop(ev) {
		ev.preventDefault();
		this.afterDrop(ev);

		//hide drop class
		ev.target.classList.remove("uploadHover");
	}

	afterDrop(ev) {
		ev.preventDefault();

		var data = ev.dataTransfer.getData("text");
		var dataJson = {};
		try {
			dataJson = JSON.parse(data);
		} catch (e) {
			dataJson = {}
		}

		ev.target.classList.remove("uploadHover");
	}

	public allowFileOverviewDrop(ev) {
		ev.preventDefault();
		this.allowDrop(ev);
	}

	allowDrop(ev) {
		ev.preventDefault();

		var data = ev.dataTransfer.getData("text");
		var dataJson = {};
		try {
			dataJson = JSON.parse(data);
		} catch (e) {
			dataJson = {}
		}

		/*
		*   Handle file movement in ares
		*/
		ev.target.classList.add("uploadHover");
		try {
			ev.target.parentElement.classList.add("uploadHover");
		} catch (e) {

		}
		console.log(ev.target.parentElement);
		console.log(ev.target.parentElement.classList);
	}

	saveCategoryChange(targetCategory) {
		this.loaderOverlay = true;
		this.showDropDown = false;

		this.showCategoryChange = false;
		this.showDropDown = false;

		let self = this;

		for (var prop in this.selectedFiles) {
			if (Object.prototype.hasOwnProperty.call(this.selectedFiles, prop)) {
				self.appService.setLoaderStatus(true);
				self.appService.setSystemMessageType("SUCCESS");
				self.appService.setSystemMessageText("Categorie gewijzigd");
				
				self.api
					.read(
						"" + self.fileType + "/changeCategory/" +
						prop +
						"/" +
						targetCategory +
						""
					)
					.then((response) => {
						self.appService.setLoaderStatus(false);
						self.appService.setSystemMessageLoading(true);

						delete self.selectedFiles[prop];
						self.saveCategoryChange(targetCategory);
					})
					.catch(function (error) {
						self.appService.setLoaderStatus(false);
						delete self.selectedFiles[prop];
						self.getCategories();
						self.loaderOverlay = false;
					});
				return true;
			}
		}
		this.loaderOverlay = false;
		self.getCategories();
	}

	public async onFolderDrop(ev, category) {
		const self = this;
		ev.preventDefault();

		this.fileList = [];

		var data = ev.dataTransfer.getData("text");
		var dataJson = {};
		try {
			dataJson = JSON.parse(data);
		} catch (e) {
			dataJson = {};
		}

		/*
		 *   Handle file movement in ares
		 */
		if (dataJson['type'] === "categoryfile") {
			const fileId = dataJson['fileId'];

			self.selectedFiles[fileId] = fileId;

			this.saveCategoryChange(category);

			return true;
		}

		/*
		 *  Handle file drag
		 */
		var transferItems = ev.dataTransfer.items;
		var dirCount = 0;

		for (let i = 0; i < transferItems.length; i++) {
			let entry = transferItems[i].webkitGetAsEntry();
			if (entry) { // Check if entry is not null
				if (entry.isDirectory) {
					dirCount++;
				} else if (entry.isFile) {
					entry.file(function (file) {
						self.fileList.push(file);
					});
				}
			}
		}

		if (dirCount > 1) {
			alert('Maximaal 1 map');
			return;
		}

		if (dirCount === 1) {
			var directory = transferItems[0].webkitGetAsEntry();
			if (directory) { // Check if directory is not null
				await self.processDirectory(directory, category);
			}
		} else {
			setTimeout(function () {
				self.uploadFiles(self.fileList, self.uploadUrl + category);
			}, 300);
		}
	}


	drag(ev) {
		ev.dataTransfer.setData("text", JSON.stringify({
			type: "categoryfile",
			fileId: ev.target.getAttribute("data-file-id")
		}));
	}

	processDirectory(directory, category) {
		const self = this;
		var reader = directory.createReader();

		reader.readEntries(function (entries) {
			entries.forEach(function (entry) {
				if (entry.isDirectory) {
					// dont process inner directories  
					// processDirectory(entry);
				} else {
					entry.file(function (file) {
						self.files.push(file);
					});
				}
			});
		});
		setTimeout(function () { self.uploadFiles(self.fileList, self.uploadUrl + category); }, 300);
	}

	addCategory(role) {
		let self = this;
		const modal = this.modalService.open(FileCategoryFormComponent, {});
		modal.componentInstance.targetUrl = this.addCategoryUrl;
		modal.componentInstance.role = role;
		modal.componentInstance.projectId = this.project["id"];

		modal.result.then((result) => {
			if (result == false) { return; }
			self.getCategories();
		})
			.catch((error) => {

			});
	}

	uploadFiles(files, uploadUrl) {
		const self = this;

		document.getElementsByClassName("uploadHover")[0].classList.remove("uploadHover")

		if (files.length === 0 && this.files.length === 0) {
			alert('No files selected.');
			return;
		}

		var formData = new FormData();

		if (this.files.length !== 0) {
			for (let i = 0; i < this.files.length; i++) {
				files.push(this.files[i]);
			}
		}

		for (var i = 0; i < files.length; i++) {
			var file = files[i];
			formData.append('file[]', file);
		}

		self.loaderOverlay = true;
		self.appService.setLoaderStatus(true);
		this.api
			.saveFile(uploadUrl,
				formData
			)
			.then(function (response) {
				self.files = [];
				self.loading = false;
				self.getCategories();
				self.loaderOverlay = false;
				self.appService.setLoaderStatus(false);
				self.appService.setSystemMessageLoading(true);
				self.appService.setSystemMessageText("Bestanden zijn opgeslagen");
				self.appService.setSystemMessageType("SUCCESS");
				self.dropzoneHovered = false;
				self.reloadAction.emit(self.project);
			});
	}

	public collapse(key) {
		this.frontCollapse[key] = !this.frontCollapse[key];
	}
}
