<div class="modal-header">
	<h5 class="modal-title">Planning PDF</h5>
    <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
</div>
<div class="modal-body p-5">
    <div class="row">
        <div class="col-12">
            Startdatum:<br/>
			<input type="date" class="form-control" id="startWeek" required [(ngModel)]="startWeek">
        </div>
    </div>

</div>
<div class="modal-footer">
	<button (click)="confirm()" type="submit" class="btn btn-success">Download</button>
</div>