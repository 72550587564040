<header class="page-header">
  <div class="row">
    <div class="col-4">
      <h1>Algemene bestanden</h1>
      <h6>Bestanden / overzicht</h6>
    </div>
    <div class="col-8 text-end pt-3">
    </div>
  </div>
</header>
<section class="table-grid">
  <div class="col file-list">
    <section class="table-grid">
      <section class="table-content">
        <div class="text-center py-5 d-flex-column" *ngIf="files == null">
          <span class="rotate"
            style="display:inline-block;padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;cursor:pointer; font-size: 25px; line-height: 0px;">
            <i class="bi bi-arrow-clockwise"></i>
          </span>
          <small>Bestanden ophalen..</small>
        </div>
        <table class="table table-vertical-align mb-0" *ngIf="files">
          <thead class="thead-dark">
            <tr>
              <th>Bestand</th>
              <th style="text-align: right;">Actie</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let file of files; let i = index">
              <!-- Parent row -->
              <tr [ngClass]="{'directory': file.type == 'directory'}">
                <td nowrap>
                  {{ file['name'] }}
                </td>
                <td nowrap style="text-align:right;">
                  <span *ngIf="file.type !== 'directory'" (click)="showPreview(file)"
                    style="display:inline-block;padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;cursor:pointer; font-size: 25px; line-height: 0px;"
                    class="">
                    <i class="bi" [ngClass]="getFileExtension(file.name)"></i>
                  </span>

                  <ng-container *ngIf="file.type == 'directory'">
                    <!-- Chevron to expand/collapse child files -->
                    <span (click)="toggleDirectory(file, i)" *ngIf="!file.loading"
                      style="display:inline-block;padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;cursor:pointer; font-size: 25px; line-height: 0px;">
                      <i [ngClass]="file.expanded ? 'bi bi-chevron-up' : 'bi bi-chevron-down'"></i>
                    </span>
                    <span class="rotate" *ngIf="file.loading"
                      style="display:inline-block;padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;cursor:pointer; font-size: 25px; line-height: 0px;">
                      <i class="bi bi-arrow-clockwise"></i>
                    </span>
                  </ng-container>
                </td>
              </tr>

              <!-- Recursively show child files -->
              <ng-container *ngIf="file.expanded && file.files && file.files.length > 0">
                <tr *ngFor="let childFile of file.files" [ngClass]="{'directoryChild': childFile.type == 'directory'}">
                  <td nowrap style="padding-left: 50px;">
                    -------- {{ childFile['name'] }}
                  </td>
                  <td nowrap style="text-align:right;">
                    <span *ngIf="childFile.type !== 'directory'" (click)="showPreview(childFile)"
                      style="display:inline-block;padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;cursor:pointer; font-size: 25px; line-height: 0px;"
                      class=""><i class="bi" [ngClass]="getFileExtension(childFile.name)"></i></span>

                    <ng-container *ngIf="childFile.type == 'directory'">
                      <!-- Chevron for child directories -->
                      <span (click)="toggleDirectory(childFile, i)" *ngIf="!childFile.loading"
                        style="display:inline-block;padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;cursor:pointer; font-size: 25px; line-height: 0px;">
                        <i [ngClass]="childFile.expanded ? 'bi bi-chevron-up' : 'bi bi-chevron-down'"></i>
                      </span>
                      <span class="rotate" *ngIf="childFile.loading"
                        style="display:inline-block;padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;cursor:pointer; font-size: 25px; line-height: 0px;">
                        <i class="bi bi-arrow-clockwise"></i>
                      </span>
                    </ng-container>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </table>
      </section>
    </section>
  </div>
</section>