<header class="page-header">
	<div class="row">
		<div class="col-8">
			<h1>Offertes</h1>
			<h6>Offertes / overzicht</h6>
		</div>
		<div class="col-4 text-end pt-3">
			<button (click)="openExportModal()" class="btn btn-dark with-icon mr-2">Exporteren <i class="bi bi-box-arrow-down"></i></button>
			<button (click)="openForm({})" class="btn btn-success with-icon">Offerte aanmaken <i class="bi bi-plus-circle-fill"></i></button>
		</div>
	</div>
</header>
<app-table-guru 
	[refresh]="refresh"
	 [link]="link"
	 [mapping]="mapping"
	 [editPath]="editPath" 
></app-table-guru>