import { Component, OnInit, Input, Output, Renderer2 } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select2Data } from 'ng-select2-component';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { ConfigService } from 'src/app/services/core/config.service';

import { AuthenticationService } from 'src/app/services/core/authentication.service';

@Component({
  selector: 'app-create-termstate',
  templateUrl: './create-termstate.component.html',
  styleUrls: ['./create-termstate.component.scss']
})
export class CreateTermstateComponent implements OnInit {
  @Input() public title;
  @Input() public button;
  @Input() public message;
  @Input() public project;
  @Input() public data = {};

  @Input() public status = null;
  @Input() public weekNumber = null;

  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  showSuccess = false;
  loaderOverlay = false;
  value;

  currentUser;

  showError = false;
  errorMessage = "";

  isEditing = false;

  constructor(
    public activeModal: NgbActiveModal,
    public appService: AppService,
    public config: ConfigService,
    public api: ApiConnectionService,
    public sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private authenticationService: AuthenticationService
  ) {
  }


  ngOnInit(): void {
    this.isEditing = false;

    if (this.data['id'] !== undefined) {
      this.isEditing = true;
    }

    if (this.status != null) {
      this.data['status'] = this.status;
    }

    if (this.weekNumber != null) {
      this.data['weekNumber'] = this.weekNumber;
    }
  }

  create() {
    let self = this;
    self.appService.setLoaderStatus(true);
    self.loaderOverlay = true;

    self.data['projectId'] = self.project['id'];

    self.api.save('termStatements/save/', self.data).then(function (response) {
      self.appService.setLoaderStatus(false);
      self.showSuccess = true;
      self.loaderOverlay = false;
    }).catch((err) => {
      self.appService.setLoaderStatus(false);
      self.showError = true;
      self.loaderOverlay = false;
      self.errorMessage = err.error.message;
    });
  }

  confirm() {
    this.activeModal.close(false);
  }

  cancel() {
    this.activeModal.close(false);
  }

  close() {
    this.activeModal.close(false);
  }

}
