import { Component, OnInit, Input, Output, Renderer2 } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { AuthenticationService } from 'src/app/services/core/authentication.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { UsersService } from 'src/app/services/users.service';
import { UserAddCertificateModalComponent } from '../user-add-certificate-modal/user-add-certificate-modal.component';
import { ModalConfirmDeleteComponent } from 'src/app/modals/modal-confirm-delete/modal-confirm-delete.component';


@Component({
	selector: 'app-user-edit-modal',
	templateUrl: './user-edit-modal.component.html',
	styleUrls: ['./user-edit-modal.component.scss']
})
export class UserEditModalComponent implements OnInit {
	@Input() public id;
	@Input() public organisationId;
	@Input() public roleKey;
	@Input() public title;
	@Input() public button;
	@Input() public closeAfterSave = false;
	@Input() public message;

	@Input() public currentTab = "algemeen";

	@Output() passEntry: EventEmitter<any> = new EventEmitter();
	loaderOverlay = false;

	item: any = [];
	groups;
	existingCompanies;
	categories;
	errors = {};
	successmessage;
	errormessage;
	changepassword;
	selectedItems = {};
	selectedCategories = {};
	autograph;

	createCertificate = {

	};

	companyDDVisible = false;
	companyDDKeyword = '';

	parentUserDDVisible = false;
	parentUserDDKeyword = '';

	parentUsers;
	currentUser;

	roles = [
		{ name: 'Beheerder', value: 'ADMIN' },
		{ name: 'Uitvoerder', value: 'MANAGER' },
		{ name: 'Gebruiker', value: 'USER' },
		{ name: 'Contactpersoon', value: 'CONTACT' },
	];
	organisations: any = [];

	constructor(
		public service: UsersService,
		public modalService: NgbModal,
		public appService: AppService,
		private authenticationService: AuthenticationService,
		public apiService: ApiConnectionService,
		public renderer: Renderer2,
		public config: ConfigService,
		public activeModal: NgbActiveModal
	) {
		let self = this;
		this.renderer.listen('window', 'click', (evt) => {
			if (evt.target.closest('.auto-dropdown') == null && !evt.target.classList.contains('dd-input')) {
				this.parentUserDDVisible = false;
			}
		});

		this.renderer.listen("window", "click", (evt) => {
			if (
				evt.target.closest(".auto-dropdown") == null &&
				!evt.target.classList.contains("dd-input")
			) {
				this.companyDDVisible = false;
			}
		});

		self.authenticationService.getCurrentUser();
		self.authenticationService.data.subscribe(data => {
			if (Object.keys(data).length) {
				self.currentUser = data;
			} else {
				self.currentUser = false;
			}
		});

		this.getUsers();
	}

	changeTab(tab) {
		this.currentTab = tab;
	}

	getUsers() {
		let self = this;
		this.apiService.read('users&data[page]=1&data[limit]=9500&data[sort]=name&data[direction]=ASC&data').then(function (response) {
			self.parentUsers = response['items'];
		});
	}

	ngOnInit(): void {
		this.fetchItem();
		this.fetchGroups();
		this.fetchCategories();
		this.fetchCompanies();
		this.roles = this.config.get('user_roles');
	}

	toggleList(key) {
		if (this.selectedItems[key] == true) {
			delete this.selectedItems[key];
		} else {
			this.selectedItems[key] = true;
		}
	}

	ngOnChanges(changes: any) {
		this.fetchItem();
		this.fetchGroups();
		this.fetchCategories();
	}

	setCompany(item) {
		this.item["companyName"] = item;
		this.companyDDVisible = false;
	}

	searchCompanies() {
		let list = [];
		for (let index = 0; index < this.existingCompanies.length; ++index) {
			let user = this.existingCompanies[index];
			let string = "" + user.toLowerCase() + "";
			let result = string.includes(this.companyDDKeyword.toLowerCase());

			if (this.companyDDKeyword == "" || result) {
				list.push(user);
			}
		}

		return list;
	}

	fetchGroups() {
		let self = this;
		this.service.read('userGroups').then((response) => {
			self.groups = response['items'];
		});
	}

	showCompanyDD() {
		this.companyDDVisible = true;
	}

	fetchCompanies() {
		let self = this;
		this.service.read('users/existingCompanyNames').then((response) => {
			self.existingCompanies = response;
			console.log(self.existingCompanies)
		});
	}

	fetchCategories() {
		let self = this;
		this.service.read('categories/list').then((response) => {
			self.categories = response;
		});
	}

	toggleCat(id) {
		if (this.selectedCategories[id] == undefined) {
			this.item['categoryIds'].push(id);
			this.selectedCategories[id] = { id: id };
		} else {
			this.item['categoryIds'].splice(this.item['categoryIds'].indexOf(id), 1);
			delete this.selectedCategories[id];
		}
	}

	fetchItem() {
		this.service.read('organisations').then((response) => {
			self.organisations = response['items'];
		});
		let self = this;
		this.service.read('users/details/' + this.id + '').then((response) => {
			self.item = response;
			if (this.item['birthdate'] !== null) {
				this.item['birthdate'] = this.item['birthdate'].replace(" 00:00:00", "");
			}

			if (this.item['vcaValidTill'] !== null) {
				this.item['vcaValidTill'] = this.item['vcaValidTill'].replace(" 00:00:00", "");
			}

			self.autograph = self.item['autograph'];

			for (let i = 0; i < self.item['categories'].length; i++) {
				self.selectedCategories[self.item['categories'][i]['id']] = self.item['categories'][i];
			}

			self.selectedItems = [];
			self.item['groups'].forEach(element => {
				self.selectedItems[element['id']] = true;
			});
			if (self.id == '') {
				self.item['organisationId'] = self.organisationId;
				if (self.roleKey && self.roleKey.length > 0) {
					self.item['role'] = self.roleKey;
				}
			}
		});
	}

	confirmDeleteCert(certItem) {
		const self = this;
		const filterModal = this.modalService.open(ModalConfirmDeleteComponent, {
		});
		filterModal.componentInstance.message = "Weet je zeker dat je dit certificaat wilt verwijderen?";
		filterModal.result.then((result) => {
			self.loaderOverlay = true;

			if (result) {
				this.apiService.delete('userCertificates/delete/' + certItem.id).then((response) => {
					this.fetchItem();
					self.loaderOverlay = false;
				}).catch(function (response) {
					self.loaderOverlay = false;
				});
			}
		})
			.catch((error) => {

			});
	}

	openAddCertificate() {
		let self = this;
		const certModal = this.modalService.open(UserAddCertificateModalComponent, {
			windowClass: 'modal-right',
			size: 'lg'
		})
		certModal.componentInstance.userId = self.id;
		console.log(self.id)
		certModal.result.then((result) => {
			self.fetchItem();
		})
			.catch((error) => {
			});

	}

	removeAutograph() {
		this.autograph = '';
		this.item['autograph'] = '';
	}

	save() {
		let self = this;
		self.errors = [];

		self.loaderOverlay = true;

		self.appService.scrollToElementTop('user-form');

		self.item['changepassword'] = this.changepassword;
		self.item['groupIds'] = Object.keys(this.selectedItems);

		self.appService.setLoaderStatus(true);

		if (this.item['birthdate'] !== null) {
			this.item['birthdate'] = this.item['birthdate'] + " 00:00:00";
		}

		if (this.item['vcaValidTill'] !== null) {
			this.item['vcaValidTill'] = this.item['vcaValidTill'] + " 00:00:00";
		}

		this.service.save('users/save', self.item).then((response) => {
			self.item = response;
			self.successmessage = true;

			self.changepassword = false;
			self.loaderOverlay = false;

			if (self.closeAfterSave === true) {
				self.activeModal.close({
					id: self.item['id'],
					name: self.item['name']
				});
			}

			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageText('DEFAULT_SAVED');
			self.appService.setSystemMessageType('SUCCESS');
			self.appService.setLoaderStatus(false);
			self.loaderOverlay = false;

		}).catch(function (response) {

			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageText('DEFAULT_ERROR');
			self.appService.setSystemMessageType('DANGER');
			self.appService.setLoaderStatus(false);
			self.loaderOverlay = false;

			self.errormessage = true;
			for (let error in response['error']['errors']) {
				self.errors[error] = response['error']['errors'][error];
			}
		});
	}

	checkChangepassword(event) {
		if (this.changepassword === true) {
			this.changepassword = false;
		} else {
			this.changepassword = true;
		}
	}

	dismiss() {
		this.activeModal.close(false);
	}

	close() {
		this.activeModal.close(false);
	}

	cancel() {
		this.activeModal.close(false);
	}

	readURL(event: Event): void {
		let self = this;
		if (event.target['files'] && event.target['files'][0]) {
			const file = event.target['files'][0];

			const reader = new FileReader();
			reader.onload = function (e) {
				self.autograph = e.target.result;
				self.item['autograph'] = self.autograph;
			}
			reader.readAsDataURL(file);
		}
	}

	searchParentUsers() {
		let list = [];
		for (let index = 0; index < this.parentUsers.length; ++index) {
			let contact = this.parentUsers[index];
			let string = '' + contact['name'].toLowerCase() + '';
			let result = string.includes(this.parentUserDDKeyword.toLowerCase());

			if (contact['role'] === 'MATERIAL') {
				continue;
			}

			if (this.parentUserDDKeyword == '' || result) {
				list.push(contact);
			}
		}

		return list;
	}

	showParentUserDD() {
		this.parentUserDDVisible = true;
	}

	setParentUser(item) {
		this.item['materialParentId'] = item['id'];
		this.item['materialParentName'] = item['name'];
		this.parentUserDDVisible = false;
	}
}