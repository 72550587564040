import { Component, OnInit } from '@angular/core';
import { ApiConnectionService } from '../../services/core/api-connection.service';
import { ConfigService } from '../../services/core/config.service';
import { AuthenticationService } from '../../services/core/authentication.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { CollectionManagerService } from '../../services/core/collection-manager.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { UsersService } from '../../services/users.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalContentComponent } from '../../modals/modal-content/modal-content.component';
import { AppService } from 'src/app/services/app.service';

import { AddFilterComponent } from '../../components/table-guru/modals/add-filter/add-filter.component';
import { EditFilterComponent } from '../../components/table-guru/modals/edit-filter/edit-filter.component';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class IndexComponent {

  public apiUrl;
  public backupMxUrl;
  public backupWordpressUrl;

  public canShow = false;
  public currentUser: any;

  public documentFilter = "";
  public subFilter = "";

  public activeFilter = "";

  public showDropDown = false;
  public showSubDropdown = false;

  public projects = [];
  public costsProjects = [];
  public finishedProjects = [];
  public pendingQuotations = [];
  public invoicedProjects = [];

  public periodProjects = [];
  public noDocuments = [];

  public planProjects = [];
  public inProgressProjects = [];

  public currentWeek = '';
  public currentYear = '';

  public previousWeek = '';
  public previousYear = '';

  public nextWeek = '';
  public nextYear = '';

  public weekOverview1 = [
  ];
  public weekOverview2 = [
  ];

  path = '';

  modal;

  categories;
  selectedCategories = {};

  constructor
    (
      public http: HttpClient,
      public authenticationService: AuthenticationService,
      public configService: ConfigService,
      public router: Router,
      public api: ApiConnectionService,
      public collectionManager: CollectionManagerService,
      private appService: AppService,
      private ngxLoader: NgxUiLoaderService,
      private modalService: NgbModal,
      private usersService: UsersService,
    ) {
    let self = this;

    self.authenticationService.getCurrentUser();
    self.authenticationService.data.subscribe(data => {
      if (Object.keys(data).length) {
        self.currentUser = data;
      } else {
        self.currentUser = false;
      }
    });


    self.authenticationService.authState.subscribe(data => {
      if (data === false) {
        self.router.navigate(['login']);
      }
    });
  }

  toggleDropdown() {
    this.showDropDown = !this.showDropDown;
  }

  toggleSubDropdown() {
    this.showSubDropdown = !this.showSubDropdown;
  }

  setDocumentFilter(input) {
    if(this.documentFilter == input) {
      this.documentFilter = "";
      this.toggleDropdown();
      this.getNoDocuments(``);
    } else {
      this.documentFilter = input;

      this.activeFilter = `&data[filters][isInvalidFiles][values]=${input}`;

      this.getNoDocuments(`&data[filters][isInvalidFiles][values]=${input}`);
      this.toggleDropdown();
    }
  }

  subStatusToText(input) {
    if(input == "NVT") {
      return "N.V.T.";
    } else if(input == "OPEN") {
      return "vereist"
    } else if(input == "REQUESTED") {
      return "aangevraagd";
    } else if(input == "RECEIVED") {
      return "ontvangen";
    } else {
      return "";
    }
  }

  addSubStatusFilter(input) {
    if(this.subFilter == input) {
      this.subFilter = "";

      this.getNoDocuments(this.documentFilter);

      this.toggleSubDropdown();
    } else {
      this.subFilter = input;

      this.getNoDocuments(this.documentFilter + "&data[filters][" + this.documentFilter + "Status][values]=" + input);
      this.toggleSubDropdown();
    }

    //&data[filters][klicStatus][values]=OPEN
  }

  setCategory(event) {
    const idList = this.currentUser['categoryIds'];

    if (idList.includes(event)) {
      idList.splice(idList.indexOf(event), 1);
    } else {
      idList.push(event);
    }

    if (event != null) {
      this.api.save('users/save', this.currentUser).then((response) => {
        location.reload();
      });
    }
  }

  fetchCategories() {
    let self = this;
    this.api.read('categories/list').then((response) => {
      self.categories = response;
    });
  }

  ngOnInit(): void {
    let self = this;
    this.appService.setPageTitle('Dashboard');
    this.appService.setNavKey('dashboard');

    this.getQuotes();
    this.getProjects();
    this.getCostsProjects();
    this.getFinishedProjects();
    this.getWeekProjects();
    this.getProjectsInPlan();
    this.getProjectsInProgress();
    this.getNoDocuments('');
    this.getPeriodProjects();
    this.getInvoicedProjects();
  }

  getQuotes() {
    let self = this;
    this.api.read('quotes&data[page]=1&data[limit]=50&data[sort]=number&data[direction]=ASC&data[filters][status][values]=QUOTE_IN_PROGRESS||QUOTE_OPEN').then(function (response) {
      self.pendingQuotations = response['items'];
    });
  }

  getNoDocuments(extraFilter) {
    let self = this;

    //add filter missing document type via dropdown select
    this.api.read('projects&data[page]=1&data[limit]=50&data[sort]=number&data[direction]=ASC&data[filters][isInvalidFiles][values]=' + extraFilter).then(function (response) {
      self.noDocuments = response['items'];
    });
  }

  getPeriodProjects() {
    let self = this;
    this.api.read('projects&data[page]=1&data[limit]=50&data[sort]=number&data[direction]=ASC&data[filters][status][values]=PERIOD_SEND').then(function (response) {
      self.periodProjects = response['items'];
    });
  }

  getInvoicedProjects() {
    let self = this;
    this.api.read('archive&data[page]=1&data[limit]=50&data[sort]=number&data[direction]=ASC&data[filters][status][values]=INVOICE_SEND').then(function (response) {
      self.invoicedProjects = response['items'];
    });
  }

  getProjects() {
    let self = this;
    this.api.read('projects&data[page]=1&data[limit]=50&data[sort]=number&data[direction]=ASC&data[filters][status][values]=QUOTE_ACCEPTED').then(function (response) {
      self.projects = response['items'];
    });
  }

  getProjectsInProgress() {
    let self = this;
    this.api.read('projects&data[page]=1&data[limit]=50&data[sort]=number&data[direction]=ASC&data[filters][status][values]=IN_PROGRESS').then(function (response) {
      self.inProgressProjects = response['items'];
    });
  }

  getProjectsInPlan() {
    let self = this;
    this.api.read('projects&data[page]=1&data[limit]=50&data[sort]=number&data[direction]=ASC&data[filters][status][values]=PLAN').then(function (response) {
      self.planProjects = response['items'];
    });
  }

  getCostsProjects() {
    let self = this;
    this.api.read('projects&data[page]=1&data[limit]=50&data[sort]=number&data[direction]=ASC&data[filters][status][values]=COSTS_SEND').then(function (response) {
      self.costsProjects = response['items'];
    });
  }

  getFinishedProjects() {
    let self = this;
    this.api.read('projects&data[page]=1&data[limit]=50&data[sort]=number&data[direction]=ASC&data[filters][status][values]=READY_FOR_INVOICE').then(function (response) {
      self.finishedProjects = response['items'];
    });
  }

  getWeekProjects() {
    let self = this;
    this.api.read('projects/week/' + self.currentWeek + '/' + self.currentYear + '').then(function (response) {
      self.weekOverview1 = response['week1'];
      self.weekOverview2 = response['week2'];
      self.currentWeek = response['currentWeek'];
      self.currentYear = response['currentYear'];
      self.nextWeek = response['nextWeek'];
      self.nextYear = response['nextYear'];
      self.previousWeek = response['previousWeek'];
      self.previousYear = response['previousYear'];
    });
  }


  public daysPast(dateString) {
    const date = new Date(dateString);

    const milliseconds = Date.now() - date.getTime();

    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));

    const past = days > 1 ? "dagen" : "dag";
    return `${Math.abs(days)} ${past} oud`;
  }

  public daysTillStart(dateString) {
    const date = new Date(dateString);

    const milliseconds = Date.now() - date.getTime();

    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));

    const past = days < 1 ? "dagen" : "dag";
    return `${Math.abs(days)} ${past} tot uitvoering`;
  }

  showWarning(dateString) {
    const date = new Date(dateString);
    const milliseconds = Date.now() - date.getTime();

    const days = Math.floor(milliseconds / (1000 * 60 * 60 * 24));

    return days > 7;
  }

  dutchDateFormat(dateString) {
    // Split the date string into year, month, and day components
    const [year, month, day] = dateString.split("-");

    // Return the reformatted date string
    return `${day}-${month}-${year}`;
  }

  getDutchWeekday(dateString) {
    const weekdays = ['Zondag', 'Maandag', 'Dinsdag', 'Woensdag', 'Donderdag', 'Vrijdag', 'Zaterdag'];
    const date = new Date(dateString);
    const weekdayIndex = date.getDay();
    const dutchWeekday = weekdays[weekdayIndex];
    return dutchWeekday;
  }

  prev() {
    this.currentWeek = this.previousWeek;
    this.currentYear = this.previousYear;
    this.getWeekProjects();
  }

  next() {
    this.currentWeek = this.nextWeek;
    this.currentYear = this.nextYear;
    this.getWeekProjects();
  }
}
