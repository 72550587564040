import { Component, OnInit, Directive, Input, Output, ElementRef, HostListener, EventEmitter } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { OrganisationsService } from '../../services/organisations.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';
import { ModalSwapOrganisationComponent } from 'src/app/modals/modal-swap-organisation/modal-swap-organisation.component';
import { AppService } from 'src/app/services/app.service';
import { OrganisationsFormComponent } from '../organisations-form/organisations-form.component';

@Component({
	selector: 'app-organisations-index',
	templateUrl: './organisations-index.component.html',
	styleUrls: ['./organisations-index.component.scss']
})

export class OrganisationsIndexComponent implements OnInit {
	organisations: any = [];
	link = 'organisations';
	editPath = 'organisations/form/';
	refresh: any = 0;
	mapping = [
		{ key: 'number', label: 'Nummer', width: '140px', sort: true },
		{ key: 'name', label: 'Naam', sort: true },
		{ key: 'createdAtText', label: 'Gemaakt op', sort: true, sortType: "date" },
		{ key: 'updatedAtText', label: 'Gewijzigd op', sort: true, sortType: "date" },
		{ key: 'activeLabel', label: 'Actief', sort: true },
		{ key: 'city', label: 'Plaats', sort: true }
	];

	organisationLocal = [];

	constructor(
		public organisationsService: OrganisationsService,
		private appService: AppService,
		private modalService: NgbModal,
		private ngxLoader: NgxUiLoaderService
	) { }

	ngOnInit(): void {
		this.appService.setPageTitle('Klanten');
		this.appService.setNavKey('organisations');
	}

	all() {
		let self = this;
		this.organisationsService.read('organisations').then((response) => {
			self.organisations = response['items'];
			self.organisationLocal = response['items'];
		}).catch(function (error) {
		});
	}

	delete(organisation) {
		let self = this;
		self.appService.setLoaderStatus(true);

		this.organisationsService.delete('organisations/delete/' + organisation.id + '').then((response) => {

			self.appService.setLoaderStatus(false);
			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageText('DEFAULT_DELETED');
			self.appService.setSystemMessageType('DANGER');

			self.refresh = Math.random();
		}).catch(function (error) {
			self.appService.setLoaderStatus(false);

			self.appService.setSystemMessageLoading(true);
			self.appService.setSystemMessageText('DEFAULT_ERROR');
			self.appService.setSystemMessageType('DANGER');
		});
	}

	editRow(item) {
		this.openClientForm({ id: item['id'] });
	}

	deleteRow(item) {
		this.confirmDelete(item);
	}

	openClientForm(data) {
		let self = this;

		sessionStorage.setItem('organisation-tab', 'info');
		const filterModal = this.modalService.open(OrganisationsFormComponent, {
			animation: true,
			windowClass: 'modal-right',
			backdrop: 'static',
			size: 'lg'
		});

		filterModal.componentInstance.id = data['id'];
		filterModal.result.then((result) => {
			self.refresh = Math.random();
		})
			.catch((error) => {
			});
	}

	confirmDelete(organisation) {
		const deleteModal = this.modalService.open(ModalSwapOrganisationComponent);
		deleteModal.componentInstance.message = 'Weet je zeker dat je ' + organisation.name + ' wilt verwijderen? Selecteer welke klant de projecten moet overnemen.';

		deleteModal.result.then((result) => {
			if (result) {
				this.delete(organisation);
			}
		});
	}
}
