import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './pages/index/index.component';

import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';

import { HttpClientModule } from '@angular/common/http';

import { ReactiveFormsModule } from '@angular/forms';
import { OrganisationsIndexComponent } from './pages/organisations-index/organisations-index.component';
import { LoginComponent } from './pages/login/login.component';
import { OrganisationsFormComponent } from './pages/organisations-form/organisations-form.component';
import { AuthGuard } from './services/core/authguard.service';

import { FormsModule } from '@angular/forms';
import { ModalContentComponent } from './modals/modal-content/modal-content.component';
import { ModalConfirmDeleteComponent } from './modals/modal-confirm-delete/modal-confirm-delete.component';
import { ModalSwapOrganisationComponent } from './modals/modal-swap-organisation/modal-swap-organisation.component';
import { HasErrorPipe } from './pipes/has-error.pipe';
import { UsersIndexComponent } from './pages/users-index/users-index.component'; 

import { ConfigService }  from './services/core/config.service';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { NotauthorizedComponent } from './pages/notauthorized/notauthorized.component';
import { LogsComponent } from './pages/logs/logs.component';

import { registerLocaleData } from '@angular/common';
import LocaleNl from '@angular/common/locales/nl';

import {NgxPaginationModule} from 'ngx-pagination';
import { TableGuruComponent } from './components/table-guru/table-guru.component';
import { AddFilterComponent } from './components/table-guru/modals/add-filter/add-filter.component';
import { EditFilterComponent } from './components/table-guru/modals/edit-filter/edit-filter.component';
import { UserEditModalComponent } from './components/users/user-edit-modal/user-edit-modal.component';
import { QuotesIndexComponent } from './pages/quotes-index/quotes-index.component';
import { ProjectsDetailComponent } from './pages/projects-detail/projects-detail.component';
import { ProjectInfoFormComponent } from './components/projects/project-info-form/project-info-form.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { ProjectInfoFilesComponent } from './components/projects/project-info-files/project-info-files.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { UserGroupsIndexComponent } from './pages/user-groups-index/user-groups-index.component';
import { CategoriesIndexComponent } from './pages/categories-index/categories-index.component';
import { CategoriesFormComponent } from './pages/categories-form/categories-form.component';
import { UserGroupsFormComponent } from './pages/user-groups-form/user-groups-form.component';
import { QuoteAcceptedFormComponent } from './components/projects/quote-accepted-form/quote-accepted-form.component';
import { QuoteStatusFormComponent } from './components/projects/quote-status-form/quote-status-form.component';
import { ProjectsIndexComponent } from './pages/projects-index/projects-index.component';
import { ArchiveIndexComponent } from './pages/archive-index/archive-index.component';
import { FilePreviewComponent } from './modals/file-preview/file-preview.component';
import { AbcComponent } from './pages/projects-detail/abc/abc.component';
import { CalculationItemsComponent } from './pages/projects-detail/calculation-items/calculation-items.component';
import { QualityComponent } from './pages/quality/quality.component';
import { ProjectHoursComponent } from './pages/projects-detail/project-hours/project-hours.component';
import { ProjectHoursMobileComponent } from './pages/projects-detail/project-hours-mobile/project-hours-mobile.component';
import { HoursComponent } from './pages/hours/hours.component';
import { ProjectHoursUsersComponent } from './pages/projects-detail/project-hours/project-hours-users/project-hours-users.component';
import { ProjectHoursMobileUsersComponent } from './pages/projects-detail/project-hours-mobile/project-hours-mobile-users/project-hours-users.component';
import { ProjectJournalComponent } from './pages/projects-detail/project-journal/project-journal.component';
import { ProjectJournalMobileComponent } from './pages/projects-detail/project-journal-mobile/project-journal-mobile.component';
import { WeekFormComponent } from './components/week-form/week-form.component';
import { FileCategoryFormComponent } from './components/file-category-form/file-category-form.component';
import { UserHourRegistrationComponent } from './components/user-hour-registration/user-hour-registration.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { SettingsComponent } from './pages/settings/settings.component';
import { SettingsCalculationTemplatesComponent } from './pages/settings-calculation-templates/settings-calculation-templates.component';
import { SettingsCalculationFormComponent } from './pages/settings-calculation-templates/settings-calculation-form/settings-calculation-form.component';
import { SettingsCalculationMutateModalComponent } from './pages/settings-calculation-templates/settings-calculation-mutate-modal/settings-calculation-mutate-modal.component';
import { CalculcationTemplateListComponent } from './pages/projects-detail/calculation-items/calculcation-template-list/calculcation-template-list.component';
import { ProjectPermitStatusComponent } from './pages/projects-detail/project-permit-status/project-permit-status.component';
import { ProjectFilesComponent } from './pages/projects-detail/project-files/project-files.component';
import { ProjectFilesTreeComponent } from './pages/projects-detail/project-files-tree/project-files-tree.component';
import { QuickViewClientComponent } from './quick-views/quick-view-client/quick-view-client.component';
import { QuickViewContactComponent } from './quick-views/quick-view-contact/quick-view-contact.component';
import { QuickViewUserGroupComponent } from './quick-views/quick-view-user-group/quick-view-user-group.component';
import { QuickViewUserSiteManagerComponent } from './quick-views/quick-view-user-site-manager/quick-view-user-site-manager.component';
import { ProjectsMapsComponent } from './pages/projects-maps/projects-maps.component';
import { ProjectsPdfComponent } from './pages/projects-detail/projects-pdf/projects-pdf.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { SetupPasswordComponent } from './pages/setup-password/setup-password.component';
import { AuthenticationService } from './services/core/authentication.service';
import { EditFileNameComponent } from './pages/projects-detail/project-files/edit-file-name/edit-file-name.component';
import { EditFileNameTreeComponent } from './pages/projects-detail/project-files-tree/edit-file-name-tree/edit-file-name-tree.component';
import { EditCategoryNameTreeComponent } from './pages/projects-detail/project-files-tree/edit-category-name-tree/edit-category-name-tree.component';
import { EditCategoryOrderComponent } from './pages/projects-detail/project-files-tree/edit-category-order/edit-category-order.component';
import { ProjectHoursNotesComponent } from './pages/projects-detail/project-hours/project-hours-notes/project-hours-notes.component';
import { ProjectNotesComponent } from './pages/projects-detail/project-notes/project-notes.component';
import { EditKlicNote } from './pages/projects-detail/edit-klic-note/edit-klic-note.component';
import { GenerateQuoteComponent } from './pages/projects-detail/generate-quote/generate-quote.component';
import { SendQuoteComponent } from './pages/projects-detail/send-quote/send-quote.component';
import { QuoteMailsComponent } from './pages/projects-detail/quote-mails/quote-mails.component';
import { SettingsSpecialDatesComponent } from './pages/settings-special-dates/settings-special-dates.component';
import { SettingsSpecialDatesFormComponent } from './pages/settings-special-dates-form/settings-special-dates-form.component';
import { BestandsviewerComponent } from './pages/bestandsviewer/bestandsviewer.component';
import { Select2Module } from 'ng-select2-component';
import { SendCostsComponent } from './pages/projects-detail/send-costs/send-costs.component';
import { CalendarPdfComponent } from './pages/calendar/calendar-pdf/calendar-pdf.component';
import { UserCategoryComponent } from './components/users/user-category/user-category.component';
import { SendTermstateComponent } from './pages/projects-detail/send-termstate/send-termstate.component';
import { SendInvoiceComponent } from './pages/projects-detail/send-invoice/send-invoice.component';
import { CalendarPdfOldComponent } from './pages/calendar/calendar-pdf-old/calendar-pdf-old.component';
import { PictureExportComponent } from './pages/projects-detail/project-files/picture-export/picture-export.component';
import { ModalExportComponent } from './modals/modal-export/modal-export.component';
import { FilesComponent } from './pages/files/files.component';
import { ProjectTermstatesComponent } from './pages/projects-detail/project-termstates/project-termstates.component';
import { FinancialComponent } from './pages/financial/financial.component';
import { CreateTermstateComponent } from './pages/projects-detail/create-termstate/create-termstate.component';
import { ModalSelectYearComponent } from './modals/modal-select-year/modal-select-year.component';
import { CertificateTypesIndexComponent } from './pages/certificate-types-index/certificate-types-index.component';
import { CertificateTypesFormComponent } from './pages/certificate-types-form/certificate-types-form.component';
import { UserAddCertificateModalComponent } from './components/users/user-add-certificate-modal/user-add-certificate-modal.component';

registerLocaleData(LocaleNl);

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "#5c2d2d",
  "bgsOpacity": 0.5,
  "bgsPosition": "bottom-right",
  "bgsSize": 60,
  "bgsType": "ball-spin-clockwise",
  "blur": 5,
  "delay": 0,
  "fastFadeOut": true,
  "fgsColor": "#001e79",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "pulse",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(255,255,255,0.8)",
  "pbColor": "red",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": false,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 300
};

export function appInit(configService: ConfigService) {
  return () => configService.load();
}

export function appInit2(authenticationService: AuthenticationService) {
  return () => authenticationService.load();
}

@NgModule({
  declarations: [
    AppComponent,
    IndexComponent,
    OrganisationsIndexComponent,
    LoginComponent,
    OrganisationsFormComponent,
    ModalContentComponent,
    ModalConfirmDeleteComponent,
    ModalSwapOrganisationComponent,
    HasErrorPipe,
    UsersIndexComponent, 
    NotauthorizedComponent,
    LogsComponent,
    TableGuruComponent,
    AddFilterComponent,
    EditFilterComponent,
    UserEditModalComponent,
    QuotesIndexComponent,
    ProjectsDetailComponent,
    ProjectInfoFormComponent,
    ProjectInfoFilesComponent,
    CalendarComponent,
    UserGroupsIndexComponent,
    CategoriesIndexComponent,
    CategoriesFormComponent,
    UserGroupsFormComponent,
    QuoteAcceptedFormComponent,
    QuoteStatusFormComponent,
    ProjectsIndexComponent,
    ArchiveIndexComponent,
    FilePreviewComponent,
    AbcComponent,
    CalculationItemsComponent,
    QualityComponent,
    ProjectHoursComponent,
    ProjectHoursMobileComponent,
    HoursComponent, 
    ProjectHoursUsersComponent,
    ProjectHoursMobileUsersComponent,
    ProjectJournalComponent,
    ProjectJournalMobileComponent,
    WeekFormComponent,
    FileCategoryFormComponent,
    UserHourRegistrationComponent,
    MyProfileComponent,
    SettingsComponent,
    SettingsCalculationTemplatesComponent,
    SettingsCalculationFormComponent,
    SettingsCalculationMutateModalComponent,
    CalculcationTemplateListComponent,
    ProjectPermitStatusComponent,
    ProjectFilesComponent,
    ProjectFilesTreeComponent,
    QuickViewClientComponent,
    QuickViewContactComponent,
    QuickViewUserGroupComponent,
    QuickViewUserSiteManagerComponent,
    ProjectsMapsComponent,
    ProjectsPdfComponent,
    ForgotPasswordComponent,
    SetupPasswordComponent,
    EditFileNameComponent,
    EditFileNameTreeComponent,
    EditCategoryNameTreeComponent,
    EditCategoryOrderComponent,
    ProjectHoursNotesComponent,
    ProjectNotesComponent,
    EditKlicNote,
    GenerateQuoteComponent,
    SendQuoteComponent,
    QuoteMailsComponent,
    SettingsSpecialDatesComponent,
    SettingsSpecialDatesFormComponent,
    BestandsviewerComponent,
    SendCostsComponent,
    CalendarPdfComponent,
    UserCategoryComponent,
    SendTermstateComponent,
    SendInvoiceComponent,
    CalendarPdfOldComponent,
    PictureExportComponent,
    ModalExportComponent,
    FilesComponent,
    ProjectTermstatesComponent,
    FinancialComponent,
    CreateTermstateComponent,
    ModalSelectYearComponent,
    CertificateTypesIndexComponent,
    CertificateTypesFormComponent,
    UserAddCertificateModalComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    ReactiveFormsModule,
    FormsModule,
    Select2Module,
    AppRoutingModule,
    DragDropModule,
    NgxDropzoneModule,
    NgxPaginationModule
  ],
  providers: [
    AuthGuard,
	  ConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit,
      multi: true,
      deps: [ConfigService]
    },
	  AuthenticationService,
    {
      provide: APP_INITIALIZER,
      useFactory: appInit2,
      multi: true,
      deps: [AuthenticationService]
    },
    { provide: LOCALE_ID, 
      useValue: "nl" },
   ],
  bootstrap: [AppComponent],
  entryComponents: [ ModalContentComponent ]
})
export class AppModule { }
