<header class="page-header">
    <div class="row">
        <div class="col-8">
            <h1>Financieel</h1>
            <h6>Financieel / overzicht</h6>
        </div>
        <div class="col-4 text-end pt-3">
            <button (click)="openExportModal()" class="btn btn-dark with-icon mr-2">Exporteren <i
                    class="bi bi-box-arrow-down"></i></button>
        </div>
    </div>
</header>

<app-table-guru [editParam]="editParam" [link]="link" [refresh]="refresh" [mapping]="mapping"
    [editPath]="editPath" (sortAction)="saveSort($event)" (editAction)="editTermstate($event)" (deleteAction)="deleteTermstate($event)" [showEditButtons]="true" ></app-table-guru>