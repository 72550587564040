<div [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="modal-header" *ngIf="item">
        <h5 class="modal-title">
            <span *ngIf="item['id']">{{item['number']}} / Bestanden uploaden</span>
        </h5>
        <button type="button" class="btn-close" (click)="close()" tabindex="-1" aria-label="Close"></button>
    </div>
    <div class="modal-body">	
        <div class="" *ngIf="loading==true" style="text-align:center;padding-top:140px;">
            <div class="spinner-border text-dark" role="status">
                <span class="sr-only"></span>
            </div>
        </div>

        <div class="row">
            <div class="col-6">
                <h5 class="pb-3"><i class="bi bi-file-earmark-text"></i> Bestanden</h5>
                <!-- <app-project-files [project]="item" [fileType]="'projectFiles'" [hideMeta]="true" [target]="''"></app-project-files> -->
                <app-project-files-tree [project]="item" [hideMeta]="true" [target]="''"></app-project-files-tree>
            </div>
            <div class="col-6">
                <h5 class="pb-3"><i class="bi bi-image"></i> Foto's</h5>
                <app-project-files [project]="item" [fileType]="'projectPhotos'" [hideMeta]="true" [target]="''"></app-project-files>
            </div>
        </div>
    </div> 
    <div class="modal-footer" *ngIf="item">
        <button (click)="continue()" class="btn btn-success with-icon">
            Verder <i class="bi bi-arrow-right"></i>
        </button>
    </div>  
</div>