import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { AuthenticationService } from 'src/app/services/core/authentication.service';

import {
  CdkDragDrop,
  CdkDragEnter,
  CdkDragMove,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { ConfigService } from 'src/app/services/core/config.service';

@Component({
  selector: 'app-generate-quote',
  templateUrl: './generate-quote.component.html',
  styleUrls: ['./generate-quote.component.scss']
})
export class GenerateQuoteComponent implements OnInit {
  @Input() public title;
  @Input() public button;
  @Input() public message;
  @Input() public project;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  showSuccess = false;
  content;
  loaderOverlay = true;
  files = [];
  photos = [];
  previewUrl = '';
  photoTabExtended = false;
  currentUser;

  obj;

  constructor(
    public activeModal: NgbActiveModal,
    public appService: AppService,
    public config: ConfigService,
    public api: ApiConnectionService,
    public sanitizer: DomSanitizer,
    public authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.fetchItems();
    this.generateUrl();

    this.authService.getCurrentUser();
    this.authService.data.subscribe(data => {
      this.currentUser = data;
      this.fetchPreview();
    });
  }

  generateUrl() {
    let url = this.config.get('api_url');
    url += 'projects/quotePdf/' + this.project['id'] + '?t=' + Math.random() + '';
    let options = [];
    for (let i = 0; i < this.files.length; i++) {
      if (this.files[i]['selected'] === true) {
        options.push(this.files[i]['id']);
      }
    }
  }

  setActive(entry) {
    entry['selected'] = true;
    this.generateUrl();
  }

  setInActive(entry) {
    entry['selected'] = false;
    this.generateUrl();
  }

  fetchPreview() {
    let link = '<object data="' + this.config.get('api_url') + 'projects/quotePdf/' + this.project['id'] + '&preview=1&hash=' + this.currentUser['publicHash'] + '" style="width:100%;height:600px;" type="application/pdf"></object>';
    this.message = link;
    this.content = this.sanitizer.bypassSecurityTrustHtml(this.message);
  }

  fetchItems() {
    let self = this;
    self.api.read('projects/quoteFiles/' + self['project']['id'] + '').then(function (response) {
      self.files = response['files'];
      self.loaderOverlay = false;
    });
    this.api.read('projects/details/' + self['project']['id'] + '/photos').then((response) => {
      let items = response['items'];

      for (let i = 0; i < items.length; i++) {
        let item = items[i];
        item['selected'] = true;
        self.photos.push(item);
      }
    });
  }

  selectedPhotoCount() {
    let count = 0;
    for (let i = 0; i < this.photos.length; i++) {
      if (this.photos[i].selected == true) {
        count++;
      }
    }
    return count;
  }

  dropPending(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.files, event.previousIndex, event.currentIndex);
    return;
  }

  generatePreview() {
    this.appService.setLoaderStatus(true);
    let self = this;
    let url = '';
    url += 'projects/saveQuotePdfSettings/' + this.project['id'] + '?t=' + Math.random() + '';

    let options = [];
    for (let i = 0; i < this.files.length; i++) {
      if (this.files[i]['selected'] === true) {
        options.push(this.files[i]['id']);
      }
    }

    let photos = [];
    for (let i = 0; i < this.photos.length; i++) {
      if (this.photos[i]['selected'] === true) {
        photos.push(this.photos[i]['id']);
      }
    }

    this.api.save(url, {
      files: options,
      photos: photos
    }).then(function (response) {
      setTimeout(function () { self.appService.setLoaderStatus(false); }, 1000);
      self.fetchPreview();
    });
  }

  saveQuote() {
    let self = this;
    self.appService.setLoaderStatus(true);
    self.loaderOverlay = true;
    self.api.read('projects/quotePdf/' + this.project['id'] + '').then(function (response) {
      self.appService.setLoaderStatus(false);
      self.showSuccess = true;
      self.loaderOverlay = false;
    });
  }

  showFiles() {
    this.activeModal.close('SHOW_FILES');
  }

  showQuoteEmail() {
    this.activeModal.close('SHOW_EMAIL');
  }


  confirm() {
    this.activeModal.close(false);
  }

  cancel() {
    this.activeModal.close(false);
  }

  close() {
    this.activeModal.close(false);
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  togglePhotoTab() {
    if (this.photoTabExtended == true) {
      this.photoTabExtended = false;
    } else {
      this.photoTabExtended = true;
    }
  }
}
