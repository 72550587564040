<div [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="modal-header">
        <h4 class="modal-title">Wijzig categorie naam</h4>
        <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
    </div>
    <div class="modal-body p-1"> 
        <div class="row">
            <div class="col-12 p-5 pb-2">
                Nieuwe naam:<br/>
                <input class="form-control" type="text" placeholder="" [(ngModel)]="newName" />
            </div>
            <div class="col-12 p-5 pt-2">
                Rol:<br/>
                <select class="form-control" [(ngModel)]="newRank">
                    <option value="ADMIN">Beheerder</option>
                    <option value="MANAGER">Uitvoerder</option>
                    <option value="USER">Gebruiker</option>
                </select>        
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="delete()" class="btn btn-dark with-icon">
            Verwijderen <i class="bi bi-trash"></i>
        </button>
        <button (click)="confirm()" class="btn btn-success with-icon">
            Verder <i class="bi bi-arrow-right"></i>
        </button>
    </div>  
</div>