import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { ProjectInfoFormComponent } from 'src/app/components/projects/project-info-form/project-info-form.component';
import { AuthenticationService } from 'src/app/services/core/authentication.service';

@Component({
	selector: 'app-quick-view-contact',
	templateUrl: './quick-view-contact.component.html',
	styleUrls: ['./quick-view-contact.component.scss']
})
export class QuickViewContactComponent implements OnInit {
	loaderOverlay = true;
	@Input() public id;
	@Input() public project;
	item;
	organisation;
	currentUser;

	constructor(
		public activeModal: NgbActiveModal,
		public appService: AppService,
		public authenticationService: AuthenticationService,
		public modalService: NgbModal,
		public api: ApiConnectionService,
		public sanitizer: DomSanitizer
	) {
		let self = this;
		self.authenticationService.getCurrentUser();
		self.authenticationService.data.subscribe(data => {
			if (Object.keys(data).length) {
				self.currentUser = data;
			} else {
				self.currentUser = false;
			}
		});
	}

	ngOnInit(): void {
		let self = this;
		this.appService.setLoaderStatus(true);
		setTimeout(function () {
			self.fetchItem();
		}, 500);
	}

	transformToArray(value) {
		if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
			return Object.values(value); // Converts object values to an array
		}
		return value; // Return as-is if not an object or already an array
	}


	remove() {
		// console.log(this.project)
		// if (item['id'])

		const contactPersonId = this.item['id'];


		const contactIndex = this.transformToArray(this.project.contactPersonIds.indexOf(contactPersonId));

		if (contactIndex !== -1) {
			if (contactIndex == 0) {
				this.project['contactPersonId'] = null;
			} else if (contactIndex == 1) {
				this.project['contactPerson2Id'] = null;
			} else if (contactIndex == 2) {
				this.project['contactPerson3Id'] = null;
			}

			if (this)
				this.api
					.save("projects/save", this.project)
					.then((response) => {
						this.close();
					});
		}
	}

	openForm(data) {
		let self = this;
		let id = self.project['id'];

		const filterModal = this.modalService.open(ProjectInfoFormComponent, {
			windowClass: 'modal-right',
			backdrop: 'static',
			size: 'lg'
		});

		filterModal.componentInstance.id = id;
		filterModal.result.then((result) => {
			self.fetchItem();
		})
			.catch((error) => {
			});
	}

	openFormProject() {
		let self = this;
		let id = self.project['id'];

		const filterModal = this.modalService.open(ProjectInfoFormComponent, {
			windowClass: 'modal-right',
			backdrop: 'static',
			size: 'lg'
		});

		filterModal.componentInstance.id = id;
		filterModal.componentInstance.currentTab = 'status';

		filterModal.result.then((result) => {
			self.fetchItem();
		})
			.catch((error) => {
			});
	}

	confirm() {
		this.activeModal.close(false);
	}

	cancel() {
		this.activeModal.close(false);
	}

	close() {
		this.activeModal.close(false);
	}

	fetchItem() {
		let self = this;
		this.api.read('users/details/' + self.id + '').then((response) => {
			self.appService.setLoaderStatus(false);
			self.loaderOverlay = false;
			self.item = response;
			console.log(self.item)
			self.fetchOrganisation(response['organisationId']);
		});
	}

	fetchOrganisation(id) {
		let self = this;
		this.api.read('organisations/details/' + id + '').then((response) => {
			self.organisation = response;
		});
	}
}
