import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export enum FileOpenerStatus {
  CONNECTED = 'CONNECTED',
  CONNECTING = 'CONNECTING',
  FAILED = 'FAILED'
}

@Injectable({
  providedIn: 'root'
})
export class FileOpenerService {
  private statusSubject = new BehaviorSubject<FileOpenerStatus>(FileOpenerStatus.CONNECTING);
  status$ = this.statusSubject.asObservable();

  socket: WebSocket | null = null;

  setupWebsocket() {
    this.statusSubject.next(FileOpenerStatus.CONNECTING);
    this.socket = new WebSocket("ws://localhost:8081");

    this.socket.onopen = () => {
      console.log("Connected to file opener service");
      this.statusSubject.next(FileOpenerStatus.CONNECTED);
    };

    this.socket.onmessage = (event) => {
      console.log("Response:", event.data);
    };

    this.socket.onerror = (error) => {
      console.error("WebSocket Error:", error);
      this.statusSubject.next(FileOpenerStatus.FAILED);
    };

    this.socket.onclose = () => {
      console.warn("WebSocket closed");
      this.statusSubject.next(FileOpenerStatus.FAILED);
    };
  }

  requestReconnect() {
    this.setupWebsocket();
  }

  openFile(filePath: string) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(filePath);
    } else {
      console.error("Socket is not open");
    }
  }
}
