import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiConnectionService } from 'src/app/services/core/api-connection.service';

@Component({
	selector: 'app-modal-export',
	templateUrl: './modal-export.component.html',
	styleUrls: ['./modal-export.component.scss']
})
export class ModalExportComponent implements OnInit {
	@Input() public message;
	@Input() public exportType;
	@Input() public sortFilter;
	@Output() passEntry: EventEmitter<any> = new EventEmitter();

	public exportingPDF = false;
	public exportingExcel = false;

	filters = [];
	filterOptions = [];

	constructor(
		public activeModal: NgbActiveModal,
		public api: ApiConnectionService
	) { }

	ngOnInit(): void {
		setTimeout(function () {
			document.getElementById('delete-btn').focus();
		}, 100);
	}

	ngAfterViewInit() {
	}

	getFormattedDate() {
		const date = new Date();

		// Extract day, month, and year
		let day: any = date.getDate();
		let month: any = date.getMonth() + 1; // Months are zero-based
		let year = date.getFullYear().toString().slice(-2); // Get last two digits of the year

		// Pad day and month with leading zero if needed
		day = day < 10 ? '0' + day : day;
		month = month < 10 ? '0' + month : month;

		return `${day}-${month}-${year}`;
	}

	confirm(fileType: string) {
		let url = "projects/export";
		if (this.exportType === "termStatements") {
			url = "termStatements/export";
		}

		if (fileType === 'pdf') {
			this.exportingPDF = true;
			url += "Pdf";
		} else if (fileType === 'excel') {
			this.exportingExcel = true;
			url += "Excel";
		}

		let filterStorageItem = localStorage.getItem('filters');
		let filterStorage = {};
		if (filterStorageItem) {
			filterStorage = JSON.parse(filterStorageItem);
		}

		if (this.exportType === "quotes") {
			url += "&data[forQuote]=1";
		}

		if (filterStorage[this.exportType]?.filters) {
			// Encode the filter values properly
			const encodedFilters = this.encodeFilters(filterStorage[this.exportType].filters);
			url += "&" + encodedFilters;
		}

		if (this.sortFilter.sort == undefined) {
			url += '&data[sort]=' + this.encodeFilters("name");
			url += '&data[direction]=' + this.encodeFilters("ASC");
		} else {
			url += '&data[sort]=' + this.encodeFilters(this.sortFilter.sort);
			url += '&data[direction]=' + this.encodeFilters(this.sortFilter.direction);
		}

		this.api.getPublicHash();

		let dataExtension = "";

		if (fileType === 'pdf') {
			dataExtension = ".pdf";
		} else if (fileType === 'excel') {
			dataExtension = ".xlsx";
		}

		let fileName = "";

		if (this.exportType === "quotes") {
			fileName = "offertes_" + this.getFormattedDate();
		} else if (this.exportType === "financial") {
			fileName = "financieel_" + this.getFormattedDate();
		} else {
			fileName = "projecten_" + this.getFormattedDate();
		}

		this.api.downloadExport(fileName + dataExtension, url).then(() => {
			if (fileType === 'pdf') {
				this.exportingPDF = false;
			} else if (fileType === 'excel') {
				this.exportingExcel = false;
			}
		});
	}

	encodeFilters(filters: string): string {
		// Replace spaces with "+" after encoding the URL component
		return encodeURIComponent(filters).replace(/%20/g, '+');
	}


	cancel() {
		this.activeModal.close(false);
	}

	close() {
		this.activeModal.close(false);
	}
}
