<section [ngClass]="{'loader-overlay': loaderOverlay}">
	<div class="modal-header">
	<h5 class="modal-title">Medewerker(s) toevoegen</h5>
	<button type="button" class="btn-close" (click)="close()" tabindex="-1" aria-label="Close"></button>
	</div>
	<div class="modal-body">
	<div class="row">
		<div class="col-12" style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:15px;">
			<small>Selecteer uit de onderstaande opties</small>
		</div>
	</div>
	<div class="row" style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;">
		<div class="col-1 pt-2">
			<i class="bi bi-square-fill check-inactive check-icon ms-1" *ngIf="selectAllSelected==false" (click)="selectAll()"></i>
			<i class="bi bi-check-square-fill check-icon ms-1" *ngIf="selectAllSelected==true" (click)="deSelectAll()"></i>
		</div>
		<div class="col-11">
			<input type="text" value="" class="form-control" [(ngModel)]="keyword" placeholder="Zoeken">
		</div>
	</div>
	<div class="row" *ngFor="let user of searchUsers()" [ngClass]="{'d-none': activeUserIds[user['id']]}" (click)="toggleSelectedUser(user)">
		<div *ngIf="user['active'] == 1" class="col-1 pt-3" style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;">
			<i class="bi bi-square-fill check-inactive check-icon ms-1" *ngIf="!selectedUsers[user['id']]"></i>
			<i class="bi bi-check-square-fill check-icon ms-1" *ngIf="selectedUsers[user['id']]"></i>
		</div>
		<div *ngIf="user['active'] == 0" class="col-1 pt-3" style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;opacity:0.4">
			<i class="bi bi-check-square check-icon ms-1"></i>
		</div>
		<div *ngIf="user['active'] == 0"  class="col-8" style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;opacity:0.4">
			{{user['display']}}<br/><small class="text-muted">{{user['email']}}</small>
		</div>
		<div *ngIf="user['active'] == 1"  class="col-8" style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;">
			{{user['display']}}<br/><small class="text-muted">{{user['companyName']}}<br>({{user['email']}})</small>
		</div>
		<div class="col-3 pt-3 ps-1" style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;" [innerHTML]="user['activeLabel']">
		</div>
	</div>
	<div class="row">
		<div class="col-6 py-3">
			<div (click)="openUserForm({})" class="cursor-pointer">+ Werknemer toevoegen</div>
		</div>
		<div class="col-6 py-3">
			<div (click)="openMaterialForm({})" class="cursor-pointer">+ Materieel toevoegen</div>
		</div>
	</div>
	<input type="text" style="opacity:0;">
	</div>
	<div class="modal-footer">
	<button (click)="cancel()" type="submit" class="btn btn btn-link" style="text-decoration:none ! important;color:#000000;">Annuleren</button>
	<button (click)="confirm()" type="submit" class="btn btn-success">Toevoegen</button>
	</div>
</section>