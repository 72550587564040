<div class="modal-header">
	<h4 class="modal-title" id="modal-basic-title">Exporteren</h4>
	<button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
</div>
<div class="modal-body">
	{{message}}
	<input type="text" style="opacity:0;">
</div>
<div class="modal-footer">
	<button (click)="cancel()" type="submit" class="btn btn btn-link"
		style="text-decoration:none ! important;color:#000000;">Annuleren</button>
	<button (click)="confirm('pdf')" type="submit" class="btn btn-dark pdf-export-button" id="delete-btn">
		<ng-container *ngIf="exportingPDF"><i class="bi bi-arrow-repeat rotating"></i> exporteren..</ng-container>
		<ng-container *ngIf="!exportingPDF">PDF <i class="bi bi-file-earmark-pdf-fill"></i></ng-container></button>
	<button (click)="confirm('excel')" type="submit" class="btn btn-dark" id="delete-btn">
		<ng-container *ngIf="exportingExcel"><i class="bi bi-arrow-repeat rotating"></i> exporteren..</ng-container>
		<ng-container *ngIf="!exportingExcel">Excel <i class="bi bi-file-earmark-spreadsheet-fill"></i></ng-container></button>
</div>