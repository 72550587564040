import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { ConfigService } from 'src/app/services/core/config.service';

@Component({
	selector: 'app-edit-file-name-tree',
	templateUrl: './edit-file-name-tree.component.html',
	styleUrls: ['./edit-file-name-tree.component.scss']
})
export class EditFileNameTreeComponent implements OnInit {
	@Input() public project;
	@Input() public projectFile;
	@Input() public target;
	@Input() public type;
	@Output() passEntry: EventEmitter<any> = new EventEmitter();


	public error = false;
	public loaderOverlay = false;
	public statusOptions;
	public selectedStatus = 'NVT';
	public currentTab = 'info';

	constructor(
		public activeModal: NgbActiveModal,
		public appService: AppService,
		public api: ApiConnectionService,
		public config: ConfigService
	) {

	}

	ngOnInit(): void {

	}

	confirm() {
		let self = this;

		self.loaderOverlay = true;

		self.appService.setLoaderStatus(true);

		if (/[.,]+$/.test(self.projectFile['baseName'])) {
			this.error = true;
			this.loaderOverlay = false;
			self.appService.setLoaderStatus(false);
		} else {
			this.error = false;

			return this.api.save('' + self.target + '/changeFileName/' + self.projectFile['id'], {
				projectId: self.project['id'],
				name: self.projectFile['baseName'],
			}).then((response) => {
				self.loaderOverlay = false;

				self.appService.setSystemMessageLoading(true);
				self.appService.setSystemMessageType('SUCCESS');
				self.appService.setLoaderStatus(false);
				self.loaderOverlay = false;

				self.activeModal.close(true);

			}).catch(function (response) {
				self.appService.setSystemMessageLoading(true);
				self.appService.setSystemMessageText('DEFAULT_ERROR');
				self.appService.setSystemMessageType('DANGER');
				self.appService.setLoaderStatus(false);
				self.loaderOverlay = false;

				self.activeModal.close(true);
			});
		}
	}

	cancel() {
		this.activeModal.close(false);
	}

	close() {
		this.activeModal.close(false);
	}
}
