import { Component, OnInit, Input, Output, Inject, Renderer2, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';

import { AuthenticationService } from 'src/app/services/core/authentication.service';
import { FileOpenerService } from 'src/app/services/file-opener.service';

@Component({
	selector: 'app-file-preview',
	templateUrl: './file-preview.component.html',
	styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent implements OnInit {

	@Input() public title;
	@Input() public mobile;
	@Input() public button;
	@Input() public message;
	@Input() public fullscreen;
	@Input() public file;
	@Input() public fileList = [];
	@Output() passEntry: EventEmitter<any> = new EventEmitter();

	content;
	previousFile;
	nextFile;
	loaderOverlay = true;

	currentUser;

	constructor(
		public activeModal: NgbActiveModal,
		public appService: AppService,
		public sanitizer: DomSanitizer,
		@Inject(DOCUMENT) private document: Document,
		private renderer: Renderer2,
		private authenticationService: AuthenticationService,
		private fileOpener: FileOpenerService
	) {
		const self = this;

		self.authenticationService.getCurrentUser();
		self.authenticationService.data.subscribe(data => {
			if (Object.keys(data).length) {
				self.currentUser = data;
			} else {
				self.currentUser = false;
			}
		});
	}

	ngOnInit(): void {
		let self = this;

		self.appService.setLoaderStatus(true);

		if (this.file.ext === "msg") {
			this.message = this.message.replace("iframe", `iframe id='page-container'`);
		}

		if (this.file.full_path != undefined) {
			let extension = this.file.name.split('.').pop();
			const imgExtensions = ['png', 'jpg', 'jpeg', 'svg'];

			this.file.hasPreview = true;
			this.file.isImage = false;

			this.file["full_path"] = this.file["full_path"].replaceAll(" ", "+");

			let link =
				'<iframe src="https://api.kloens.app/managerFiles/get&filename=' +
				this.file["full_path"] +
				'&hash=' + this.currentUser['publicHash'] + '" style="width:100%;height:600px;"></iframe>';
			if (imgExtensions.includes(extension)) {
				link =
					'<img src="https://api.kloens.app/managerFiles/get&filename=' +
					this.file["full_path"] +
					'&hash=' + this.currentUser['publicHash'] + '" style="height:100%;">';
			}

			this.file.downloadUrl = "https://api.kloens.app/managerFiles/get&filename=" + this.file["full_path"] +
				'&hash=' + this.currentUser['publicHash'] + "&download=1";

			this.message = link
		}

		this.content = this.sanitizer.bypassSecurityTrustHtml(this.message);

		this.setNavIds();

		setTimeout(function () {
			self.loaderOverlay = false;
			self.appService.setLoaderStatus(false);
		}, 100);
	}

	toggleFullscreen() {
		this.fullscreen = !this.fullscreen
		if (this.fullscreen) {
			this.document.body.classList.add('fullscreen-modals');
		} else {
			this.document.body.classList.remove('fullscreen-modals');
		}
	}

	addHash(url) {
		return url + "&hash=" + this.currentUser['publicHash'];
	}

	setCurrentFile(file) {
		let self = this;
		self.loaderOverlay = true;

		this.file = file;

		let link = '<iframe data="' + file['viewUrl'] + '&hash=' + this.currentUser['publicHash'] + '" style="width:100%;height:600px;"></iframe>';
		if (file['isImage']) {
			link = '<img src="' + file['viewUrl'] + '&hash=' + this.currentUser['publicHash'] + '" style="">';
			setTimeout(function () {
				self.loaderOverlay = false;
				self.appService.setLoaderStatus(false);
			}, 20);
		}
		else {
			setTimeout(function () {
				self.loaderOverlay = false;
				self.appService.setLoaderStatus(false);
			}, 2000);
		}
		this.message = link;
		this.content = this.sanitizer.bypassSecurityTrustHtml(this.message);

		self.appService.setLoaderStatus(true);
		this.setNavIds();


	}

	setNavIds() {
		let self = this;

		self.previousFile = null;
		self.nextFile = null;

		let found = false;

		if (this.fileList) {
			this.fileList.every(item => {
				if (found == true) {
					self.nextFile = item;
					return false;
				}
				else {
					if (item['id'] === self.file['id']) {
						found = true;
					}
					else {
						self.previousFile = item;
					}
				}
				return true;
			});
		}
	}

	// transform(value) {

	//   return ;
	// }

	confirm() {
		this.activeModal.close(false);
		this.document.body.classList.remove('fullscreen-modals');
	}

	cancel() {
		this.activeModal.close(false);
		this.document.body.classList.remove('fullscreen-modals');
	}

	close() {
		this.activeModal.close(false);
		this.document.body.classList.remove('fullscreen-modals');
	}

	sanitize(url: string) {
		return this.sanitizer.bypassSecurityTrustUrl(url);
	}

	openProtocol(event, url) {
		event.preventDefault();

		// Attempt to open the custom protocol
		// window.location.href = url;
		url = url.replace("kloens-filemanager://", "K:\\");
		this.fileOpener.openFile(url);
	}
}