import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmDeleteComponent } from 'src/app/modals/modal-confirm-delete/modal-confirm-delete.component';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { Router } from '@angular/router';
import { SettingsCalculationMutateModalComponent } from './settings-calculation-mutate-modal/settings-calculation-mutate-modal.component';

@Component({
  selector: 'app-settings-calculation-templates',
  templateUrl: './settings-calculation-templates.component.html',
  styleUrls: ['./settings-calculation-templates.component.scss']
})
export class SettingsCalculationTemplatesComponent implements OnInit {
  items : any = [];
  link = 'calculationTemplates';
  editPath = 'calculationTemplates/form/';
  refresh : any = 0;
  mapping = [
    {key: 'name', label: 'Naam', sort: true},
    {key: 'createdAtText', label: 'Gemaakt op', sort: true, sortType: "date"},
    {key: 'updatedAtText', label: 'Gewijzigd op', sort: true, sortType: "date"}
  ];

  constructor(
    private app: AppService,
    private modalService: NgbModal,
    private router: Router,
    private api: ApiConnectionService
  ) { }

  ngOnInit(): void {
    this.app.setNavKey('settings');
    this.app.setPageTitle('Calculatietemplates');
  }

  delete (organisation) {
    let self = this;
	  self.app.setLoaderStatus(true);
    this.api.delete('calculationTemplates/delete/'+organisation.id+'').then((response) => {  
		  self.refresh = Math.random();

      self.app.setLoaderStatus(false);
      self.app.setSystemMessageLoading(true);
      self.app.setSystemMessageText('DEFAULT_DELETED');
      self.app.setSystemMessageType('DANGER');

      self.refresh = Math.random();
    }).catch (function (error) {
		  self.refresh = Math.random();

      self.app.setLoaderStatus(false);		
      
      self.app.setSystemMessageLoading(true);
      self.app.setSystemMessageText('DEFAULT_ERROR');
      self.app.setSystemMessageType('DANGER');
    });	
  }

  editRow(item) {
		this.router.navigate(['settings/calculation-templates/', item['id']]);
  }

  deleteRow(item) {
	  this.confirmDelete(item);
  }
  
  add () 
  {
  	let self = this;
    const modal = this.modalService.open(SettingsCalculationMutateModalComponent);
    modal.componentInstance.item = {'name': '', 'createdAt': 'x'};
    modal.result.then((result) => {
      if (result) {
        self.router.navigate(['/settings/calculation-templates/'+result['id']+'']);
      }
    });    
  }
  
  confirmDelete(organisation) {
  	let self = this;
    const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
    deleteModal.componentInstance.message = 'Weet je zeker dat je dit wilt verwijderen?';
    deleteModal.result.then((result) => {
      if (result) {
        this.delete(organisation);
      }
    });
  }
}
