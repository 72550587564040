<div [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="modal-header">
        <h4 class="modal-title">Wijzig {{type  | lowercase }} status</h4>
        <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
    </div>
    <div class="modal-body p-1" *ngIf="!mobile">
        <ul class="nav nav-fill mb-4" *ngIf="type != 'QUOTE'">
            <li class="nav-item">
                <span class="nav-link " (click)="setTab('info')" [ngClass]="{active: currentTab=='info'}">Informatie</span>
            </li>
            <li class="nav-item">
                <span class="nav-link" (click)="setTab('files')" [ngClass]="{active: currentTab=='files'}">Bestanden</span>
            </li>
        </ul>
        <div class="px-5 py-3" *ngIf="currentTab=='info'">
            <div class="row">
                <div class="col-3">Projectnummer</div>
                <div class="col-9">{{project['number']}}</div>
            </div>
            <div class="row">
                <div class="col-3">Project</div>
                <div class="col-9">{{project['name']}}</div>
            </div>
            <div class="row">
                <div class="col-3">Status {{type  | lowercase }}</div>
                <div class="col-9">{{getCurrentActiveStatusText()}}</div>
            </div>
            <div class="row pt-4">
                <div class="col-12">Selecteer status</div>
            </div>
            <div class="status-container py-2" *ngIf="currentUser['role'] == 'ADMIN' || currentUser['role'] == 'MANAGER'">
                <span (click)="setSelectedStatus(status['value'])" class="btn bg-permit-status-{{status['value'] | lowercase}}" [ngClass]="{
                    'active': selectedStatus == status['value']
                }" *ngFor="let status of statusOptions">
                    {{status['name']}}
                </span>
            </div>
            <div class="status-container py-2" *ngIf="currentUser['role'] != 'ADMIN' &&  currentUser['role'] != 'MANAGER'">
                <span class="btn bg-permit-status-{{status['value'] | lowercase}}" style="cursor:default;" [ngClass]="{
                    'active': selectedStatus == status['value']
                }" *ngFor="let status of statusOptions">
                    {{status['name']}}
                </span>
            </div>
        </div>
        <div class="px-5 pb-3" *ngIf="currentTab=='files'" style="max-height:600px;overflow-y:auto;">
            <app-project-files [project]="project" target="{{type}}"></app-project-files>
        </div>
    </div>
    <div class="modal-body p-1" *ngIf="mobile">
        <app-project-files [project]="project" [onlyFiles]="true" target="{{type}}"></app-project-files>
    </div>
</div>