import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from 'src/app/services/core/config.service';

@Component({
	selector: 'app-calendar-pdf-old',
	templateUrl: './calendar-pdf-old.component.html',
	styleUrls: ['./calendar-pdf-old.component.scss']
})
export class CalendarPdfOldComponent implements OnInit {
	@Input() public title;
	@Input() public button;
	@Input() public message;
	@Input() public url;
	@Input() public startWeek;
	@Input() public endWeek;
	@Output() passEntry: EventEmitter<any> = new EventEmitter();

	constructor(
		public activeModal: NgbActiveModal,
		public config: ConfigService
	) { }

	ngOnInit(): void {

	}

	confirm() {
		let url = this.url;
		url = this.config.get('api_url') + 'projects/' + url.replace('projects', 'calendarPdf') + '&startWeek=' + this.startWeek + '&' + 'endWeek=' + this.endWeek + '&';
		location.href = url;

		this.activeModal.close({startWeek: this.startWeek, endWeek: this.endWeek});
		this.activeModal.close(true);
	}

	cancel() {
		this.activeModal.close(false);
	}

	close() {
		this.activeModal.close(false);
	}
}