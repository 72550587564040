import { Component, OnInit, Input } from "@angular/core";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalConfirmDeleteComponent } from "src/app/modals/modal-confirm-delete/modal-confirm-delete.component";
import { AppService } from "src/app/services/app.service";
import { ApiConnectionService } from "src/app/services/core/api-connection.service";
import { ConfigService } from "src/app/services/core/config.service";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { CalculcationTemplateListComponent } from "./calculcation-template-list/calculcation-template-list.component";
import { AuthenticationService } from "src/app/services/core/authentication.service";

@Component({
  selector: "app-calculation-items",
  templateUrl: "./calculation-items.component.html",
  styleUrls: ["./calculation-items.component.scss"],
})
export class CalculationItemsComponent implements OnInit {
  constructor(
    public api: ApiConnectionService,
    public authenticationService: AuthenticationService,
    public app: AppService,
    public modalService: NgbModal,
    public config: ConfigService
  ) {
    let self = this;
		self.authenticationService.getCurrentUser();
		self.authenticationService.data.subscribe(data => {
		  if (Object.keys(data).length) {
			self.currentUser = data;
		  } else {
			self.currentUser = false;
		  }
		});
  }

  public projectCalculationItems;
  @Input() public project;
  @Input() public template;
  public edit = false;
  public itemTypes;
  public itemUnits;
  public itemCategories;
  public showDropDown = false;
  public currentTab = "calculation";
  public activeRowNameId = '';
  public activeRowTypeId = '';
  public activeRowTypeTimeout;
  public activeRowNameTimeout;
  public mode = 'project';

  public suggestions;

	public currentUser;

  ngOnInit(): void {
    let self = this;

    this.itemTypes = this.config.get("project_calculation_item_type_options");
    this.itemUnits = this.config.get("project_calculation_item_unit_options");
    this.itemCategories = this.config.get(
      "project_calculation_item_category_options"
    );

    try {
      let key = sessionStorage.getItem("project-calculation-tab");
      if (key) {
        this.setTab(key);
      } else {
        if (this.project["calculationFileCount"] > 0) {
          this.setTab("files");
        } else {
          this.setTab("calculation");
        }
      }
    } catch (e) {
      if (this.project && this.project["calculationFileCount"] > 0) {
        this.setTab("files");
      } else {
        this.setTab("calculation");
      }
    }
  
    let projectId = !self.project ? null : self.project["id"];
    let templateId = !self.template ? null : self.template["id"];

    if (projectId) {
      self.mode = 'PROJECT';
      self.getSuggestions();
    } else {
      self.mode = 'TEMPLATE';
      self.setTab("calculation");
    }

  }

  getSuggestions () {
    let self = this;
    this.api
      .read("projectCalculationItems/suggestions")
      .then(function (response) {
        self.suggestions = response["items"];
      });
  }

  hideNameList (event, row) {
    let self = this;
    self.activeRowNameTimeout = setTimeout(function () {
      self.activeRowNameId = '';
    }, 500);
  }

  showNameList (event, row) {
    clearTimeout(this.activeRowNameTimeout);
    this.activeRowNameId = row['id'];
    this.selectAll(event);
  }

  selectName (row, searchName) 
  {
    row['title'] = searchName['title'];
    row['price'] = searchName['price'];
    row['unit'] = searchName['unit'];
		setTimeout(function () {
			document.getElementById('calculation-amount-'+row['id']+'').focus();
		},100);	
    this.activeRowNameId = '';
    this.update(row);
  }

  searchNames (row)
  {
    let list = [];
    for (let i=0;i<this.suggestions.length;i++)
    {
      let item = this.suggestions[i];
      let string = "" + this.suggestions[i]['title'].toLowerCase() + "";
      let result = string.includes(row['title'].toLowerCase());
      if (row['title'] == "" || row['title'].length < 3) { 
        // list.push(item);
      } else if (result) {
        list.push(item);
      }
    }
    return list;
  }

  hideUnitList (event, row) {
    let self = this;
    self.activeRowTypeTimeout = setTimeout(function () {
      self.activeRowTypeId = '';
    }, 500);
  }

  showUnitList (event, row) {
    this.activeRowTypeId = row['id'];
    this.selectAll(event);
    clearTimeout(this.activeRowTypeTimeout);
  }

  selectUnit (row, searchUnit) 
  {
    row['unit'] = searchUnit;
    this.activeRowTypeId = '';
		setTimeout(function () {
			document.getElementById('calculation-price-'+row['id']+'').focus();
		},100);	
    this.update(row);
  }

  searchUnits (row)
  {
    let list = [];
    for (let i=0;i<this.itemUnits.length;i++)
    {
      let item = this.itemUnits[i];
      let string = "" + this.itemUnits[i].toLowerCase() + "";
      let result = string.includes(row['unit'].toLowerCase());

      if (row['unit'] == "" || result) {
        list.push(item);
      }
    }
    return list;
  }

  ngOnChanges(changes: any) {
    this.getCalculationItems();
  }

  getCalculationItems() {
    let self = this;
    let projectId = !self.project ? null : self.project["id"];
    let templateId = !self.template ? null : self.template["id"];

    if (projectId) {
      this.api
        .read("projects/details/" + projectId + "/calculationItems")
        .then((response) => {
          self.projectCalculationItems = response["items"];
        });
    }

    if (templateId) {
      this.api
        .read(
          "calculationTemplates/details/" + templateId + "/calculationItems"
        )
        .then((response) => {
          self.projectCalculationItems = response["items"];
        });
    }
  }

  confirmDelete(item) {
    let self = this;

    const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
    deleteModal.componentInstance.message =
      "Weet je zeker dat je dit element wilt verwijderen?";
    deleteModal.result.then((result) => {
      if (result) {
        this.delete(item);
      }
    });
  }

  delete(item) {
    let self = this;
    self.app.setLoaderStatus(true);
    this.api
      .delete("projectCalculationItems/delete/" + item.id + "")
      .then((response) => {
        self.app.setLoaderStatus(false);
        self.app.setSystemMessageLoading(true);
        self.app.setSystemMessageText("Regel verwijderd");
        self.app.setSystemMessageType("DANGER");
        self.getCalculationItems();
      })
      .catch(function (error) {
        self.app.setLoaderStatus(false);
      });
  }

  drop(event: CdkDragDrop<string[]>) {
    let positions = [];
    moveItemInArray(
      this.projectCalculationItems,
      event.previousIndex,
      event.currentIndex
    );
    this.projectCalculationItems.forEach(function (value, index) {
      positions.push(value["id"]);
    });

    let self = this;

    self.app.setLoaderStatus(true);

    this.api
      .save("projectCalculationItems/positions", {
        items: positions,
      })
      .then(function (response) {
        self.app.setLoaderStatus(false);
        self.app.setSystemMessageLoading(true);
        self.app.setSystemMessageText("Opgeslagen");
        self.app.setSystemMessageType("SUCCESS");
        self.getCalculationItems();
      })
      .catch(function (response) {
        self.app.setLoaderStatus(false);
        self.app.setSystemMessageLoading(true);
        self.app.setSystemMessageText(response["error"]["message"]);
        self.app.setSystemMessageType("DANGER");
      });
  }

  addRow(position, category, type) {
    let self = this;

    let projectId = !self.project ? null : self.project["id"];
    let templateId = !self.template ? null : self.template["id"];
    let target = projectId ? "PROJECT" : "TEMPLATE";
    let targetId = projectId ? projectId : templateId;

    self.app.setLoaderStatus(true);
    position = position + 1;
    this.api
      .save("projectCalculationItems/add/" + targetId + "", {
        type: type,
        category: category,
        target: target,
        position: position,
      })
      .then(function (response) {
        self.app.setLoaderStatus(false);
        self.app.setSystemMessageLoading(true);
        self.app.setSystemMessageText("Rij toegevoegd");
        self.app.setSystemMessageType("SUCCESS");
        self.getCalculationItems();
      })
      .catch(function (response) {
        self.app.setLoaderStatus(false);
        self.app.setSystemMessageLoading(true);
        self.app.setSystemMessageText(response["error"]["message"]);
        self.app.setSystemMessageType("DANGER");
      });
  }

  setTab(tab) {
    this.currentTab = tab;
    sessionStorage.setItem("project-calculation-tab", tab);
  }

  update(item) {
    let self = this;

    let projectId = !self.project ? null : self.project["id"];
    let templateId = !self.template ? null : self.template["id"];
    let target = projectId ? "PROJECT" : "TEMPLATE";
    let targetId = projectId ? projectId : templateId;

    self.app.setLoaderStatus(true);

    item["target"] = target;

    this.api
      .save(
        "projectCalculationItems/update/" + targetId + "/" + item["id"] + "",
        item
      )
      .then(function (response) {
        self.app.setLoaderStatus(false);
        self.app.setSystemMessageLoading(true);
        self.app.setSystemMessageText("Rij gewijzigd");
        self.app.setSystemMessageType("SUCCESS");

        item["amount"] = response["item"]["amount"];
        item["price"] = response["item"]["price"];
        item["total"] = response["item"]["total"];
        item["isCalculation"] = response["isCalculation"];
        item["category"] = response["item"]["category"];

        for (let i=0;i<response['calculations'].length;i++)
        {
          let calculation = response['calculations'][i];
          for (let x=0;x<self.projectCalculationItems.length;x++)
          {
            let item = self.projectCalculationItems[x];
            if (item['id'] === calculation['id'])
            {
              self.projectCalculationItems[x]['total'] = calculation['total'];
            }
          }
        }
      })
      .catch(function (response) {
        self.app.setLoaderStatus(false);
        self.app.setSystemMessageLoading(true);
        self.app.setSystemMessageText(response["error"]["message"]);
        self.app.setSystemMessageType("DANGER");
      });
  }

  selectTemplate() {
    let self = this;

    this.showDropDown = false;
    const modal = this.modalService.open(CalculcationTemplateListComponent);
    modal.componentInstance.project = self.project;
    modal.result.then((result) => {
      if (result) {
        self.getCalculationItems();
      }
    });
  }

  toggleDropdown() {
    if (this.showDropDown === false) {
      this.showDropDown = true;
    } else {
      this.showDropDown = false;
    }
  }

  updateTotal() {
    if (this.mode === 'TEMPLATE')
    {
      return;
    }

    let self = this;
    self.app.setLoaderStatus(true);

    this.api
      .save("projects/saveCalculationTotal/" + self.project.id, {
        calculationTotal: self["project"]["calculationTotal"],
      })
      .then(function (response) {
        self.app.setLoaderStatus(false);
        self.app.setSystemMessageLoading(true);
        self.app.setSystemMessageText("Gewijzigd");
        self.app.setSystemMessageType("SUCCESS");
      })
      .catch(function (response) {
        self.app.setLoaderStatus(false);
        self.app.setSystemMessageLoading(true);
        self.app.setSystemMessageText(response["error"]["message"]);
        self.app.setSystemMessageType("DANGER");
      });
  }

  reload(event) {
    let self = this;
    this.api
      .read("projects/details/" + self.project["id"] + "")
      .then(function (response) {
        self["project"]["calculationFileCount"] =
          response["calculationFileCount"];
      });
  }

  onKeyDownNumeric(event: KeyboardEvent) {
    // Allow backspace, delete, tab, escape, enter, decimal point and numbers only
    if (
      event.keyCode === 46 ||
      event.keyCode === 8 ||
      event.keyCode === 9 ||
      event.keyCode === 27 ||
      event.keyCode === 13 ||
      event.keyCode === 188 ||
      event.keyCode === 190 ||
      event.keyCode === 110 || // Decimal point
      event.keyCode === 173 || // minus
      (event.keyCode >= 48 && event.keyCode <= 57) || // Numbers
      (event.keyCode >= 96 && event.keyCode <= 105) // Numpad numbers
    ) {
      // Allow the key press
      return;
    } else {
      // Block the key press
      event.preventDefault();
    }
  }

  selectAll(event) {
    if (event.target.value === "0.00") {
      event.target.select();
    }
  }
}
