<div [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="modal-header">
        <h4 class="modal-title" style="font-size:16px;">Genereer offerte P{{project['number']}}</h4>
        <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
    </div>
    <div class="modal-body p-1">
        <div class="py-5 text-center" *ngIf="showSuccess == true">
            <p>Offerte is succesvol opgeslagen.</p>
            <button (click)="showFiles()" class="btn btn-success with-icon">Bekijk offerte bestanden <i class="bi bi-arrow-right"></i></button> 
            <!-- <span class="d-inline-block px-3">of</span> 
            <span class="d-inline-block mt-3" *ngIf="project">
                <button (click)="showQuoteEmail()" class="btn btn-dark with-icon">Verstuur e-mail <i class="bi bi-arrow-right"></i></button>
            </span> -->
        </div>
        <div class="row" *ngIf="showSuccess == false">
            <div class="col-4">
                <div cdkDropList class="mt-2" (cdkDropListDropped)="dropPending($event)">
                    <div cdkDrag *ngFor="let file of files">
                        <div class="px-3 pt-1 pb-2 mb-1 d-flex" style="border-bottom:1px solid #EEEEEE;">
                            <span class="file-mover"><i class="bi bi-arrows-move p-1"></i></span>
                            <span class="file-name">
                                <span class="d-inline-block pe-4 truncate">{{file['name']}}</span>
                                <span *ngIf="file['id'] == 'photos'" class="">
                                    <span class="">({{selectedPhotoCount()}}/{{photos.length}})</span>
                                </span>
                            </span>
                            <span class="file-checkbox text-end">
                                <span *ngIf="file['id'] == 'photos'" class="me-2">
                                    <i class="bi bi-chevron-up check-icon" (click)="togglePhotoTab()" *ngIf="photoTabExtended != true"></i>
                                    <i class="bi bi-chevron-down check-icon" (click)="togglePhotoTab()" *ngIf="photoTabExtended == true"></i>
                                </span>
                                <i class="bi bi-square-fill check-inactive check-icon" (click)="setActive(file)" *ngIf="file['selected'] != true"></i>
                                <i class="bi bi-check-square-fill check-icon" (click)="setInActive(file)" *ngIf="file['selected'] == true"></i>
                            </span>
                        </div>
                        <div *ngIf="file['id'] == 'photos'" [ngClass]="{
                            'd-none': photoTabExtended == false
                        }">
                            <div class="">
                                <span *ngFor="let photo of photos">
                                    <span class="quote-photo" [ngClass]="{
                                        selected: photo['selected'] == true
                                    }">
                                        <span class="quote-photo-selection">
                                            <i class="bi bi-check check-inactive check-icon" (click)="setActive(photo)" *ngIf="photo['selected'] != true"></i>
                                            <i class="bi bi-check check-icon" (click)="setInActive(photo)" *ngIf="photo['selected'] == true"></i>
                                        </span>
                                        <img src="{{photo['thumbUrl']}}&hash={{this.currentUser['publicHash']}}" (click)="setActive(photo)" *ngIf="photo['selected'] != true">
                                        <img src="{{photo['thumbUrl']}}&hash={{this.currentUser['publicHash']}}" (click)="setInActive(photo)" *ngIf="photo['selected'] == true">
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-8">
                <div [innerHTML]="content"></div>
            </div>
        </div>
    </div>
    <div class="modal-footer d-block" *ngIf="showSuccess == false">
        <a class="btn btn-dark with-icon" (click)="generatePreview()">Genereer preview <i class="bi bi-arrow-right"></i></a>
        <a class="btn btn-success with-icon" (click)="saveQuote()">Offerte opslaan <i class="bi bi-cloud-arrow-down"></i></a>
    </div>
</div>