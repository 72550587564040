<section *ngIf="item" [ngClass]="{'loader-overlay': loaderOverlay}">
	<div class="modal-header">
		<h5 class="modal-title">
			<span *ngIf="!item['id']">Categorie aanmaken</span>
			<span *ngIf="item['id']">Categorie wijzigen</span>
		</h5>
		<button type="button" class="btn-close" (click)="close()" tabindex="-1" aria-label="Close"></button>
	</div>
	<div class="modal-body">
		<div class="alert alert-success" *ngIf="successmessage">Wijzigingen succesvol opgeslagen.</div>
		<div class="alert alert-danger" *ngIf="errormessage">Er zijn enkel fouten gevonden.</div>
		<section class="user-form" *ngIf="item">
			<input type="hidden" *ngIf="item" class="form-control" id="id" [(ngModel)]="item['id']" name="id">

			<div class="form-group mb-3">
				<label for="name" class="pb-1">Naam</label>
				<input type="text" class="form-control" id="name" required [(ngModel)]="item['name']" name="name">
				<div class="error" *ngIf="errors['name']">{{errors['name']}}</div>
			</div>

			<div class="form-group mb-3">
				<label for="key" class="pb-1">Korte naam</label>
				<input type="text" class="form-control" id="key" required [(ngModel)]="item['key']" name="key">
				<div class="error" *ngIf="errors['key']">{{errors['key']}}</div>
			</div>

			<div class="form-group row mb-3">
				<div class="col-12">
					<label class="col-form-label pb-1">Actief:</label>
				</div>
				<div class="col-12">
					<div class="form-check form-switch">
						<input type="checkbox" class="form-check-input" id="active" role="switch" name="active"
							[ngModelOptions]="{standalone: true}" [(ngModel)]="item.active">
						<label class="form-check-label" for="active"></label>
					</div>
				</div>
			</div>

			<div class="form-group row mb-3">
				<div class="col-12">
					<label class="col-form-label pb-1">Vanaf offerte verplichte documenten weergeven:</label>
				</div>
				<div class="col-12">
					<div class="form-check form-switch">
						<input type="checkbox" class="form-check-input" id="quoteDocumentsRequired" role="switch"
							name="quoteDocumentsRequired" [ngModelOptions]="{standalone: true}"
							[(ngModel)]="item.quoteDocumentsRequired">
						<label class="form-check-label" for="quoteDocumentsRequired"></label>
					</div>
				</div>
			</div>

			<div class="form-group row mb-3">
				<div class="col-12">
					<label class="col-form-label pb-1">Gebruiker mag project statussen wijzigen:</label>
				</div>
				<div class="col-12">
					<div class="form-check form-switch">
						<input type="checkbox" class="form-check-input" id="canEditProjectStatus" role="switch"
							name="canEditProjectStatus" [ngModelOptions]="{standalone: true}"
							[(ngModel)]="item.canEditProjectStatus">
						<label class="form-check-label" for="canEditProjectStatus"></label>
					</div>
				</div>
			</div>

			<div class="form-group row mb-3">
				<div class="col-12">
					<label class="col-form-label pb-1">Gebruiker mag dagboek inzien:</label>
				</div>
				<div class="col-12">
					<div class="form-check form-switch">
						<input type="checkbox" class="form-check-input" id="canManageJournals" role="switch"
							name="canManageJournals" [ngModelOptions]="{standalone: true}"
							[(ngModel)]="item.canManageJournals">
						<label class="form-check-label" for="canManageJournals"></label>
					</div>
				</div>
			</div>

			<div class="form-group row mb-3">
				<div class="col-12">
					<label class="col-form-label pb-1">Gebruiker moet verplicht uren registeren:</label>
				</div>
				<div class="col-12">
					<div class="form-check form-switch">
						<input type="checkbox" class="form-check-input" id="hourRegistrationRequired" role="switch"
							name="hourRegistrationRequired" [ngModelOptions]="{standalone: true}"
							[(ngModel)]="item.hourRegistrationRequired">
						<label class="form-check-label" for="hourRegistrationRequired"></label>
					</div>
				</div>
			</div>

			<div class="form-group row mb-3">
				<div class="col-12">
					<label class="col-form-label pb-1">Gebruiker mag meetgegevens invullen:</label>
				</div>
				<div class="col-12">
					<div class="form-check form-switch">
						<input type="checkbox" class="form-check-input" id="canManageMeasurements" role="switch"
							name="canManageMeasurements" [ngModelOptions]="{standalone: true}"
							[(ngModel)]="item.canManageMeasurements">
						<label class="form-check-label" for="canManageMeasurements"></label>
					</div>
				</div>
			</div>
		</section>
	</div>
	<div class="modal-footer" *ngIf="item">
		<button (click)="save()" class="btn btn-success">
			<span *ngIf="!item.id"><i class="bi bi-sd-card pe-2"></i> Toevoegen</span>
			<span *ngIf="item.id"><i class="bi bi-sd-card pe-2"></i> Opslaan</span>
		</button>
	</div>
</section>