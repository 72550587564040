import { Component, OnInit } from '@angular/core';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfigService } from 'src/app/services/core/config.service';
import { UsersService } from 'src/app/services/users.service';
import { ModalConfirmDeleteComponent } from 'src/app/modals/modal-confirm-delete/modal-confirm-delete.component';

@Component({
	selector: 'app-user-groups-form',
	templateUrl: './user-groups-form.component.html',
	styleUrls: ['./user-groups-form.component.scss']
})
export class UserGroupsFormComponent implements OnInit {

	item;
	id = '';
	users = [];
	errors = [];
	colors = [];
	successmessage = false;
	errormessage = false;
	selectedItems = {};
	loaderOverlay = false;
	autographImage;

	filterName: any = '';

	constructor(
		public api: ApiConnectionService,
		public config: ConfigService,
		public usersService: UsersService,
		public modalService: NgbModal,
		public activeModal: NgbActiveModal
	) { }

	ngOnInit(): void {
		this.colors = this.config.get('user_group_colors');
		this.fetchItem();
		this.fetchUsers();
	}

	ngOnChanges(changes: any) {
		this.fetchItem();
	}

	canShow(username) {
		if (this.filterName != '') {
			if ((username).toLowerCase().includes(this.filterName.toLowerCase())) {
				return true;
			}
			return false;
		} else {
			return true;
		}
	}

	fetchUsers() {
		let self = this;
		this.api.read('users').then((response) => {
			self.users = response['items'];
		});
	}

	fetchItem() {
		let self = this;
		self.item = {};
		this.api.read('userGroups/details/' + self.id + '').then((response) => {
			self.item = response;
			self.selectedItems = [];
			response['userIds'].forEach(element => {
				self.selectedItems[element] = true;
			});
		});
	}

	save() {
		let self = this;
		self.errors = [];
		self.successmessage = false;
		self.errormessage = false;

		self.item['userIds'] = Object.keys(this.selectedItems);

		let type = 'added';
		if (!self.item['id'] || self.item['id'].length < 2) {
			type = 'edited';
		}

		this.api.save('userGroups/save', self.item).then((response) => {
			self.item = response;
			self.successmessage = true;
			self.activeModal.close({
				id: self.item['id'],
				type: 'edited',
			});
		}).catch(function (response) {
			self.errormessage = true;
			for (let error in response['error']['errors']) {
				self.errors[error] = response['error']['errors'][error];
			}
		});
	}

	toggleList(key) {
		if (this.selectedItems[key] == true) {
			delete this.selectedItems[key];
		} else {
			this.selectedItems[key] = true;
		}
	}

	dismiss() {
		this.activeModal.close(false);
	}

	close() {
		this.activeModal.close(false);
	}

	cancel() {
		this.activeModal.close(false);
	}
}
