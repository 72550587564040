import { Component, OnInit, Input, Output, Renderer2 } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Select2Data } from 'ng-select2-component';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { ConfigService } from 'src/app/services/core/config.service';

import { AuthenticationService } from 'src/app/services/core/authentication.service';

@Component({
  selector: 'app-send-termstate',
  templateUrl: './send-termstate.component.html',
  styleUrls: ['./send-termstate.component.scss']
})
export class SendTermstateComponent implements OnInit {
  @Input() public title;
  @Input() public button;
  @Input() public message;
  @Input() public project;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  showSuccess = false;
  content;
  loaderOverlay = false;
  files = [];
  photos = [];
  previewUrl = '';
  photoTabExtended = false;
  data;
  value;
  selContact = "";
  from;
  fromName;

  currentUser;

  projectFiles = [];
  filteredProjectFiles = {};
  showCategories = {};
  categoryPositions = {};

  categories;

  to: Select2Data = [];

  receivers = [];
  cc = [];
  bcc = [];
  selectedFileIds: string[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    public appService: AppService,
    public config: ConfigService,
    public api: ApiConnectionService,
    public sanitizer: DomSanitizer,
    private renderer: Renderer2,
    private authenticationService: AuthenticationService
  ) {
    let self = this;

    const select2 = document.getElementsByClassName("select2-search__field");

    this.renderer.listen('window', 'keydown', (evt) => {
      if (evt.target.id.includes(select2[0].id) && evt.keyCode === 13) {
        let value = evt.target.value;
        // evt.target.value = '';
        setTimeout(function () {
          var elementsWithClass = document.querySelectorAll('.select2-container--open');

          for (var i = 0; i < elementsWithClass.length; i++) {
            let element = elementsWithClass[i];
            (elementsWithClass[i] as HTMLElement).click();

          }
        }, 100);

        if (value != '' && value.includes('.') && value.includes('@')) {
          self.receivers.push(value);

        }
      }
      if (evt.target.id.includes(select2[1].id) && evt.keyCode === 13) {
        let value = evt.target.value;
        // evt.target.value = '';
        setTimeout(function () {
          var elementsWithClass = document.querySelectorAll('.select2-container--open');

          for (var i = 0; i < elementsWithClass.length; i++) {
            let element = elementsWithClass[i];
            (elementsWithClass[i] as HTMLElement).click();

          }
        }, 100);

        if (value != '' && value.includes('.') && value.includes('@')) {
          self.cc.push(value);

        }
      }
      if (evt.target.id.includes(select2[2].id) && evt.keyCode === 13) {
        let value = evt.target.value;
        // evt.target.value = '';
        setTimeout(function () {
          var elementsWithClass = document.querySelectorAll('.select2-container--open');

          for (var i = 0; i < elementsWithClass.length; i++) {
            let element = elementsWithClass[i];
            (elementsWithClass[i] as HTMLElement).click();

          }
        }, 100);

        if (value != '' && value.includes('.') && value.includes('@')) {
          self.bcc.push(value);

        }
      }
    });
  }


  ngOnInit(): void {
    this.fetchData();
    this.getCategories();

    const self = this;
    this.authenticationService.getCurrentUser().then(function (data) {
      self.authenticationService.data.subscribe(data => {
        if (Object.keys(data).length) {
          self.currentUser = data;
          console.log(self.currentUser)
          setTimeout(() => {
            self.from = "werkvoorbereiding@kloens.nl";
            self.data.fromName = self.currentUser.display;
          }, 100);
        } else {
          self.currentUser = false;
        }
      });
    });
  }

  checkSearch(event) {
    console.log('x');
    console.dir(event);
  }

  truncateString(str, num) {
    if (str.length > num) {
      return str.slice(0, num) + "...";
    } else {
      return str;
    }
  }

  setAuthorName() {
    if (this.from === 'werkvoorbereiding@kloens.nl') {
      this.data.fromName = 'Werkvoorbereiding Gebr. Kloens';
    } else if (this.from === "miranda@kloens.nl") {
      this.data.fromName = "Miranda van Dalen";
    } else {
      this.data.fromName = this.currentUser['display'];
    }
  }

  getCategories() {
    let self = this;
    if (!self.project) {
      return;
    }

    this.api
      .read("projectFiles/categories/" + this.project['id'] + "")
      .then((response: any) => {
        self.categories = response;
        for (let x = 0; x < self.categories.length; x++) {
          let currentCategory = self.categories[x];
          self.categoryPositions[currentCategory['name']] = currentCategory['position'];
        }
      });
  }

  addEmail() {
    const selId = this.selContact;
    const contacts = this.data['contacts'];

    for (let i = 0; i < contacts.length; i++) {
      if (contacts[i].id == selId) {
        if (!Array.isArray(this.data['contactId'])) {
          this.data['contactId'] = [];
        }

        this.data['contactId'].push(selId)
        this.receivers.push(contacts[i]['email']);

        this.selContact = null;
        break;
      }
    }
  }

  updateReceiver(event) {
    this.receivers.push(event.value);
  }

  updateCC(event) {
    this.cc.push(event.value);
  }

  updateBCC(event) {
    this.bcc.push(event.value);
  }

  addCC() {
    const selId = this.selContact;
    const contacts = this.data['contacts'];

    for (let i = 0; i < contacts.length; i++) {
      if (contacts[i].id == selId) {
        if (!Array.isArray(this.data['contactId'])) {
          this.data['contactId'] = [];
        }


        this.data['contactId'].push(selId)
        this.cc.push(contacts[i]['email']);

        this.selContact = null;
        break;
      }
    }
  }

  addBCC() {
    const selId = this.selContact;
    const contacts = this.data['contacts'];

    for (let i = 0; i < contacts.length; i++) {
      if (contacts[i].id == selId) {
        if (!Array.isArray(this.data['contactId'])) {
          this.data['contactId'] = [];
        }


        this.data['contactId'].push(selId)
        this.bcc.push(contacts[i]['email']);

        this.selContact = null;
        break;
      }
    }
  }

  removeEmail(email) {
    var index = this.receivers.indexOf(email);
    if (index !== -1) {
      this.receivers.splice(index, 1);
    }
  }

  removeCC(email) {
    var index = this.cc.indexOf(email);
    if (index !== -1) {
      this.cc.splice(index, 1);
    }
  }

  removeBCC(email) {
    var index = this.bcc.indexOf(email);
    if (index !== -1) {
      this.bcc.splice(index, 1);
    }
  }

  toggle(cat) {
    this.showCategories[cat] = !this.showCategories[cat]
  }

  send() {
    let self = this;
    self.appService.setLoaderStatus(true);
    self.loaderOverlay = true;

    self.data.to = this.receivers;
    self.data.bcc = this.bcc;
    self.data.cc = this.cc;
    self.data.files = this.selectedFileIds;
    self.data.from = this.from;
    self.data.notificationType = "PERIOD";

    delete self.data.fileId;
    delete self.data.contacts;

    self.api.save('projects/sendMail/' + self.project['id'] + '', this.data).then(function (response) {
      self.data = response;
      self.appService.setLoaderStatus(false);
      self.showSuccess = true;
      self.loaderOverlay = false;
    });
  }

  changeFiles(id) {
    if (this.selectedFileIds.includes(id)) {
      this.selectedFileIds.slice(this.selectedFileIds.indexOf(id), 1);
    } else {
      this.selectedFileIds.push(id);
    }
  }

  item;

  fetchData() {
    let self = this;
    self.api.read('quotes/termStateMailData/' + self.project['id'] + '').then(function (response) {
      self.data = response;

      let to = [];
      for (let x = 0; x < self.data['contacts'].length; x++) {
        to.push({
          value: self.data['contacts'][x]['email'],
          label: self.data['contacts'][x]['name'] + ' ' + self.data['contacts'][x]['email'],
        });
      }
      self.to = to;

      self.data['contactId'] = [];
    });

    this.api
    .read("projects/details/" + self.project['id'] + "/files")
    .then((response) => {
      self.projectFiles = response["items"];

      console.log(self.projectFiles)
      for (let i = 0; i < self.projectFiles.length; i++) {
        const sel = self.projectFiles[i];

        //create object keys, either categorytext or category for name
        if (self.filteredProjectFiles[sel.categoryText] == undefined || sel.categoryText == "undefined") {
          if (sel.categoryText == undefined) {
            if(self.filteredProjectFiles[sel.category] == undefined) {
              self.filteredProjectFiles[sel.category] = [];
            }
          } else {
            self.filteredProjectFiles[sel.categoryText] = [];
          }
        }

        if (sel.categoryText == undefined || sel.categoryText == "undefined") {
          self.filteredProjectFiles[sel.category].push(sel);
          // self.showCategories[sel] = false;
        } else {
          self.filteredProjectFiles[sel.categoryText].push(sel);
        }

        if (self.filteredProjectFiles[sel.categoryText] == undefined || sel.categoryText == "undefined") {
          self.showCategories[sel.category] = true;
        } else {
          self.showCategories[sel.categoryText] = true;
        }
      }
    });
  }

  confirm() {
    this.activeModal.close(false);
  }

  cancel() {
    this.activeModal.close(false);
  }

  close() {
    this.activeModal.close(false);
  }

}
