<header class="page-header">
	<div class="row">
		<div class="col-8">
			<h1>Speciale dagen</h1>
			<h6>Instellingen / overzicht</h6>
		</div>
		<div class="col-4 text-end pt-3">
			<button (click)="form({})" class="btn btn-success with-icon">Toevoegen <i class="bi bi-plus-circle-fill"></i></button>
		</div>
	</div>
</header>
<section class="table-grid">
    <section class="table-content">
        <div class="text-center py-5" *ngIf="items && items.length < 1">
            <small>Geen resultaten</small>
        </div>
        <table class="table table-vertical-align mb-0" *ngIf="items && items.length > 0">
            <thead class="thead-dark">
                <tr>
                    <th>Startdatum</th>
                    <th>Einddatum</th>
                    <th>Omschrijving</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of items; let i = index">
                    <td>{{item['startAtText']}}</td>
                    <td>{{item['endAtText']}}</td>
                    <td>{{item['description']}}</td>
					<td class="align-middle text-end" >  
                        <span (click)="form(item)" class="table-guru-edit-link d-inline-block me-3 p-1" style="cursor:pointer;">
                            <i class="bi bi-pencil"></i>
                        </span>    
                        <span (click)="confirmDelete(item)" class="table-guru-delete-link delete-link p-1" style="cursor:pointer;">
                            <i class="bi bi-trash-fill"></i>
                        </span>    
				   </td>
                </tr>
            </tbody>
        </table>
    </section>
</section>