<div [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="modal-header">
        <h4 class="modal-title" style="font-size:16px;">{{file['name']}}</h4>
        <button (click)="toggleFullscreen()" tabindex="-1" class="fullscreen"><i
                class="bi bi-arrows-angle-expand"></i></button>
        <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
    </div>
    <div class="modal-body p-1">
        <div style="height:0;opacity:0;"><input type="text" value=""></div>
        <div class="text-center" *ngIf="!file['hasPreview']">
            <div class="text-muted" style="padding:60px 0px;">Geen preview mogelijk. Download of open het bestand.</div>
        </div>
        <div class="file-preview-container" *ngIf="file['hasPreview']" style="position:relative;">
            <div *ngIf="file['isImage'] == true" class="image-container">
                <div class="zoom-container" style="position:absolute;top:5px;left:5px;z-index:1000;">
                    <button class="zoom-in" onclick="zoomin()" style="width: 40px;
                    height: 40px;
                    line-height: 36px;
                    font-size: 24px;
                    cursor: pointer;
                    margin: 5px;
                    border: 1px solid #DDDDDD;">+</button>
                    <button class="zoom-out" onclick="zoomout()" style="width: 40px;
                    height: 40px;
                    line-height: 36px;
                    font-size: 24px;
                    cursor: pointer;
                    margin: 5px;
                    border: 1px solid #DDDDDD;">-</button>
                </div>
                <div [innerHTML]="content" id="preview-content"></div>
            </div>
            <div [innerHTML]="content" *ngIf="file['isImage'] == false"></div>
        </div>
    </div>
    <div class="modal-footer d-block">
        <span class="btn btn-light float-end" *ngIf="fileList && fileList.length > 0"
            [ngClass]="{ disabled: !nextFile }" (click)="setCurrentFile(nextFile)"><i
                class="bi bi-arrow-right"></i></span>
        <span class="btn btn-light float-end" *ngIf="fileList && fileList.length > 0"
            [ngClass]="{ disabled: !previousFile }" (click)="setCurrentFile(previousFile)"><i
                class="bi bi-arrow-left"></i></span>


        <span class="cache-status" *ngIf="!file['hasPreview']">{{ file['cacheStatus'] }}</span>
        <a (click)="openProtocol($event, file['openUrl'])" class="btn btn-dark with-icon d-none d-lg-inline-block"> Open bestand <i
                class="bi bi-arrow-right"></i></a>
        <a [href]="addHash(file['downloadUrl'])" class="btn btn-success with-icon">Download bestand <i
                class="bi bi-cloud-arrow-down"></i></a>
    </div>
</div>