import { Component, OnInit , Input , Output} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';

@Component({
  selector: 'app-quote-status-form',
  templateUrl: './quote-status-form.component.html',
  styleUrls: ['./quote-status-form.component.scss']
})
export class QuoteStatusFormComponent implements OnInit {

  constructor(
    public api: ApiConnectionService,
    public activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

	@Input() public item;

  save (value)
  {
    let self = this;
    this.api.read('projects/changeQuoteStatus/'+self.item['id']+'/'+value+'').then(function (response) {
      self.activeModal.close(true);
    });
  }

	close () {
		this.activeModal.close(false);
	}
  
	cancel () {
		this.activeModal.close(false);
	}
  
	dismiss () {
		this.activeModal.close(false);
	}
}
