import { Component, OnInit , Input , Output} from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserEditModalComponent } from 'src/app/components/users/user-edit-modal/user-edit-modal.component';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';

@Component({
  selector: 'app-project-hours-users',
  templateUrl: './project-hours-users.component.html',
  styleUrls: ['./project-hours-users.component.scss']
})
export class ProjectHoursUsersComponent implements OnInit {
	@Input() public project;
	@Input() public title;
	@Input() public button;
	@Input() public message;
	@Input() public week;
	@Input() public year;
	@Output() passEntry: EventEmitter<any> = new EventEmitter();

	loaderOverlay = false;

	users = [];
	selectedUsers = {};
	activeUserIds = {};
	keyword = '';
	selectAllSelected = false;

	constructor(
  		public activeModal: NgbActiveModal,
		public app: AppService,
		public modalService: NgbModal,
		public api: ApiConnectionService
  ) { 

	}

	ngOnInit(): void {
		this.all();
	}
  
	ngOnChanges(changes: any) {
	  this.all();
	}

	selectAll ()
	{
		let users = this.searchUsers();
		for (let index = 0; index < users.length; ++index) {
		  let contact = users[index];
		  this.selectedUsers[contact['id']] = contact['id'];
		}
		this.selectAllSelected = true;
	}

	deSelectAll ()
	{
		let users = this.searchUsers();
		for (let index = 0; index < users.length; ++index) {
		  	let contact = users[index];
		  	if (this.selectedUsers.hasOwnProperty(contact['id'])) {
				delete this.selectedUsers[contact['id']];
			}
		}
		this.selectAllSelected = false;
	}

	searchUsers ()
	{
		let list = [];
		for (let index = 0; index < this.users.length; ++index) {
		  let contact = this.users[index];
		  let string = ''+contact['display'].toLowerCase() + ' ' + contact['email'].toLowerCase()  + ' ' + contact['companyName'].toLowerCase();
		  let result = string.includes(this.keyword.toLowerCase()); 
		  if (this.keyword == '' || result)
		  {
			list.push(contact);
		  }
		}
		return list;
	}

	all ()
	{
	  let self = this;
	  let id = self.project['id'];
	  
	  if (!id)
	  {
		return;
	  }
  
	  this.api.read('users').then((response) => {
		  self.users = response['items'];
	  });
	}

	toggleSelectedUser (user)
	{
		if (this.selectedUsers.hasOwnProperty(user['id'])) {
			delete this.selectedUsers[user['id']];
		} else {
			this.selectedUsers[user['id']] = user['id'];
		}
	}
  
	confirm () {
		let self = this;
		self.loaderOverlay = true;
		self.app.setLoaderStatus(true);			
		this.api.save('projectHours/add/'+self.project.id+'/'+self.week+'/'+self.year+'', {users: this.selectedUsers}).then(function (response) {
			self.activeModal.close(response);
			self.app.setLoaderStatus(false);		
			self.app.setSystemMessageLoading(true);
			self.app.setSystemMessageType('SUCCESS');
			self.app.setSystemMessageText('Medewerker toegevoegd');
			self.loaderOverlay = false;
		}).catch(function (response) {
			self.activeModal.close(response);
			self.app.setLoaderStatus(false);		
			self.app.setSystemMessageLoading(true);
			self.app.setSystemMessageType('DANGER');
			self.app.setSystemMessageText('Er is een fout opgetreden');
			self.loaderOverlay = false;
		});
	}
  
	cancel () {
		this.activeModal.close(false);
	}
  
	close () {
		this.activeModal.close(false);
	}
  
	openUserForm (data)
	{
	  let self = this;
		const filterModal = this.modalService.open(UserEditModalComponent, {
			windowClass: 'second-modal modal-right',
			backdropClass: 'second-modal-backdrop',
			size: 'lg'
		});
		
		filterModal.componentInstance.id = '';
		filterModal.componentInstance.organisationId = 'acb1a425-8bdb-11ea-9b3c-002590dfff4c';
		filterModal.componentInstance.roleKey = 'USER';
		filterModal.componentInstance.closeAfterSave = true;
		filterModal.result.then((result) => {
			self.all();
		})
		.catch((error) => {
			self.all();
		});
	}
  
	openMaterialForm (data)
	{
	  let self = this;
		const filterModal = this.modalService.open(UserEditModalComponent, {
			windowClass: 'second-modal modal-right',
			backdropClass: 'second-modal-backdrop',
			size: 'lg'
		});
		
		filterModal.componentInstance.id = '';
		filterModal.componentInstance.organisationId = 'acb1a425-8bdb-11ea-9b3c-002590dfff4c';
		filterModal.componentInstance.roleKey = 'MATERIAL';
		filterModal.componentInstance.closeAfterSave = true;
		filterModal.result.then((result) => {
			self.all();
		})
		.catch((error) => {
			self.all();
		});
	}
}