import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { AddFilterComponent } from './modals/add-filter/add-filter.component';
import { EditFilterComponent } from './modals/edit-filter/edit-filter.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { filter } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { AppService } from 'src/app/services/app.service';

import { AuthenticationService } from 'src/app/services/core/authentication.service';
import { ConfigService } from 'src/app/services/core/config.service';
import { Config } from 'protractor';

@Component({
  selector: 'app-table-guru',
  templateUrl: './table-guru.component.html',
  styleUrls: ['./table-guru.component.scss']
})
export class TableGuruComponent implements OnInit {
  @Input() public link;
  @Input() public refresh;
  @Input() public mapping;
  @Input() public editPath;
  @Input() public editParam = 'id';
  @Input() public showEditButtons = false;
  @Output() editAction = new EventEmitter();
  @Output() deleteAction = new EventEmitter();
  @Output() sortAction = new EventEmitter();

  loading = false;
  items;
  total;
  pages;
  totals = null;
  page = 1;
  limit = this.table_limit;
  limitOptions = [25, 50, 100, 250];
  keyword = '';
  filters = [];
  filterOptions = [];
  sort = '';
  direction = 'ASC';
  sortType = "STRING";
  pagesCounter = [];
  globalTimeout = null;
  path = '';
  currentUser;

  statusOptions;

  showDropDown = false;

  constructor(
    private modalService: NgbModal,
    private sanitizer: DomSanitizer,
    private app: AppService,
    private apiService: ApiConnectionService,
    private authenticationService: AuthenticationService,
    private configService: ConfigService
  ) {
    const self = this;
    self.authenticationService.getCurrentUser();

    self.authenticationService.data.subscribe(data => {
      if (Object.keys(data).length) {
        self.currentUser = data;

        self.statusOptions = self.configService.get("project_status_options")
      } else {
        self.currentUser = false;
      }
    });
  }

  get firstIndex(): number {
    return (this.page - 1) * this.limit + 1;
  }

  get lastIndex(): number {
    return Math.min(this.page * this.limit, this.total);
  }

  get table_limit() {
    const savedLimit = localStorage.getItem('table_limit');
    if (savedLimit) {
      return parseInt(savedLimit, 10); // Set the limit from localStorage
    } else {
      return 25;
    }
  }

  ngOnInit(): void {
    this.path = this.link;

    this.mapping.forEach(map => {
      if (map.defaultSort != undefined) {
        this.sort = map.key;
        this.direction = map.defaultSort;
      }
    });

    this.all();
  }

  ngOnChanges(changes: any) {
    this.path = this.link;

    this.mapping.forEach(map => {
      if (map.defaultSort != undefined) {
        this.sort = map.key;
        this.direction = map.defaultSort;
      }
    });

    this.all();
  }

  changeLimit(event: Event): void {
    const selectedValue = (event.target as HTMLSelectElement).value;
    this.limit = parseInt(selectedValue, 10); // Update the limit
    localStorage.setItem('table_limit', selectedValue); // Save the limit to localStorage
    this.page = 1; // Reset to the first page
    this.all(); // Reload data with the new limit
  }

  toggleDropdown() {
    if (this.showDropDown === true) {
      this.showDropDown = false;
    } else {
      this.showDropDown = true;
    }
  }

  bulkSetStatus(value) {
    if (confirm("Weet je zeker dat je de bulkactie wilt uitvoeren?\nDit kan niet worden teruggedraaid.")) {
      //loop through items, check if quote not accepted and set to accepted
      const self = this;

      let total = 0;

      for (let i = 0; i < this.items.length; i++) {
        total++;
      }

      for (let i = 0; i < this.items.length; i++) {
        const sel = this.items[i];
        const id = sel.id;

        self.apiService.read('projects/changeQuoteStatus/' + id + '/' + value).then(function (response) {
          if (i == (total - 1)) {
            self.toggleDropdown();
            self.all();
          }
        });
      }
    }
  }

  addFilter() {
    let self = this;

    const filterModal = this.modalService.open(AddFilterComponent);
    filterModal.componentInstance.filterOptions = self.filterOptions;
    filterModal.result.then((result) => {
      if (result && result.length > 0) {
        self.editFilter(result);
      }
    })
      .catch((error) => {
      });
  }

  getWeekNumber(dateString) {
    // Parse the date string to a Date object
    const date = new Date(dateString);

    // Copy date so it's not modified
    const copiedDate: any = new Date(date.getTime());

    // Set the copied date to the nearest Thursday: current date + 4 - current day number (adjusted for Sunday)
    copiedDate.setDate(copiedDate.getDate() + 4 - (copiedDate.getDay() || 7));

    // Get the first day of the year
    const yearStart: any = new Date(copiedDate.getFullYear(), 0, 1);

    // Calculate the full weeks to the nearest Thursday
    const weekNumber = Math.ceil((((copiedDate - yearStart) / 86400000) + 1) / 7);

    return weekNumber;
  }

  getYear(dateString) {
    // Parse the date string to a Date object
    const date = new Date(dateString);

    // Get the year from the Date object
    const year = date.getFullYear();

    return year;
  }

  transform(value: string, toWeekNumber?: boolean): SafeHtml {
    // Example: Add your logic if needed before sanitizing
    if (toWeekNumber) {
      value = `Week ${value}`;
    }
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }

  editFilter(filterKey) {
    let self = this;
    const filterModal = this.modalService.open(EditFilterComponent);
    filterModal.componentInstance.filterOptions = self.filterOptions;
    filterModal.componentInstance.filters = self.filters;
    filterModal.componentInstance.activeFilterKey = filterKey;

    filterModal.result.then((result) => {

      this.page = 1;

      if (result && result['action'] === "remove") {
        this.removeFilter(result['key']);
      }
      else if (result) {
        self.pushFilter(result);
        self.all();
      }
    })
      .catch((error) => {
      });
  }

  removeFilter(key) {
    let self = this;
    if (this.filters) {
      for (let i = 0; i < this.filters.length; i++) {
        let filter = this.filters[i];
        if (filter['key'] === key) {
          this.filters.splice(i, 1);
        }
      }
    }
    /*
    * TODO: handle in storage function
    */
    if (this.filters.length === 0) {
      let filterStorageItem = localStorage.getItem('filters');
      let filterStorage = {};
      if (filterStorageItem) {
        filterStorage = JSON.parse(filterStorageItem);
      }

      filterStorage[this.path] = {};

      localStorage.setItem('filters', JSON.stringify(filterStorage));
    }
    self.all();
  }

  pushFilter(searchValues) {
    let key = searchValues['key'];
    let values = searchValues['values'];

    if (this.filters) {
      let filterOptions = [];
      for (let i = 0; i < this.filters.length; i++) {
        let filter = this.filters[i];
        if (filter['key'] === key) {
          this.filters[i] = {
            key: key,
            values: values,
          };

          return true;
        }
      }
    }
    this.filters.push({
      key: key,
      values: values,
    });
    return true;
  }

  all() {
    if (this.loading === true) {
      /// do nothing
      return;
    }

    let filterStorageItem = localStorage.getItem('filters');
    let filterStorage = {};
    if (filterStorageItem) {
      filterStorage = JSON.parse(filterStorageItem);
    }

    let url = this.path;
    url += '&data[page]=' + this.page;
    url += '&data[limit]=' + this.limit;
    url += '&data[sort]=' + this.sort;
    url += '&data[direction]=' + this.direction;
    url += '&data[sortType]=' + this.sortType;

    let filterOption;
    if (this.keyword.length > 1) {
      url += '&data[keyword]=' + this.keyword.replace(" ", "+");
    }

    // *** KEY CHANGE: Add default filter if path is financial and no filter exists ***
    if (this.path === 'termStatements' && (!this.filters || this.filters.length === 0)) {
      this.filters = [{
        key: 'status',
        values: ['OPEN', 'INVOICE_SENT'] // Array of values
      }];
      filterOption = 'data[filters][status][values]=OPEN%7C%7CINVOICE_SENT';
    } else if (this.filters && this.filters.length > 0) {
      let filterOptions = [];
      for (let i = 0; i < this.filters.length; i++) {
        if (this.filters[i].values) {
          filterOptions.push('data[filters][' + this.filters[i].key + '][values]=' + encodeURIComponent(this.filters[i].values.join('||')) + '');
        }
      }
      filterOption = filterOptions.join('&');

      filterStorage[this.path] = { filters: filterOption };
      localStorage.setItem('filters', JSON.stringify(filterStorage));
    } else if (filterStorage[this.path]) {
      let values = filterStorage[this.path]['filters'];
      filterOption = values;
    }

    let self = this;
    self.loading = true;
    this.app.setLoaderStatus(true);


    this.apiService.save(url, filterOption).then((response) => {
      self.loading = false;
      this.app.setLoaderStatus(false);

      self.items = response['items'];
      self.total = response['total'];
      self.pages = response['pages'];
      self.page = response['page'];
      // self.limit = response['limit'];
      self.totals = response['totals'];
      self.filterOptions = response['filterOptions'];

      if (self.keyword.length < 1) {
        self.keyword = response['keyword'];
      }

      // if (self.filters.length < 1)
      // {
      self.filters = response['filters'];
      // }

      self.sort = response['sort'];
      self.direction = response['direction'];

      self.pagesCounter = [];
      for (let i = 1; i <= self.pages; i++) {
        self.pagesCounter.push(i);
      }
    }).catch(function (error) {
    });
  }

  doSearch(e) {
    let self = this;
    if (self.globalTimeout != null) {
      clearTimeout(self.globalTimeout);
    }
    self.globalTimeout = setTimeout(function () {
      self.globalTimeout = null;
      self.all();
    }, 500);
  }

  getPage(number) {
    this.page = number;
    this.all();
  }

  getPrevious() {
    this.page--;
    if (this.page < 1) {
      this.page = 1
    }
    this.all();
  }

  getNext() {
    this.page++;
    if (this.page > this.pages) {
      this.page = this.pages
    }
    this.all();
  }

  edit(item) {
    this.editAction.emit(item);
  }

  delete(item) {
    this.deleteAction.emit(item);
  }

  setSort(sort, map = null) {
    if (map.sort) {
      this.sort = sort;
      if (this.direction === 'ASC') {
        this.direction = 'DESC';
      } else {
        this.direction = 'ASC';
      }

      let sortType = "STRING";
      if (map.sortType) {
        sortType = map.sortType;
      }

      this.sortType = sortType;

      this.sortAction.emit({
        sort: this.sort,
        direction: this.direction,
        sortType: sortType
      });
      this.all();
    }
  }
}
