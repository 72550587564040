import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { AuthenticationService } from 'src/app/services/core/authentication.service';

import {
  CdkDragDrop,
  CdkDragEnter,
  CdkDragMove,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { ConfigService } from 'src/app/services/core/config.service';

@Component({
  selector: 'app-picture-export',
  templateUrl: './picture-export.component.html',
  styleUrls: ['./picture-export.component.scss']
})
export class PictureExportComponent implements OnInit {
  @Input() public title;
  @Input() public button;
  @Input() public message;
  @Input() public pictures;
  @Input() public project;
  @Input() public selectedPictures;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();
  showSuccess = false;
  content;
  loaderOverlay = false;
  files = [];
  photos = [];
  previewUrl = '';
  currentUser;

  obj;

  constructor(
    public activeModal: NgbActiveModal,
    public appService: AppService,
    public config: ConfigService,
    public api: ApiConnectionService,
    public sanitizer: DomSanitizer,
    public authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.authService.getCurrentUser();
    this.authService.data.subscribe(data => {
      this.currentUser = data;
    });

    this.selectedPictures.forEach(picture => {
      const entry = this.pictures.filter((obj) => obj.id == picture.id)
      if (entry) {
        this.setActive(entry[0]);
      }
    });
  }

  selectAll() {
    this.pictures.forEach(element => {
      this.setActive(element);
    });
  }

  deselectAll() {
    this.pictures.forEach(element => {
      this.setInActive(element);
    });
  }

  generatePDF() {
    let url = this.config.get('api_url');
    url += 'projectPhotos/pdfSelectablePhotos/' + this.project['id'] + '/';
    let ids = [];
    for (let i = 0; i < this.pictures.length; i++) {
      if (this.pictures[i]['selected'] === true) {
        ids.push(this.pictures[i]['id']);
      }
    }

    url += ids.join(',');
    url += `&hash=${this.currentUser['publicHash']}`;

    window.open(url, "_blank");
  }

  setInActive(entry) {
    entry['selected'] = false;
  }

  setActive(entry) {
    entry['selected'] = true;
  }

  selectedPhotoCount() {
    let count = 0;
    for (let i = 0; i < this.photos.length; i++) {
      if (this.photos[i].selected == true) {
        count++;
      }
    }
    return count;
  }

  dropPending(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.files, event.previousIndex, event.currentIndex);
    return;
  }

  showFiles() {
    this.activeModal.close('SHOW_FILES');
  }

  showQuoteEmail() {
    this.activeModal.close('SHOW_EMAIL');
  }


  confirm() {
    this.activeModal.close(false);
  }

  cancel() {
    this.activeModal.close(false);
    this.deselectAll();
  }

  close() {
    this.activeModal.close(false);
    this.deselectAll();
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
}
