<section class="p-5" *ngIf="items && items.length < 1">
    <p class="text-center text-muted">Geen notities beschikbaar</p>
    <p class="text-center">
        <button type="button" class="btn btn-success" (click)="addRow()">
            <i class="bi bi-plus-lg text-white me-2"></i> Notitie toevoegen
        </button>
    </p>
</section>
<section class="p-5" *ngIf="!items">
    laden
</section>
<section class="table-grid" *ngIf="items && items.length > 0">
    <section class="table-content">
        <table class="table table-vertical-align-top desktop" style="margin-bottom:0;">
            <thead class="thead-dark">
                <tr>
                    <th width="180">Datum</th>
                    <th>Omschrijving</th>
                    <th width="140"></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of items; let index = index; let last = last;" class="user-hour-row">
                    <td>
                        {{item['createdAtText']}}
                        <span (click)="generatePDF(item['content'], item['createdAtText'])">
                            <i class="bi bi-file-earmark-pdf-fill add2-icon row-icon"></i>
                        </span>
                        <br /><small style="display:inline-block;font-size:80%;">{{item['createdByName']}}</small>
                    </td>
                    <td>
                        <textarea #elem [style.height.px]="elem.scrollHeight" [(ngModel)]="item['content']"
                            [disabled]="item['createdByName'] !== currentUser['name'] && currentUser['role'] == 'USER'"
                            placeholder="Omschrijving" (blur)="update(item)" class="form-control"
                            style="width:100%;">{{item['content']}}</textarea>
                    </td>
                    <td>
                        <span (click)="confirmDelete(item)" *ngIf="currentUser['role'] !== 'USER'"
                            class="me-3 row-icon delete2-icon">
                            <i class="bi bi-trash-fill"></i>
                        </span>
                        <span class="me-3" (click)="addRow()">
                            <i class="bi bi-plus-lg add2-icon row-icon"></i>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>


        <div class="table table-vertical-align-top mobile" style="margin-bottom:0;">
            <div *ngFor="let item of items; let index = index; let last = last;" class="mobile-note-row">
                <div style="display: flex; align-items: center; justify-content: space-between;">
                    {{item['createdAtText']}}
                    <br /><small style="display:inline-block;font-size:80%;">{{item['createdByName']}}</small>
                </div>
                <div>
                    <textarea [(ngModel)]="item['content']"
                        [disabled]="item['createdByName'] !== currentUser['name'] && currentUser['role'] == 'USER'"
                        placeholder="Omschrijving" (blur)="update(item)" class="form-control"
                        style="width:100%;height: 100px;">{{item['content']}}</textarea>
                </div>
                <div style="display: flex; align-items: center; justify-content: space-between;">
                    <span (click)="confirmDelete(item)" *ngIf="currentUser['role'] !== 'USER'"
                        class="me-3 row-icon delete2-icon">
                        <i class="bi bi-trash-fill"></i>
                    </span>
                    <span (click)="addRow()">
                        <i class="bi bi-plus-lg add2-icon row-icon"></i>
                    </span>
                </div>
            </div>
        </div>
    </section>
</section>