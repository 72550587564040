<section class="project-files project-files-{{type}}" [ngClass]="{
    'loader-overlay': loaderOverlay    
}">
    <div class="row">
        <div class="col-12 file-list" [ngClass]="{
            'modal-view': target != 'GENERAL' && target != 'CALCULATION'
        }">

            <div class="flexButtons">
                <div>
                    <div class="dropdown mb-3 d-inline-block" *ngIf="currentUser['role'] === 'ADMIN' && projectFiles">
                        <button class="btn btn-dark dropdown-toggle" type="button" data-bs-toggle="dropdown"
                            (click)="toggleDropdown()">
                            Acties
                        </button>
                        <ul class="dropdown-menu" [ngClass]="{
                        'show': showDropDown == true
                        }" style="margin-top:0;margin-left:0;">
                            <li class="hide-on-modal"><a class="dropdown-item" (click)="setShowCategory(true)"
                                    href="javascript:;">Wijzig categorie</a></li>
                            <li><a class="dropdown-item" (click)="confirmDelete()" href="javascript:;">Verwijder</a>
                            </li>
                        </ul>
                    </div>

                    <div class="d-inline-block only-for-admin" style="margin-left: 15px;">
                        <button class="btn btn-dark" type="button" (click)="adjustCategoryOrder()">
                            Volgorde wijzigen
                        </button>
                    </div>

                    <div class="dropdown mb-3 d-inline-block filterButton"
                        *ngIf="currentUser['role'] === 'ADMIN' && projectFiles">
                        <button class="btn btn-dark dropdown-toggle" type="button" data-bs-toggle="dropdown"
                            (click)="toggleFilterDropdown()">
                            Sorteren
                        </button>
                        <ul class="dropdown-menu" [ngClass]="{
                        'show': showFilters == true
                        }" style="margin-top:0;margin-left:0;">
                            <li class="hide-on-modal">
                                <a class="dropdown-item" (click)="setSort('name','asc')" href="javascript:;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="ai ai-Descending">
                                        <path d="M6 21V3m0 18l3-3.333M6 21l-3-3.333" />
                                        <path d="M14 3h8l-1 4h-7V3z" />
                                        <path d="M14 10h6l-1 4h-5v-4z" />
                                        <path d="M14 17h4l-1 4h-3v-4z" />
                                    </svg>
                                    Naam oplopend</a>
                            </li>
                            <li class="hide-on-modal">
                                <a class="dropdown-item" (click)="setSort('name','desc')" href="javascript:;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" class="ai ai-Ascending">
                                        <path d="M6 3v18M6 3l3 3.333M6 3L3 6.333" />
                                        <path d="M14 21h8l-1-4h-7v4z" />
                                        <path d="M14 14h6l-1-4h-5v4z" />
                                        <path d="M14 7h4l-1-4h-3v4z" />
                                    </svg>
                                    Naam aflopend</a>
                            </li>
                            <li>
                                <a class="dropdown-item" (click)="setSort('date','asc')" href="javascript:;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="ai ai-Descending">
                                        <path d="M6 21V3m0 18l3-3.333M6 21l-3-3.333" />
                                        <path d="M14 3h8l-1 4h-7V3z" />
                                        <path d="M14 10h6l-1 4h-5v-4z" />
                                        <path d="M14 17h4l-1 4h-3v-4z" />
                                    </svg>
                                    Datum oplopend
                                </a>
                            </li>
                            <li>
                                <a class="dropdown-item" (click)="setSort('date','desc')" href="javascript:;">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"
                                        stroke-linejoin="round" class="ai ai-Ascending">
                                        <path d="M6 3v18M6 3l3 3.333M6 3L3 6.333" />
                                        <path d="M14 21h8l-1-4h-7v4z" />
                                        <path d="M14 14h6l-1-4h-5v4z" />
                                        <path d="M14 7h4l-1-4h-3v4z" />
                                    </svg>
                                    Datum aflopend
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="d-inline-block file-upload" (dragover)="dropzoneHovered = true"
                    (dragleave)="dropzoneHovered = false" [ngClass]="{
                'has-files': projectFiles,
                'hide-meta': hideMeta,
                'modal-view': target != 'GENERAL'  &&  target != 'CALCULATION'
            }">
                    <div class="file-container" (dragover)="dropzoneHovered = true"
                        (dragleave)="dropzoneHovered = false">
                        <section class="user-form" *ngIf="project">
                            <input type="hidden" *ngIf="project" class="form-control" id="id"
                                [(ngModel)]="project['id']" name="id">
                        </section>
                        <section style="position:relative;" class="dropzone-container" [ngClass]="{
                            'dropzone-hovered': dropzoneHovered
                        }">
                            <!-- [ngClass]="{ 'd-none': currentUser['role'] !== 'ADMIN' && fileType != 'projectPhotos'}" -->
                            <section class="d-none">
                                <ngx-dropzone #drop (change)="onSelect($event)" (dragover)="dropzoneHovered = true"
                                    (dragleave)="dropzoneHovered = false" class="dropzone">
                                    <ngx-dropzone-label><i class="bi bi-file-earmark-ruled"></i></ngx-dropzone-label>
                                    <ngx-dropzone-preview class="filename" *ngFor="let f of files" [removable]="true"
                                        (removed)="onRemove(f)">
                                        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
                                    </ngx-dropzone-preview>
                                </ngx-dropzone>
                            </section>
                            <button (click)="drop.showFileSelector()"
                                class="dropdown mb-3 d-inline-block filterButton btn btn-primary"
                                *ngIf="type != 'quick' && currentUser['role'] === 'ADMIN'">
                                <i class="bi bi-file-earmark-arrow-up me-1"></i> <span *ngIf="target == 'GENERAL'">Kies
                                    bestanden</span><span *ngIf="target != 'GENERAL'">Upload bestanden</span>
                            </button>
                            <button (click)="drop.showFileSelector()"
                                class="dropdown mb-3 d-inline-block filterButton btn btn-light"
                                *ngIf="type == 'quick' && projectFiles.length < 1 && currentUser['role'] === 'ADMIN'">
                                <i class="bi bi-file-earmark-arrow-up me-1"></i> <span
                                    *ngIf="target != 'GENERAL'">Upload
                                    bestanden</span>
                            </button>
                            <button (click)="editPageProjectPermitStatus (project, target)"
                                class="dropdown mb-3 d-inline-block filterButton btn btn-dark"
                                *ngIf="type == 'quick' && projectFiles.length > 0">
                                <i class="bi bi-file-earmark-arrow-up me-1"></i> <span
                                    *ngIf="target != 'GENERAL'">Bekijk
                                    bestanden</span>
                            </button>
                        </section>
                    </div>
                </div>

            </div>

            <span class="d-online-block" *ngIf="showCategoryChange">
                <select (change)="handleCategoryChange($event)" [style.opacity]="targetCategory != '' ? '1' : '0.8'"
                    class="form-control ms-2 d-inline-block"
                    style="width:240px;position:relative;top:1px;height:42.5px;">
                    <option selected value="">Wijzig naar categorie</option>
                    <option value="{{category.value}}" *ngFor="let category of fileCategoryOptions">
                        {{category.name}}
                    </option>
                </select>

                <button class="btn btn-dark ms-1 with-icon" *ngIf="targetCategory != ''"
                    (click)="saveCategoryChange(targetCategory)" style="height: 40px;top: -1px;">
                    <i class="bi bi-arrow-right"></i> Toepassen
                </button>

                <button class="btn btn-light ms-1" (click)="setShowCategory(false)" style="height: 40px;top: -1px;">
                    Annuleren
                </button>
            </span>

            <section class="table-grid">
                <section class="table-content">
                    <td colspan="999" class="addCategoryButton only-for-admin" (click)="addCategory(0)" style="    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;">
                        <b>Categorie toevoegen <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                viewBox="0 0 24 24" fill="currentColor" stroke-width="2" class="ai ai-CirclePlusFill">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zm1 15a1 1 0 1 1-2 0v-3H8a1 1 0 1 1 0-2h3V8a1 1 0 1 1 2 0v3h3a1 1 0 1 1 0 2h-3v3z" />
                            </svg></b>
                    </td>
                    <ng-container *ngFor="let item of fileCategoryOptions">
                        <table class="table table-vertical-align mb-0" *ngIf="canShow(item.role)">
                            <tbody>
                                <thead class="thead-dark">
                                    <tr style="width: 100vw;">
                                        <td style="width: 100vw;" class="relative file-tree-table-data" colspan="999"
                                            (drop)="onFolderDrop($event, item['value'])" (dragover)="allowDrop($event)"
                                            (dragleave)="afterFileOverviewDrop($event)">
                                            <p style="margin-bottom:0;">
                                                {{item.name}}
                                                <small> - {{count(item.files)}}</small>
                                            </p>
                                            <p class="categoryRole only-for-admin">
                                                {{translateRole(item.role)}} <svg *ngIf="item.editable"
                                                    (click)="editCategoryName(item.id, item.name, item)"
                                                    style="cursor: pointer;" xmlns="http://www.w3.org/2000/svg"
                                                    width="16" height="16" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" class="ai ai-Edit">
                                                    <path
                                                        d="M16.474 5.408l2.118 2.117m-.756-3.982L12.109 9.27a2.118 2.118 0 0 0-.58 1.082L11 13l2.648-.53c.41-.082.786-.283 1.082-.579l5.727-5.727a1.853 1.853 0 1 0-2.621-2.621z" />
                                                    <path
                                                        d="M19 15v3a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h3" />
                                                </svg>
                                            </p>
                                            <svg (click)="collapse(item['value'])"
                                                [ngClass]="{'collapsed' : frontCollapse[item['value']]}"
                                                xmlns="http://www.w3.org/2000/svg" width="36" height="36"
                                                viewBox="0 0 24 24" fill="none" stroke="#000" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="ai ai-ChevronDown collapseButton">
                                                <path d="M4 9l8 8 8-8" />
                                            </svg>
                                        </td>
                                    </tr>
                                </thead>

                                <table class="scrollable">

                                    <tr *ngFor="let file of item.files"
                                        [ngClass]="{'hidden' : frontCollapse[item.value]}" class="fileRow"
                                        (dragstart)="drag($event)">
                                        <td class="hideMobile" style="width: 60px;padding-top:20px"
                                            *ngIf="currentUser['role'] === 'ADMIN'">
                                            <span class="pe-2" (click)="toggleSelect(file['id'])">
                                                <i class="bi bi-square-fill check-inactive check-icon"
                                                    *ngIf="!selectedFiles[file['id']]"></i>
                                                <i class="bi bi-check-square-fill check-icon"
                                                    *ngIf="selectedFiles[file['id']]"></i>
                                            </span>
                                        </td>
                                        <td class="" [attr.data-file-id]="file['id']" draggable="true"
                                            (dragstart)="drag($event)">
                                            <a (click)="showPreview(file, projectFiles)" class="file-link"
                                                [attr.data-file-id]="file['id']">
                                                <span class="file-icon float-start me-2 mt-2"
                                                    [innerHTML]="transform(file['icon'])"></span>
                                                <div style="float:left;overflow: hidden;" class="max-width"
                                                    title="{{file['name']}}">
                                                    <p style="float:left;margin-bottom:4px;"><span
                                                            style="white-space: nowrap;">{{file['name']}}</span>
                                                        <small
                                                            class="text-primary d-block">{{file['displayDateText']}}</small>
                                                    </p>
                                                </div>
                                            </a>
                                        </td>
                                        <td class="hideMobile" nowrap *ngIf="hideMeta === false">
                                            {{file['sizeMb']}}
                                        </td>
                                        <td class="hideMobile" nowrap *ngIf="hideMeta === false">
                                            {{file['createdByName']}}
                                        </td>
                                        <td class="hideMobile" nowrap *ngIf="hideMeta === false">
                                            {{file['categoryText']}}
                                        </td>
                                        <td nowrap style="text-align:right;">
                                            <span *ngIf="currentUser['role'] === 'ADMIN'"
                                                (click)="editFilename(project, file)"
                                                style="display:inline-block;padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;cursor:pointer;"
                                                class=""><i class="bi bi-pencil"></i></span>
                                            <span (click)="showPreview(file, projectFiles)"
                                                style="display:inline-block;padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;cursor:pointer;"
                                                class=""><i class="bi bi-search"></i></span>
                                            <span *ngIf="currentUser['role'] === 'ADMIN' || fileType == 'projectPhotos'"
                                                (click)="confirmDelete2(file)"
                                                style="display:inline-block;padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;cursor:pointer;"
                                                class=""><i class="bi bi-trash"></i></span>
                                        </td>
                                    </tr>
                                </table>
                                <!-- <tr>
                                    <td colspan="999" class="addCategoryButton" (click)="addCategory(item.key)">
                                        <b>Subcategorie toevoegen <svg xmlns="http://www.w3.org/2000/svg" width="18"
                                                height="18" viewBox="0 0 24 24" fill="currentColor" stroke-width="2"
                                                class="ai ai-CirclePlusFill">
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                    d="M12 1C5.925 1 1 5.925 1 12s4.925 11 11 11 11-4.925 11-11S18.075 1 12 1zm1 15a1 1 0 1 1-2 0v-3H8a1 1 0 1 1 0-2h3V8a1 1 0 1 1 2 0v3h3a1 1 0 1 1 0 2h-3v3z" />
                                            </svg></b>
                                    </td>
                                </tr> -->
                            </tbody>
                        </table>
                    </ng-container>

                    <!-- <table class="table table-vertical-align mb-0" *ngIf="projectFiles && projectFiles.length > 0">
                        <thead class="thead-dark">
                            <tr>
                                <th *ngIf="currentUser['role'] === 'ADMIN'">&nbsp;</th>
                                <th>Bestand</th>
                                <th *ngIf="hideMeta === false">Grootte</th>
                                <th *ngIf="hideMeta === false">Uploader</th>
                                <th *ngIf="hideMeta === false">Categorie</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let file of projectFiles; let i = index" [ngClass]="{
                                'd-none': currentUser['role'] !== 'ADMIN' && file['category'] == 'QUOTE'
                            }">
                                <td style="width: 60px;padding-top:20px" *ngIf="currentUser['role'] === 'ADMIN'">
                                    <span class="pe-2" (click)="toggleSelect(file['id'])">
                                        <i class="bi bi-square-fill check-inactive check-icon" *ngIf="!selectedFiles[file['id']]"></i>
                                        <i class="bi bi-check-square-fill check-icon" *ngIf="selectedFiles[file['id']]"></i>
                                    </span>
                                </td>
                                <td class="">
                                    <a [href]=" sanitize(file['openUrl']) ">
                                        <span  class="file-icon float-start me-2 mt-2" [innerHTML]="transform(file['icon'])"></span>
                                        <div style="float:left;max-width:150px;overflow: hidden;" title="{{file['name']}}">
                                            <p style="float:left;margin-bottom:4px;"><span style="white-space: nowrap;">{{file['name']}}</span>
                                                <small class="text-primary d-block">{{file['createdAtText']}}</small>
                                            </p>
                                        </div>
                                    </a>
                                </td>
                                <td nowrap *ngIf="hideMeta === false">
                                    {{file['sizeMb']}}
                                </td>
                                <td nowrap *ngIf="hideMeta === false">
                                    {{file['createdByName']}}
                                </td>
                                <td nowrap *ngIf="hideMeta === false">
                                    {{file['categoryText']}}
                                </td>
                                <td nowrap style="text-align:right;">
                                    <span *ngIf="currentUser['role'] === 'ADMIN'" (click)="editFilename(project, file)" 
                                        style="display:inline-block;padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;cursor:pointer;"
                                        class=""><i class="bi bi-pencil"></i></span>
                                    <span (click)="showPreview(file, projectFiles)" 
                                        style="display:inline-block;padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;cursor:pointer;"
                                        class=""><i class="bi bi-search"></i></span>
                                    <span  *ngIf="currentUser['role'] === 'ADMIN' || fileType == 'projectPhotos'" (click)="confirmDelete2(file)" 
                                         style="display:inline-block;padding-left:5px;padding-right:5px;margin-left:5px;margin-right:5px;cursor:pointer;"
                                         class=""><i class="bi bi-trash"></i></span>
                                </td>
                            </tr>
                        </tbody>
                    </table> -->
                </section>
            </section>
        </div>
    </div>
</section>