import { Component, OnInit, Renderer2 } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';

import { ProjectHoursNotesComponent } from '../projects-detail/project-hours/project-hours-notes/project-hours-notes.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserEditModalComponent } from 'src/app/components/users/user-edit-modal/user-edit-modal.component';

import { AuthenticationService } from 'src/app/services/core/authentication.service';

@Component({
  selector: 'app-hours',
  templateUrl: './hours.component.html',
  styleUrls: ['./hours.component.scss']
})
export class HoursComponent implements OnInit {
  projectHours;
  weeks = [];
  years = [];

  currentUser;

  weekChecks: any = [];

  startWeek;
  startYear;
  endWeek;
  endYear;

  public userDetailId;
  public userDetailPeriod;
  public userDetails;
  public expendedKey = '';

  public filterName = '';
  public activeFilter = '';
  public suggestionList = [];

  public isFiltered = false;

  constructor(
    private api: ApiConnectionService,
    private appService: AppService,
    public renderer: Renderer2,
    private modalService: NgbModal,
    private authenticationService: AuthenticationService
  ) {
    let currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 42);

    let startDate = new Date(currentDate.getFullYear(), 0, 1);
    let tmp1 = currentDate.getTime();
    let tmp2 = startDate.getTime();

    var days = Math.floor((tmp1 - tmp2) / (24 * 60 * 60 * 1000));
    var weekNumber: any = Math.ceil(days / 7);

    if (weekNumber < 10) {
      weekNumber = weekNumber === 0 ? 1 : weekNumber;
      weekNumber = "0" + weekNumber;
    }

    this.startWeek = weekNumber;
    this.startYear = currentDate.getFullYear();

    let currentDate2 = new Date();
    let startDate2 = new Date(currentDate2.getFullYear(), 0, 1);
    let tmp12 = currentDate2.getTime();
    let tmp22 = startDate2.getTime();

    var days2 = Math.floor((tmp12 - tmp22) / (24 * 60 * 60 * 1000));
    var weekNumber2: any = Math.ceil(days2 / 7);

    if (weekNumber2 < 10) {
      weekNumber2 = "0" + (weekNumber2 + 1);
    }

    this.endWeek = weekNumber2;
    this.endYear = currentDate2.getFullYear();

    const calculateWeeksInYear = (year: number): number => {
      const firstDayOfYear = new Date(year, 0, 1);
      const lastDayOfYear = new Date(year, 11, 31);

      const isLeapYear = (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
      const has53Weeks =
        firstDayOfYear.getDay() === 4 || // Thursday start
        (isLeapYear && firstDayOfYear.getDay() === 3); // Leap year, Wednesday start

      return has53Weeks ? 53 : 52;
    };

    // Generate weeks for the current year dynamically
    const weeksInYear = calculateWeeksInYear(this.startYear);
    for (let i = 1; i <= weeksInYear; i++) {
      let str = i.toString();
      if (i < 10) {
        str = '0' + i;
      }
      this.weeks.push(str);
    }

    var curr = new Date();
    let maxYear = curr.getFullYear();

    maxYear++;
    for (let i = maxYear; i > 2021; i--) {
      let str = i.toString();
      this.years.push(str);
    }

    authenticationService.getCurrentUser();
    authenticationService.data.subscribe((data) => {
      if (Object.keys(data).length) {
        this.currentUser = data;
      } else {
        this.currentUser = false;
      }
    });
  }


  editHoursNote(project, text) {
    let self = this;

    const filterModal = this.modalService.open(ProjectHoursNotesComponent, {
      size: "lg",
      windowClass: "second-modal",
      backdropClass: "second-modal-backdrop",
      backdrop: "static",
    });

    filterModal.componentInstance.project = project;
    filterModal.componentInstance.text = text;
    // filterModal.componentInstance.target = "" + self.fileType + "";
    // filterModal.componentInstance.projectFile = projectFile;
    filterModal.result.then((result) => { self.getHours(); }).catch((error) => {
      self.getHours();
    });
  }
  searchNames() {
    this.suggestionList = [];
    this.activeFilter = this.filterName;
    for (let period = 0; period < this.projectHours.length; period++) {
      for (let i = 0; i < this.projectHours[period].hours.length; i++) {
        const entry = this.projectHours[period].hours[i];
        if ((entry.userName).toLowerCase().includes(this.filterName.toLowerCase())) {
          if (!this.suggestionList.includes(entry.userName)) {
            this.suggestionList.push(entry.userName);
          }
        }
      }
    }
  }

  hasNotes(hoursList: any) {
    // console.log(hoursList);
    // let hasNotes = false;
    // for (let i = 0; i < hoursList.length; i++) {
    //   if (hoursList[i].note != null) {
    //     hasNotes = true;
    //     break;
    //   }
    // }

    // return hasNotes;
  }

  getDateOfWeek(dayIndex: number, weekNumber: number, year: number): string {
    // Get the date of January 4th (always in the first ISO week of the year)
    const jan4 = new Date(year, 0, 4);

    // Calculate the day of the week for January 4th (0 = Sunday, 6 = Saturday)
    const jan4Day = jan4.getDay();

    // Determine the Monday of the first ISO week (adjust 0 = Sunday -> 6 = Saturday to ISO's 1 = Monday -> 7 = Sunday)
    const firstMonday = new Date(jan4);
    firstMonday.setDate(jan4.getDate() - ((jan4Day + 6) % 7));

    // Calculate the start of the given ISO week (subtract 1 because week numbers are 1-based)
    const startOfWeek = new Date(firstMonday);
    startOfWeek.setDate(firstMonday.getDate() + (weekNumber - 1) * 7);

    // Calculate the desired day within the week
    const targetDate = new Date(startOfWeek);
    targetDate.setDate(targetDate.getDate() + dayIndex);

    // Format the date to "DD-MM"
    const day = targetDate.getDate().toString().padStart(2, '0');
    const month = (targetDate.getMonth() + 1).toString().padStart(2, '0');

    return `${day}-${month}-${targetDate.getFullYear()}`;
  }

  clearSearch() {
  }

  ngOnInit(): void {
    this.appService.setPageTitle('Urenregistratie');
    this.appService.setNavKey('hours');
    this.getHours();
  }

  weekChecked(week: number, year: number, userId: any): boolean {
    return this.weekChecks.some(entry => entry.week == week && entry.year == year && entry.userId == userId && entry.checked);
  }

  getCheckObj(week: number, year: number, userId: any): any {
    return this.weekChecks.some(entry => entry.week == week && entry.year == year && entry.userId == userId);
  }

  getNote(week: number, year: number, userId: any): any {
    const obj = this.weekChecks.find(entry => entry.week == week && entry.year == year && entry.userId == userId);
    if (obj == undefined) {
      return "";
    } else {
      return obj['note'];
    }
  }

  saveNote(week: number, year: number, userId: any, note: any) {
    this.api.save(`projects/saveWeekCheck`, {
      week: week,
      year: year,
      userId: userId,
      note: note.target.value
    }).then((data) => {
      // this.getWeekCheck();
    });
  }

  canShow(username) {
    if (this.filterName != '') {
      if ((username).toLowerCase().includes(this.filterName.toLowerCase())) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  }

  setFilter(name) {
    this.activeFilter = name;
    this.filterName = name;
    this.isFiltered = true;
  }

  tryReset() {
    if (this.isFiltered) {
      this.isFiltered = false;
      this.filterName = "";
      this.activeFilter = "";
    }
  }

  setExpended(key) {
    this.expendedKey = key;
    this.userDetailId = '';
    this.userDetailPeriod = '';
    this.userDetails = '';
  }

  selectAll(event) {
    event.target.select();
  }

  addHash(url) {
    return url + "&hash=" + this.currentUser['publicHash'];
  }

  setUserDetailId(userId, period) {
    this.userDetailId = userId;
    this.userDetailPeriod = period;
    this.getUserDetails();
  }

  getUserDetails() {
    let self = this;
    this.api.read('projectHours/userWeek/' + self.userDetailId + '/' + self.userDetailPeriod + '').then((response) => {
      self.userDetails = response['items'];
    });
  }

  getHours() {
    let self = this;
    this.getWeekCheck();
    this.api.read('projectHours/list/' + self.startYear + '' + self.startWeek + '/' + self.endYear + '' + self.endWeek + '').then((response) => {
      console.log(response)
      self.projectHours = response['items'];
    });
  }

  getWeekCheck() {
    this.api.read(`projects/getUserWeekChecks&startDateWeek=${this.startYear}${this.startWeek}&endDateWeek=${this.endYear}${this.endWeek}`).then((data: any) => {
      this.weekChecks = data.data;
    })
  }

  openForm(data) {
    let self = this;

    const filterModal = this.modalService.open(UserEditModalComponent, {
      windowClass: 'modal-right',
      backdrop: 'static',
      size: 'lg'
    });

    filterModal.componentInstance.id = data;
    filterModal.componentInstance.closeAfterSave = true;
    // filterModal.componentInstance.organisationId = data['organisationId'];
    // filterModal.componentInstance.roleKey = data['role'];
    filterModal.result.then((result) => {
      this.getHours();
    })
      .catch((error) => {
      });
  }

  setWeekCheck(week, year, userId, checked) {
    this.api.save(`projects/saveWeekCheck`, {
      week: week,
      year: year,
      userId: userId,
      checked: checked
    }).then((data) => {
      this.getWeekCheck();
    });
  }

  clearUserDetailId() {
    this.userDetailId = '';
    this.userDetailPeriod = '';
  }

  dateString(week) {
    var curr = new Date; // get current date

    let y = this.startYear;
    var d = new Date("Jan 01, " + y + " 01:00:00");
    var dayMs = (24 * 60 * 60 * 1000);
    var offSetTimeStart = dayMs * (d.getDay() - 1);
    var w = d.getTime() + 604800000 * (week - 1) - offSetTimeStart; //reducing the offset here
    var n1 = new Date(w);
    var n2 = new Date(w + 518400000);

    let day = (n1.getDate() < 10 ? '0' + n1.getDate().toString() : n1.getDate().toString());
    let month = n1.getMonth();
    month = (month + 1);
    let monthStr = (month < 10 ? '0' + month.toString() : month.toString());

    var firstday = day + '-' + monthStr + '-' + n1.getFullYear();
    return firstday;
  }

}
