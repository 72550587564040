import { Component, OnInit , Input , Output} from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-filter',
  templateUrl: './add-filter.component.html',
  styleUrls: ['./add-filter.component.scss']
})
export class AddFilterComponent implements OnInit {
	@Input() public title;
	@Input() public button;
	@Input() public message;
	@Input() public filterOptions;
	@Output() passEntry: EventEmitter<any> = new EventEmitter();

	constructor(
  		public activeModal: NgbActiveModal
  	) { }

	ngOnInit(): void {
	}

	editFilter(key) {
		this.activeModal.close(key);
	}
  
	dismiss () {
		this.activeModal.close(false);
	}
	
	close () {
		this.activeModal.close(false);
	}
  
	cancel () {
		this.activeModal.close(false);
	}
}