import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CategoriesIndexComponent } from '../categories-index/categories-index.component';
import { CertificateTypesIndexComponent } from '../certificate-types-index/certificate-types-index.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  items = [];
  
  constructor(
    private app: AppService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.app.setNavKey('settings');
    this.app.setPageTitle('Instellingen');
  }

  openCategories() {
		let self = this;

		const filterModal = this.modalService.open(CategoriesIndexComponent, {
			windowClass: 'modal-right',
			backdrop: 'static',
			size: 'lg'
		});
	}

  openCertificateTypes() {
		let self = this;

		const filterModal = this.modalService.open(CertificateTypesIndexComponent, {
			windowClass: 'modal-right',
			backdrop: 'static',
			size: 'lg'
		});
	}
}
