import { Component, OnInit, Directive, Input, Output, ElementRef, HostListener, EventEmitter } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { ModalConfirmDeleteComponent } from '../../modals/modal-confirm-delete/modal-confirm-delete.component';
import { AppService } from 'src/app/services/app.service';

import { ProjectInfoFormComponent } from 'src/app/components/projects/project-info-form/project-info-form.component';
import { ProjectInfoFilesComponent } from 'src/app/components/projects/project-info-files/project-info-files.component';
import { QuoteStatusFormComponent } from 'src/app/components/projects/quote-status-form/quote-status-form.component';
import { QuoteAcceptedFormComponent } from 'src/app/components/projects/quote-accepted-form/quote-accepted-form.component';
import { ModalExportComponent } from 'src/app/modals/modal-export/modal-export.component';

@Component({
	selector: 'app-quotes-index',
	templateUrl: './quotes-index.component.html',
	styleUrls: ['./quotes-index.component.scss']
})
export class QuotesIndexComponent implements OnInit {
	link = 'quotes';
	refresh = 0;
	editPath = 'projects/detail/';
	mapping = [
		{ key: 'number', label: 'ID', width: '100px', sort: true },
		{ key: 'name', label: 'Project', sort: true },
		{ key: 'organisationName', label: 'Klant', sort: true },
		// {key: 'verkeersplanStatusLabel', label: 'Verkeersplan', sort: true },
		{ key: 'statusLabel', label: 'Status', sort: true, click: ($event, x) => { this.openQuoteStatus($event, x); } },
		{ key: 'requestedAtText', label: 'Aangevraagd op', sort: true, sortType: 'date' },
		{ key: 'quoteSendAtText', label: 'Verstuurd', sort: true, sortType: 'date' }
	];

	constructor(
		public apiService: ApiConnectionService,
		private appService: AppService,
		private modalService: NgbModal,
		private ngxLoader: NgxUiLoaderService
	) { }

	openQuoteStatus(event, item) {
		event.stopPropagation();
		let self = this;

		const filterModal = this.modalService.open(QuoteStatusFormComponent, {
		});

		filterModal.componentInstance.item = item;
		filterModal.result.then((result) => {
			self.refresh = Math.random();
		})
			.catch((error) => {

			});

	}

	openExportModal() {
		let self = this;

		const exportModal = this.modalService.open(ModalExportComponent, {
		});

		exportModal.componentInstance.message = "Kies een bestandstype voor de export.";
		exportModal.componentInstance.exportType = "quotes";
		exportModal.result.then((result) => {
			self.refresh = Math.random();
		})
			.catch((error) => {

			});

	}

	openAcceptedStatus(event, item) {
		event.stopPropagation();
		let self = this;

		const filterModal = this.modalService.open(QuoteAcceptedFormComponent, {
		});

		filterModal.componentInstance.item = item;
		filterModal.result.then((result) => {
			self.refresh = Math.random();
		})
			.catch((error) => {

			});

	}

	ngOnInit(): void {
		this.appService.setPageTitle('Offertes');
		this.appService.setNavKey('quotes');
	}

	openFileForm(data) {
		let self = this;

		const filterModal = this.modalService.open(ProjectInfoFilesComponent, {
			windowClass: 'modal-right modal-right-xl',
			size: 'lg'
		});

		filterModal.componentInstance.id = data['id'];
		filterModal.result.then((result) => {
			self.refresh = Math.random();
		})
			.catch((error) => {

			});
	}

	openForm(data) {
		let self = this;

		const filterModal = this.modalService.open(ProjectInfoFormComponent, {
			windowClass: 'modal-right',
			size: 'lg',
			backdrop: 'static'
		});

		filterModal.componentInstance.id = data['id'];
		filterModal.componentInstance.organisationId = data['organisationId'];
		filterModal.componentInstance.roleKey = data['role'];
		filterModal.result.then((result) => {
			self.refresh = Math.random();
			if (result !== false) {
				self.openFileForm(result);
			}
		})
			.catch((error) => {
			});
	}
}