<div class="col-8 mb-3">
    <h1>Bestandsviewer Applicatie</h1>
    <h6>Door de Kloens bestandsviewer applicatie te gebruiken kun je documenten vanuit het Kloens-portal rechtstreeks
        openen
        op je computer. De applicatie draait lokaal een server waarmee de Kloens-portal verbinding kan maken. Om te zien of dat de Kloens-portal verbinding heeft kunnen maken met de lokale bestandsviewer applicatie / server zie rechtsboven het bestandsviewer status-icoon.</h6>
    <a href="https://adaptivity.it/kloens/kloens-bestandsviewer.exe" target="_blank"><button class="btn btn-success with-icon">
        Download <i class="bi bi-save"></i>
    </button></a>
</div>
<div class="container-fluid" style="display: flex;">
    <div class="col-6">
        <ol>
            <li>
                Download de bestandsviewer applicatie door op de knop hierboven te klikken.
            </li>
            <li>
                Open de bestandsviewer.
            </li>
            <li>
                Druk op de bestandsviewer status knop rechtsboven in je scherm, en klik op 'Opnieuw proberen' om verbinding te maken met de applicatie.
            </li>
        </ol>
    </div>
    <div class="col-6" style="display: flex; align-items: center; flex-direction: column; justify-content: flex-end;">
        <img src="../assets/images/bestandsviewer-terminal.jpg" width="550px" alt="preview">
    </div>
</div>