<section *ngIf="item" [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="modal-header">
        <h5 class="modal-title">
            <span *ngIf="!item['id']">Certificaat toevoegen</span>
            <span *ngIf="item['id']">Certificaat wijzigen</span>
        </h5>
        <button type="button" class="btn-close" (click)="close()" tabindex="-1" aria-label="Close"></button>
    </div>
    <div class="modal-body">
        <div class="alert alert-success" *ngIf="successmessage">Wijzigingen succesvol opgeslagen.</div>
        <div class="alert alert-danger" *ngIf="errormessage">Er zijn enkel fouten gevonden.</div>
        <section class="user-form" *ngIf="item">
            <input type="hidden" *ngIf="item" class="form-control" id="userId" [(ngModel)]="item['userId']"
                name="userId">
            <input type="hidden" *ngIf="item" class="form-control" id="certificateTypeId"
                [(ngModel)]="item['certificateTypeId']" name="certificateTypeId">

            <div class="form-group mb-3">
                <label for="certificateTypeId" class="main-label">Certificaat type</label>
                <select style="width: 100%;" name="certificateTypeId" [(ngModel)]="item.certificateTypeId"
                    class="form-control">
                    <option value="{{type.id}}" *ngFor="let type of certificateTypes">
                        {{type.name}}
                    </option>
                </select>
            </div>

            <div class="form-group mb-3">
                <label for="name" class="pb-1">Geldig tot</label>
                <input type="date" class="form-control" id="validTill" required [(ngModel)]="item['validTill']"
                    name="validTill">
                <div class="error" *ngIf="errors['validTill']">{{errors['validTill']}}</div>
            </div>
        </section>
    </div>
    <div class="modal-footer" *ngIf="item">
        <button (click)="save()" class="btn btn-success">
            <span *ngIf="!item.id"><i class="bi bi-sd-card pe-2"></i> Toevoegen</span>
            <span *ngIf="item.id"><i class="bi bi-sd-card pe-2"></i> Opslaan</span>
        </button>
    </div>
</section>