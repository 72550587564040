<div [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="modal-header">
        <h4 class="modal-title" style="font-size:16px;">Genereer PDF</h4>
        <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
    </div>
    <div class="modal-body p-1">
        <div class="row">
            <div class="col-12">
                <span *ngFor="let photo of pictures">
                    <span class="quote-photo" [ngClass]="{
                        selected: photo['selected'] == true
                    }">
                        <span class="quote-photo-selection">
                            <i class="bi bi-check check-inactive check-icon" (click)="setActive(photo)"
                                *ngIf="photo['selected'] != true"></i>
                            <i class="bi bi-check check-icon" (click)="setInActive(photo)"
                                *ngIf="photo['selected'] == true"></i>
                        </span>
                        <img src="{{photo['thumbUrl']}}&hash={{this.currentUser['publicHash']}}"
                            (click)="setActive(photo)" *ngIf="photo['selected'] != true">
                        <img src="{{photo['thumbUrl']}}&hash={{this.currentUser['publicHash']}}"
                            (click)="setInActive(photo)" *ngIf="photo['selected'] == true">
                    </span>
                </span>
            </div>
        </div>
    </div>
    <div class="modal-footer d-block" *ngIf="showSuccess == false">
        <a class="btn btn-success with-icon" (click)="generatePDF()">Genereer PDF <i
                class="bi bi-cloud-arrow-down"></i></a>
        <a class="btn with-icon" (click)="selectAll()">Selecteer allemaal</a>
    </div>
</div>