<div [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="modal-header">
        <h4 class="modal-title">Categorie volgorde wijzigen</h4>
        <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
    </div>
    <div class="modal-body p-1" cdkDropList (cdkDropListDropped)="drop($event)">
        <div class="categoryBox" *ngFor="let category of categoryList; let i = index" cdkDrag><i class="bi bi-arrows-move"></i> {{category.name}}</div>
    </div>
    <div class="modal-footer">
        <button (click)="confirm()" class="btn btn-success with-icon">
            Opslaan <i class="bi bi-arrow-right"></i>
        </button>
    </div>
</div>