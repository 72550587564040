<div [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="modal-header">
        <h4 class="modal-title">Wijzig naam</h4>
        <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
    </div>
    
    <div class="modal-body p-1"> 
        <div class="row">
            <div class="col-12 p-5">
                Naam:
                <div class="d-flex">
                    <input class="form-control" type="text" placeholder="" style="width:70%" [(ngModel)]="projectFile['baseName']" />
                    <input class="form-control" disabled style="width:30%;" type="text" placeholder="" [(ngModel)]="projectFile['ext']" />
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer" *ngIf="projectFile">
        <button (click)="confirm()" class="btn btn-success with-icon">
            Verder <i class="bi bi-arrow-right"></i>
        </button>
    </div>  
</div>