<section [ngClass]="{'loader-overlay': loaderOverlay}">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">Urenregistratie invullen</h4>
		<button (click)="confirm()" type="submit" class="btn btn-success" [disabled]="hoursWorked < 0.1">Toevoegen</button>
		<!-- <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button> -->
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-12">
				<label class="d-inline-block pb-1">Datum</label>
				<!-- <select name="week" [(ngModel)]="week" class="form-control">
					<option value="{{week}}" *ngFor="let week of weeks">
						{{week}}
					</option>
				</select> -->
				<input disabled [(ngModel)]="week" type="text" class="form-control">
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<label class="d-inline-block pb-1">Aantal uren gewerkt</label>
				<input placeholder="0" (click)="clearHoursWorked()" [(ngModel)]="hoursWorked" type="number" class="form-control">
			</div>
		</div>
		<div class="user-group-users mb-5 pb-5" style="overflow-x: hidden; overflow-y: scroll; height: 100%;">
			<div class="row" style="position: sticky; top: 0; background: white; z-index: 99;">
				<div class="col-12"
					style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:15px; display: flex; justify-content: space-between; align-items: center;">
					<small>Medewerkers</small>
					<!-- (click)="tryReset()" (input)="searchNames()" -->
					<input style="display: inline; width: 150px; margin-right: 5px;" type="text"
						class="form-control ng-pristine ng-valid" placeholder="Zoeken op naam"
						[(ngModel)]="filterName">
				</div>

				<div class="row" *ngFor="let user of users">
					<div class="col-12 option-row"
						[ngClass]="{'inactive' : user['active'] == false}" *ngIf="selectedItems[user['id']]"
						style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;">
						<div (click)="toggleList(user['id'])">
							<i *ngIf="selectedItems[user['id']]" class="bi bi-check-square"
								style="position:relative;top:2px;margin-right:5px;"></i>
							<i *ngIf="!selectedItems[user['id']]" class="bi bi-square"
								style="position:relative;top:2px;margin-right:5px;opacity:0.5;"></i>
							{{user['name']}} <span class="text-muted">({{user['email']}})</span>
						</div>
					</div>
				</div>
			</div>
			<div class="row" *ngFor="let user of users">
				<div class="col-12 option-row" [ngClass]="selectedItems[user['id']] ? 'active' : ''"
					[ngClass]="{'inactive' : user['active'] == false}" *ngIf="canShow(user['name']) && !selectedItems[user['id']]"
					style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;">
					<div (click)="toggleList(user['id'])">
						<i *ngIf="selectedItems[user['id']]" class="bi bi-check-square"
							style="position:relative;top:2px;margin-right:5px;"></i>
						<i *ngIf="!selectedItems[user['id']]" class="bi bi-square"
							style="position:relative;top:2px;margin-right:5px;opacity:0.5;"></i>
						{{user['name']}} <span class="text-muted">({{user['email']}})</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>