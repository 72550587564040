import { Component, OnInit, Directive, Input, Output, ElementRef, HostListener, EventEmitter } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

import { AppService } from 'src/app/services/app.service';

import { ProjectInfoFormComponent } from 'src/app/components/projects/project-info-form/project-info-form.component';
import { ProjectInfoFilesComponent } from 'src/app/components/projects/project-info-files/project-info-files.component';
import { QuoteStatusFormComponent } from 'src/app/components/projects/quote-status-form/quote-status-form.component';
import { QuoteAcceptedFormComponent } from 'src/app/components/projects/quote-accepted-form/quote-accepted-form.component';
import { ModalExportComponent } from 'src/app/modals/modal-export/modal-export.component';
import { CreateTermstateComponent } from '../create-termstate/create-termstate.component';
import { ModalConfirmDeleteComponent } from 'src/app/modals/modal-confirm-delete/modal-confirm-delete.component';

@Component({
	selector: 'app-project-termstates',
	templateUrl: './project-termstates.component.html',
	styleUrls: ['./project-termstates.component.scss']
})

export class ProjectTermstatesComponent implements OnInit {

	@Input() public project;
	refresh = 0;

	items = [];
	total = 0;

	constructor(
		public apiService: ApiConnectionService,
		private appService: AppService,
		private modalService: NgbModal,
		private ngxLoader: NgxUiLoaderService
	) {
	}

	openQuoteStatus(event, item) {
		event.stopPropagation();
		let self = this;

		const filterModal = this.modalService.open(QuoteStatusFormComponent, {
		});

		filterModal.componentInstance.item = item;
		filterModal.result.then((result) => {
			self.refresh = Math.random();
		})
			.catch((error) => {

			});

	}

	openExportModal() {
		let self = this;

		const exportModal = this.modalService.open(ModalExportComponent, {
		});

		exportModal.componentInstance.message = "Kies een bestandstype voor de export.";
		exportModal.componentInstance.exportType = "quotes";
		exportModal.result.then((result) => {
			self.refresh = Math.random();
		})
			.catch((error) => {

			});

	}

	openAcceptedStatus(event, item) {
		event.stopPropagation();
		let self = this;

		const filterModal = this.modalService.open(QuoteAcceptedFormComponent, {
		});

		filterModal.componentInstance.item = item;
		filterModal.result.then((result) => {
			self.refresh = Math.random();
		})
			.catch((error) => {

			});

	}

	ngOnInit(): void {
		this.all();
	}

	parseCost(cost) {
		// Remove all non-numeric characters except for the decimal separator (',') and minus sign ('-')
		const cleanedCost = cost.replace(/[^\d,-]/g, '');

		// Replace ',' with '.' if ',' is the decimal separator
		const normalizedCost = cleanedCost.replace(',', '.');

		// Convert to a number
		const numberCost = parseFloat(normalizedCost);

		// Return the number
		return isNaN(numberCost) ? null : numberCost;
	}

	all() {
		const self = this;

		this.apiService.read('projects/details/' + self.project['id'] + '/termStatements').then(function (response) {
			self.items = response['items'];

			self.total = 0;
			self.items.forEach(item => {
				self.total = self.total + self.parseCost(item.amountExclVat);
			});
		});
	}

	toDutchFormat(val) {
		return val.toLocaleString('de-DE', {
			style: 'currency',
			currency: 'EUR',
			minimumFractionDigits: 2
		}).replace("€", "");
	}

	edit(termData) {
		const self = this;

		const termstateModal = this.modalService.open(CreateTermstateComponent, {
		});

		termstateModal.componentInstance.project = self.project;
		termstateModal.componentInstance.data = termData;
		termstateModal.result.then((result) => {
			self.refresh = Math.random();

			this.all();
		})
			.catch((error) => {

			});
	}

	remove(termData) {

		const filterModal = this.modalService.open(ModalConfirmDeleteComponent, {
		});
		filterModal.componentInstance.message = "Weet je zeker dat je deze termijnstaat wilt verwijderen?";
		filterModal.result.then((result) => {
			const self = this;
			this.apiService.delete('termStatements/delete/' + termData['id']).then(function (response) {
				self.all();
			}).catch((err) => {
				console.log(err)
			});
		})
			.catch((error) => {

			});
	}

	addRow() {
		const self = this;

		const termstateModal = this.modalService.open(CreateTermstateComponent, {
		});

		termstateModal.componentInstance.project = self.project;
		termstateModal.result.then((result) => {
			self.refresh = Math.random();

			this.all();
		})
			.catch((error) => {

			});
	}
}