<div [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="modal-header">
        <h4 class="modal-title ps-3" style="margin-left:-5px;">Contactpersoon</h4>
        <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
    </div>
    <div class="modal-body p-1" *ngIf="item">
        <section class="quick-view-container px-4 py-4">
            <h5 class="mb-4">Informatie</h5>
            <div class="row first-border">
                <div class="col-3">Naam</div>
                <div class="col-9">{{item['name']}}</div>
            </div>
            <div class="row">
                <div class="col-3">Functie</div>
                <div class="col-9">{{item['function']}}</div>
            </div>
            <div class="row">
                <div class="col-3">E-mail</div>
                <div class="col-9">{{item['email']}}</div>
            </div>
            <div class="row">
                <div class="col-3">Telefoon</div>
                <div class="col-9">{{item['phone']}}</div>
            </div>
            <h5 class="mt-5 mb-4">Klant</h5>
            <div class="row first-border" *ngIf="organisation">
                <div class="col-3">Naam</div>
                <div class="col-9">{{organisation['name']}}</div>
            </div>
            <div class="row" *ngIf="organisation">
                <div class="col-3">Adres</div>
                <div class="col-9">{{organisation['street']}} {{organisation['zipcode']}} <span *ngIf="item['city']">, </span>{{organisation['city']}}</div>
            </div>
            <div class="row" *ngIf="organisation">
                <div class="col-3">E-mail</div>
                <div class="col-9">{{organisation['email']}}</div>
            </div>
            <div class="row" *ngIf="organisation">
                <div class="col-3">Telefoon</div>
                <div class="col-9">{{organisation['phone']}}</div>
            </div>
            <div class="row" *ngIf="organisation">
                <div class="col-3">KVK</div>
                <div class="col-9">{{organisation['cocNumber']}}</div>
            </div>
            <div class="row" *ngIf="organisation">
                <div class="col-3">BTW</div>
                <div class="col-9">{{organisation['vatNumber']}}</div>
            </div>
            <div class="row" *ngIf="organisation">
                <div class="col-3">Status</div>
                <div class="col-9" [innerHTML]="organisation['activeLabel']"></div>
            </div>
        </section>
    </div>
    <div class="modal-footer d-block only-for-admin" *ngIf="item && currentUser['role'] == 'ADMIN'">
        <a class="btn btn-dark with-icon" (click)="openForm({})">Wijzig contactpersoon <i class="bi bi-pencil"></i></a>
        <a *ngIf="item['id'] !== null" class="btn btn-danger with-icon" (click)="remove()">Verwijderen <i class="bi bi-trash"></i></a>
    </div>
</div>