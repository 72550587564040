<div [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="modal-header">
        <h4 class="modal-title" style="font-size:16px;">Termijnstaat <ng-container *ngIf="isEditing">bewerken</ng-container><ng-container *ngIf="!isEditing">toevoegen</ng-container></h4>
        <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
    </div>
    <div class="modal-body p-1 pe-3">
        <div class="py-5 text-center" *ngIf="showSuccess == true">
            <p *ngIf="!isEditing">Termijnstaat is succesvol aangemaakt.</p>
            <p *ngIf="isEditing">Termijnstaat is succesvol bijgewerkt.</p>
        </div>

        <div class="py-2 text-center" *ngIf="showError == true">
            <p>{{errorMessage}}</p>
        </div>

        <div style="display: flex;">
            <div *ngIf="showSuccess == false" style="width: 100%;">
                <div class="row mb-1">
                    <div class="col-4 pt-2 text-end">
                        <label for="offerte" class="main-label">Opmerking</label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="from-input" class="form-control" [(ngModel)]="data['comment']">
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-4 pt-2 text-end">
                        <label for="offerte" class="main-label">Bedrag excl. BTW</label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="from-input" class="form-control" [(ngModel)]="data['amountExclVat']">
                    </div>
                </div>

                <div class="row mb-1">
                    <div class="col-4 pt-2 text-end">
                        <label for="offerte" class="main-label">Status</label>
                    </div>
                    <div class="col-8">
                        <select class="form-control" [(ngModel)]="data['status']">
                            <option value="OPEN">Open</option>
                            <option value="COST_OVERVIEW_SEND">Kostenoverzicht verstuurd</option>
                            <option value="TERM_STATEMENT_SEND">Termijnstaat verstuurd</option>
                            <option value="INVOICE_SENT">Factuur verstuurd</option>
                            <option value="PAID">Betaald</option>
                        </select>
                    </div>
                </div>

                <div class="row mb-1">
                    <div class="col-4 pt-2 text-end">
                        <label for="offerte" class="main-label">Factuurnummer</label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="from-input" class="form-control" [(ngModel)]="data['invoiceNumber']">
                    </div>
                </div>

                <div class="row mb-1">
                    <div class="col-4 pt-2 text-end">
                        <label for="offerte" class="main-label">Weeknummer</label>
                    </div>
                    <div class="col-8">
                        <input type="text" id="from-input" class="form-control" [(ngModel)]="data['weekNumber']">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer text-end" *ngIf="showSuccess == false">
        <a class="btn btn-success with-icon" (click)="create()">Opslaan <i class="bi bi-arrow-right"></i></a>
    </div>
</div>