<section *ngIf="item" [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="modal-header">
        <h5 class="modal-title">
            <span *ngIf="!item['id']">Certificaat type aanmaken</span>
            <span *ngIf="item['id']">Certificaat type wijzigen</span>
        </h5>
        <button type="button" class="btn-close" (click)="close()" tabindex="-1" aria-label="Close"></button>
    </div>
    <div class="modal-body">
        <div class="alert alert-success" *ngIf="successmessage">Wijzigingen succesvol opgeslagen.</div>
        <div class="alert alert-danger" *ngIf="errormessage">Er zijn enkel fouten gevonden.</div>
        <section class="user-form" *ngIf="item">
            <input type="hidden" *ngIf="item" class="form-control" id="id" [(ngModel)]="item['id']" name="id">

            <div class="form-group mb-3">
                <label for="name" class="pb-1">Naam</label>
                <input type="text" class="form-control" id="name" required [(ngModel)]="item['name']" name="name">
                <div class="error" *ngIf="errors['name']">{{errors['name']}}</div>
            </div>
        </section>
    </div>
    <div class="modal-footer" *ngIf="item">
        <button (click)="save()" class="btn btn-success">
            <span *ngIf="!item.id"><i class="bi bi-sd-card pe-2"></i> Toevoegen</span>
            <span *ngIf="item.id"><i class="bi bi-sd-card pe-2"></i> Opslaan</span>
        </button>
    </div>
</section>