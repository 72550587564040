<header class="page-header">
    <div class="row">
        <div class="col-6">
            <h1>Planning</h1>
        </div>
        <div class="col-6">
            <span class="btn btn-light float-end" style="background-color:#EEEEEE;" (click)="next()">
                <i class="bi bi-arrow-right"></i>
            </span>
            <span class="btn btn-light float-end" style="background-color:#EEEEEE;" (click)="prev()">
                <i class="bi bi-arrow-left"></i>
            </span>
            <a href="javascript:;" (click)="downloadPdf()"
                class="btn btn-secondary icon-right with-icon me-3 float-end printButton"><i
                    class="bi bi-printer"></i></a>
            <a href="javascript:;" (click)="downloadOldPdf()"
                class="btn btn-secondary icon-right with-icon me-3 float-end printButton"><i
                    class="bi bi-box-arrow-down"></i></a>
            <button (click)="rollbackChange()" class="btn btn-dark icon-right with-icon me-3 float-end"
                [ngClass]="{'disabled' : rollbackData == null || loading}"><i class="bi bi-arrow-counterclockwise"></i>
                Wijziging terugdraaien</button>
        </div>

    </div>
    <div class="row d-none">
        <div class="col-8">
            <input type="date" [ngModel]="ganttMonth" (ngModelChange)="fetchGanttData($event)">
        </div>
    </div>

    <div class="row">
        <div class="col-6 col-lg-8">
            <section class="filter-container">
                <span class="btn btn-filter btn-filter-add btn-white with-icon icon-right me-2" style="font-weight:600;"
                    (click)="addFilter()"><i class="bi bi-plus text-success" style="font-weight:bold;"></i>
                    Filter</span>
                <span class="btn btn-filter py-0 me-2" style="font-weight:600;" *ngFor="let filter of filters">
                    <span class="edit-filter" (click)="editFilter(filter['key'])">
                        <i class="bi bi-sliders2 text-success with-icon icon-right me-2"></i> <span>
                            {{filter['label']}}
                            <small class="text-muted">{{filter['valueText']}}</small></span>
                    </span>
                    <span class="remove-filter" (click)="removeFilter(filter['key'])"><i class="bi bi-trash"></i></span>
                </span>
            </section>
        </div>
    </div>

    <div class="printContainer">
        <div class="job-calendar-container mt-2">
            <div id="pendingJobsList" style="margin-top: 60px;">
                <div class="mt-2">
                    <ng-container *ngFor="let project of pendingProjects; index as i">
                        <div class="ganttPending">
                            <div class="ganttWrap">
                                <div class="teamIcon" [style.background-color]="project['userGroupColor']"
                                    style="color:#FFFFFF">
                                    <div class="example-handle">
                                        {{project['userGroupShortName']}}
                                    </div>
                                </div>
                                <p (click)="openForm(project['id'])"
                                    title="P{{project['number']}} - {{project['address']}}"
                                    style="white-space:nowrap;overflow:hidden;">
                                    <span
                                        *ngIf="project['isInvalidQuoteDocuments'] && project['lisStatus'] != 'NVT' && project['klicStatus'] != 'NVT'"
                                        style="padding-right:3px;padding-left:3px;" class="text-danger"
                                        title="LET OP: Mist vereiste documenten.">
                                        <i class="bi bi-exclamation-circle me-1"></i>
                                    </span>
                                    <span
                                        *ngIf="project['isInvalidPlanned'] && project['lisStatus'] != 'NVT' && project['klicStatus'] != 'NVT'"
                                        style="padding-right:3px;padding-left:3px;" class="text-danger"
                                        title="LET OP: Ingepland zonder de vereiste documenten.">
                                        <i class="bi bi-exclamation-circle me-1"></i>
                                    </span>
                                    {{project['name']}}
                                </p>
                            </div>
                            <div class="project-start-date-indicator">
                                <i class="bi bi-clock text-muted" *ngIf="project['startAt']"
                                    title="{{project['startAt']}}"></i>
                                <i class="bi bi-clock-fill text-warning" *ngIf="!project['startAt']"
                                    title="Startdatum onbekend"></i>
                            </div>
                        </div>
                        <!-- Add the margin div every 17 projects -->
                        <div *ngIf="(i + 1) % 15 === 0" class="margin-div"></div>
                    </ng-container>
                </div>
            </div>
            <div [ngClass]="{'loading' : loading}" id="calandarContainer">
                <div class="card" id="ganttContainer" *ngFor="let projectChunk of chunkedProjects">
                    <div id="ganttHeadContainer">
                        <div *ngFor="let week of ganttHeadList">
                            <p>{{week}}</p>
                        </div>
                    </div>
                    <div id="ganttDaysContainer">
                        <div *ngFor="let item of ganttDayList; let i = index"
                            [ngClass]="{'today' : ganttFourWeekDates[i] == today, 'holiday': isHoliday(ganttFourWeekDates[i])}">
                            <p>{{item}}</p>
                        </div>
                    </div>
                    <div id="ganttBodyContainer">
                        <ng-container *ngFor="let entry of projectChunk; index as i">
                            <div class="ganttWrapper" cdkDropList cdkDropListOrientation="horizontal"
                                (cdkDropListDropped)="drop($event, entry)" #dropListContainer>

                                <div *ngFor="let day of ganttFourWeekDates" class="ganttSlotPrinter" cdkDrag
                                    [cdkDragDisabled]="isManager" [cdkDragData]="entry"
                                    [ngClass]="{'dayOff' : isOffDay(day)}" title="{{ isOffDay(day) ? 'vrije dag' : ''}}"
                                    [ngStyle]="{'pointer-events': (entry.calendarStartAt != day) ? 'none' : 'all'}">
                                    <div [routerLink]="['/projects/detail/' + entry['id']]"
                                        *ngIf="entry.calendarStartAt == day" class="ganttEntry"
                                        [style.background-color]="entry['userGroupColor']"
                                        [style.width.px]="calculateGanttEntryWidthPrinter(day, entry.endAt)">
                                        <div class="teamIcon">
                                            {{entry.userGroupShortName}}
                                        </div>
                                        <p>{{entry.name}}</p>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="job-calendar-container hidePrint mt-2" cdkDropListGroup>
        <div id="pendingJobsList">
            <div cdkDropList *ngIf="!calendarSwitch" class="mt-2" (cdkDropListDropped)="dropPending($event)">
                <span style="position:relative;display:block;padding-right:80px;">
                    <input type="search" name="search" [(ngModel)]="keyword" (keyup)="getProjects()"
                        placeholder="Project zoeken" class="form-control mb-3" id="projectSearch">
                    <span style="display:block;position:absolute;top:0;right:0;">
                        <button class="btn btn-secondary dd-sort" (click)="toggleDropdown()"><i
                                class="bi bi-sort-alpha-down" style="pointer-events: none;"></i></button>
                        <ul class="dropdown-menu" [ngClass]="{
                    'show': showDropDown == true
                    }" style="margin-top:0;margin-left:0;">
                            <li class="hide-on-modal"><a href="javascript:;" (click)="changeSort('position','ASC')"
                                    class="dd-sort dropdown-item">Positie oplopend</a></li>
                            <li class="hide-on-modal"><a href="javascript:;" (click)="changeSort('startAt','ASC')"
                                    class="dd-sort dropdown-item">Datum oplopend</a></li>
                            <li class="hide-on-modal"><a href="javascript:;" (click)="changeSort('startAt','DESC')"
                                    class="dd-sort dropdown-item">Datum aflopend</a></li>
                            <li class="hide-on-modal"><a href="javascript:;"
                                    (click)="changeSort('siteManagerName','ASC')"
                                    class="dd-sort dropdown-item">Uitvoerder oplopend</a></li>
                            <li class="hide-on-modal"><a href="javascript:;" (click)="changeSort('userGroupName','ASC')"
                                    class="dd-sort dropdown-item">Ploeg oplopend</a></li>
                        </ul>
                    </span>
                </span>
                <div class="ganttPending" cdkDrag *ngFor="let project of pendingProjects" [cdkDragData]="project">
                    <div class="ganttWrap">
                        <div class="teamIcon" [style.background-color]="project['userGroupColor']"
                            style="color:#FFFFFF">
                            <div class="example-handle">
                                {{project['userGroupShortName']}}
                            </div>
                        </div>
                        <p (click)="openForm(project['id'])" title="P{{project['number']}} - {{project['address']}}"
                            style="white-space:nowrap;overflow:hidden;">
                            <span
                                *ngIf="project['isInvalidQuoteDocuments'] && project['lisStatus'] != 'NVT' && project['klicStatus'] != 'NVT'"
                                style="padding-right:3px;padding-left:3px;" class="text-danger"
                                title="LET OP: Mist vereiste documenten."><i
                                    class="bi bi-exclamation-circle me-1"></i></span>
                            <span
                                *ngIf="project['isInvalidPlanned'] && project['lisStatus'] != 'NVT' && project['klicStatus'] != 'NVT'"
                                style="padding-right:3px;padding-left:3px;" class="text-danger"
                                title="LET OP: Ingepland zonder de vereiste documenten."><i
                                    class="bi bi-exclamation-circle me-1"></i></span>{{project['name']}}
                        </p>
                    </div>
                    <div class="project-start-date-indicator">
                        <i class="bi bi-clock text-muted" *ngIf="project['startAt']" title="{{project['startAt']}}"></i>
                        <i class="bi bi-clock-fill text-warning" *ngIf="!project['startAt']"
                            title="Startdatum onbekend"></i>
                    </div>
                </div>
            </div>
        </div>
        <div [ngClass]="{'loading' : loading}" id="calandarContainer">
            <div class="card" id="ganttContainer">
                <div id="ganttHeadContainer" class="sticky">
                    <div style="display: flex; flex-direction: column; align-items: center;" *ngFor="let week of ganttHeadList; index as i">
                        <p>{{week}}</p>
                        <small style="font-size: 12px;">(week {{ganttWeekDayList[i] - 1}})</small>
                    </div>
                    
                </div>
                <div id="ganttDaysContainer">
                    <div *ngFor="let item of ganttDayList; let i = index"
                        [ngClass]="{'today' : ganttFourWeekDates[i] == today, 'holiday': isHoliday(ganttFourWeekDates[i])}">
                        <p>{{item}}</p>
                    </div>
                </div>
                <div id="ganttBodyContainer">
                    <div *ngFor="let entry of projects" class="ganttWrapper" cdkDropList
                        cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event, entry)"
                        #dropListContainer>
                        <div *ngFor="let day of ganttFourWeekDates" class="ganttSlot" cdkDrag
                            [cdkDragDisabled]="isManager" [cdkDragData]="entry" [ngClass]="{'dayOff' : isOffDay(day)}"
                            title="{{ isOffDay(day) ? 'vrije dag' : ''}}"
                            [ngStyle]="{'pointer-events': (entry.calendarStartAt != day) ? 'none' : 'all'}">
                            <div [routerLink]="['/projects/detail/' + entry['id']]" *ngIf="entry.calendarStartAt == day"
                                class="ganttEntry" [style.background-color]="entry['userGroupColor']"
                                [style.width.px]="calculateGanttEntryWidth(day, entry.endAt)">
                                <div class="teamIcon">
                                    {{entry.userGroupShortName}}
                                </div>
                                <p>{{entry.name}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>