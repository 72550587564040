<section class="p-5" *ngIf="journalItems && journalItems.length < 1">
    <p class="text-center text-muted">Geen dagboek beschikbaar</p>
    <p class="text-center">
        <button type="button" class="btn btn-success" (click)="addWeek()">
            <i class="bi bi-plus-lg text-white me-2"></i> Week toevoegen
        </button>
    </p>
</section>
<section class="p-5" *ngIf="!journalItems">
    laden
</section>
<section class="table-grid" *ngIf="journalItems && journalItems.length > 0">
    <section class="table-content">
        <table class="table table-vertical-align-top" style="margin-bottom:0;">
            <thead class="thead-dark">
                <tr>
                    <th width="140">Week</th>
                    <th width="180">Datum</th>
                    <th>Omschrijving</th>
                    <th width="140"></th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let journal of journalItems">
                    <tr class="hour-row">
                        <td>
                            <i (click)="setExpended('')" class="bi bi-chevron-up icon-pointer me-3 pt-1"
                                *ngIf="expendedKey == journal['period']"></i>
                            <i (click)="setExpended(journal['period'])"
                                class="bi bi-chevron-down icon-pointer me-3 pt-1"
                                *ngIf="expendedKey !== journal['period']"></i>
                            <b>Week {{journal['week']}}</b>
                            <span class="text-danger" *ngIf="journal['hasComment']"> <i
                                    class="d-inline-block ms-1 bi bi-exclamation-diamond-fill"></i></span>
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                            <a href="https://api.kloens.app/projectJournals/pdf/{{project['id']}}/{{journal['period']}}&hash={{currentUser['publicHash']}}"
                                target="_blank" class="d-inline-block me-3"><i class="bi bi-file-earmark-pdf"></i></a>
                            <span (click)="confirmDeleteWeek(project, journal['period'])"
                                class="me-3 row-icon delete2-icon">
                                <i class="bi bi-trash-fill"></i>
                            </span>
                            <span (click)="addWeek()">
                                <i class="bi bi-plus-lg add2-icon row-icon"></i>
                            </span>
                        </td>
                    </tr>
                    <tr *ngFor="let journalItem of journal['items']; let index = index; let last = last;"
                        class="user-hour-row"
                        [ngClass]="{'d-none': expendedKey !== journalItem['period'],  last: last}">
                        <td>
                            <i (click)="toggleItem(journalItem)" class="bi icon-pointer me-3 pt-1"
                                [ngClass]="{'bi-chevron-up': journalItem.expanded, 'bi-chevron-down': !journalItem.expanded}"></i>
                        </td>
                        <td>
                            {{journalItem['dayText']}}
                            <br /><small style="display:inline-block;font-size:80%;">{{journalItem['dateText']}}</small>
                        </td>
                        <td *ngIf="journalItem['expanded']">
                            <textarea [(ngModel)]="journalItem['description']"
                                placeholder="Omschrijving van de werkzaamheden" #elem
                                [style.height.px]="elem.scrollHeight" (blur)="updateJournal(journalItem)"
                                class="form-control" style="width:100%;">{{journalItem['description']}}</textarea>
                            <div class="pt-2">
                                <div class="py-1 comment-label" [ngClass]="{
                                'comment-label-highlight': journalItem['comments']
                            }"><i class="bi bi-exclamation-octagon"></i>&nbsp;&nbsp;&nbsp;&nbsp;Opmerkingen (meerwerk,
                                    uitzonderingen etc ... )</div>
                                <textarea [ngClass]="{
                                'comment-textarea-highlight': journalItem['comments']
                            }" [(ngModel)]="journalItem['comments']"
                                    placeholder="Omschrijf de uitzonderlijke werkzaamheden"
                                    (blur)="updateJournal(journalItem)" class="comment-textarea form-control" #elem2
                                    [style.height.px]="elem2.scrollHeight"
                                    style="overflow:hidden">{{journalItem['comments']}}</textarea>
                                <ng-container *ngIf="project.isBrl">
                                    <div class="py-1 comment-label" [ngClass]="{
                                        'comment-label-highlight': journalItem['comments']
                                    }"><i class="bi bi-exclamation-octagon"></i>&nbsp;&nbsp;&nbsp;&nbsp;BRL gegevens
                                    </div>
                                    <table>
                                        <!-- <tr>
                                        <th>Productie m/2 per dag</th>
                                        <th>Mechanische vorm</th>
                                        <th>Voldoet aan norm</th>
                                    </tr> -->
                                        <tr>
                                            <td style="vertical-align: inherit;">Productie m/2 per dag</td>
                                            <td><input type="number" id="name" required=""
                                                    (blur)="updateJournal(journalItem)"
                                                    [(ngModel)]="journalItem['brlProductionPerDayM2']"
                                                    class="form-control ng-pristine"></td>
                                        </tr>
                                        <tr>
                                            <td style="vertical-align: inherit;">Mechanische vorm</td>
                                            <td><select (blur)="updateJournal(journalItem)"
                                                    [(ngModel)]="journalItem['brlMechanicalShape']"
                                                    name="brlMechanicalShape" class="form-control">
                                                    <option value="false">Nee</option>
                                                    <option value="true">
                                                        Ja
                                                    </option>
                                                </select></td>
                                        </tr>
                                        <tr>
                                            <td style="vertical-align: inherit;">Voldoet aan norm</td>
                                            <td><select (blur)="updateJournal(journalItem)"
                                                    [(ngModel)]="journalItem['brlMeetsStandards']" name="meetsStandards"
                                                    class="form-control">
                                                    <option value="false">Nee</option>
                                                    <option value="true">
                                                        Ja
                                                    </option>
                                                </select></td>
                                        </tr>
                                    </table>
                                </ng-container>
                                <div class="mt-2">
                                    <section class="table-grid">
                                        <section class="table-content">
                                            <table class="table table-vertical-align-top measurementTable"
                                                style="margin-bottom:0;">
                                                <thead class="thead-dark">
                                                    <tr>
                                                        <th width="150">Profiel</th>
                                                        <th width="100">Lengte K</th>
                                                        <th width="100">Lengte T</th>
                                                        <th width="100">Lengte A</th>
                                                        <th width="100">Lengte G</th>
                                                        <th width="200">Grondwerk kraan/hand</th>
                                                        <th width="100">Diepte</th>
                                                        <th width="100">Kabels</th>
                                                        <th width="100">BOB</th>
                                                        <th width="100">Huisaansluiting</th>
                                                        <th width="100"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngIf="project.canManageMeasurements">
                                                        <td>
                                                            <input (keyup.enter)="focusOnElem(2, journalItem.id)" id="measurement-{{journalItem.id}}-1" type="text" class="large"
                                                                [(ngModel)]="measurementData.profile">
                                                        </td>
                                                        <td>
                                                            <input (keyup.enter)="focusOnElem(3, journalItem.id)" id="measurement-{{journalItem.id}}-2" type="text" [(ngModel)]="measurementData.kLength">
                                                        </td>
                                                        <td>
                                                            <input (keyup.enter)="focusOnElem(4, journalItem.id)" id="measurement-{{journalItem.id}}-3" type="text" [(ngModel)]="measurementData.tLength">
                                                        </td>
                                                        <td>
                                                            <input (keyup.enter)="focusOnElem(5, journalItem.id)" id="measurement-{{journalItem.id}}-4" type="text" [(ngModel)]="measurementData.aLength">
                                                        </td>
                                                        <td>
                                                            <input (keyup.enter)="focusOnElem(6, journalItem.id)" id="measurement-{{journalItem.id}}-5" type="text" [(ngModel)]="measurementData.gLength">
                                                        </td>
                                                        <td>
                                                            <select (change)="focusOnElem(7, journalItem.id)" id="measurement-{{journalItem.id}}-6" class="form-control"
                                                                [(ngModel)]="measurementData.groundwork">
                                                                <option>Selecteer optie</option>
                                                                <option value="CRANE">100% kraan</option>
                                                                <option value="MANUAL">50% kraan / 50% hand</option>
                                                                <option value="BOTH">100% hand</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input (keyup.enter)="focusOnElem(8, journalItem.id)" id="measurement-{{journalItem.id}}-7" type="text" [(ngModel)]="measurementData.depth">
                                                        </td>
                                                        <td>
                                                            <input (keyup.enter)="focusOnElem(9, journalItem.id)" id="measurement-{{journalItem.id}}-8" type="text" [(ngModel)]="measurementData.cableCount">
                                                        </td>
                                                        <td>
                                                            <input (keyup.enter)="focusOnElem(10, journalItem.id)" id="measurement-{{journalItem.id}}-9" type="text" [(ngModel)]="measurementData.bobCount">
                                                        </td>
                                                        <td>
                                                            <input (keyup.enter)="addMeasurement(journalItem['id'])" id="measurement-{{journalItem.id}}-10" type="text"
                                                                [(ngModel)]="measurementData.houseConnection">
                                                        </td>
                                                        <td>
                                                            <span [class.disabledBtn]="measurementData.profile == ''" (click)="addMeasurement(journalItem['id'])"
                                                                class="me-3">
                                                                <i class="bi bi-plus-lg add2-icon row-icon"></i>
                                                            </span>
                                                        </td>
                                                    </tr>

                                                    <tr
                                                        *ngFor="let item of getJournalMeasurements(journalItem['id']); let index = index; let last = last;">
                                                        <td>
                                                            <input [disabled]="project.canManageMeasurements == false"
                                                                (blur)="updateMeasurement(item)" type="text"
                                                                class="large" [(ngModel)]="item.profile">
                                                        </td>
                                                        <td>
                                                            <input [disabled]="project.canManageMeasurements == false"
                                                                (blur)="updateMeasurement(item)" type="text"
                                                                [(ngModel)]="item.kLength">
                                                        </td>
                                                        <td>
                                                            <input [disabled]="project.canManageMeasurements == false"
                                                                (blur)="updateMeasurement(item)" type="text"
                                                                [(ngModel)]="item.tLength">
                                                        </td>
                                                        <td>
                                                            <input [disabled]="project.canManageMeasurements == false"
                                                                (blur)="updateMeasurement(item)" type="text"
                                                                [(ngModel)]="item.aLength">
                                                        </td>
                                                        <td>
                                                            <input [disabled]="project.canManageMeasurements == false"
                                                                (blur)="updateMeasurement(item)" type="text"
                                                                [(ngModel)]="item.gLength">
                                                        </td>
                                                        <td>
                                                            <select [disabled]="project.canManageMeasurements == false"
                                                                (blur)="updateMeasurement(item)" class="form-control"
                                                                [(ngModel)]="item.groundwork">
                                                                <option>Selecteer optie</option>
                                                                <option value="CRANE">100% kraan</option>
                                                                <option value="MANUAL">50% kraan / 50% hand</option>
                                                                <option value="BOTH">100% hand</option>
                                                            </select>
                                                        </td>
                                                        <td>
                                                            <input [disabled]="project.canManageMeasurements == false"
                                                                (blur)="updateMeasurement(item)" type="text"
                                                                [(ngModel)]="item.depth">
                                                        </td>
                                                        <td>
                                                            <input [disabled]="project.canManageMeasurements == false"
                                                                (blur)="updateMeasurement(item)" type="text"
                                                                [(ngModel)]="item.cableCount">
                                                        </td>
                                                        <td>
                                                            <input [disabled]="project.canManageMeasurements == false"
                                                                (blur)="updateMeasurement(item)" type="text"
                                                                [(ngModel)]="item.bobCount">
                                                        </td>
                                                        <td>
                                                            <input [disabled]="project.canManageMeasurements == false"
                                                                (blur)="updateMeasurement(item)" type="text"
                                                                [(ngModel)]="item.houseConnection">
                                                        </td>
                                                        <td>
                                                            <span (click)="confirmDelete(item['id'])"
                                                                *ngIf="currentUser['role'] !== 'USER' && project.canManageMeasurements"
                                                                class="me-3 row-icon delete2-icon">
                                                                <i class="bi bi-trash-fill"></i>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </section>
                                    </section>
                                </div>
                                <div class="mt-2">
                                    <div class="d-none">
                                        <ngx-dropzone #drop (change)="onSelect($event, journalItem['id'])"
                                            class="dropzone"></ngx-dropzone>
                                    </div>
                                    <button (click)="drop.showFileSelector()" class="btn btn-dark mt-3 mb-4">
                                        <i class="bi bi-file-earmark-arrow-up me-1"></i><span>Upload foto's</span>
                                    </button>
                                </div>
                            </div>
                        </td>
                        <td></td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </section>
</section>