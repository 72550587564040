import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-projects-pdf',
  templateUrl: './projects-pdf.component.html',
  styleUrls: ['./projects-pdf.component.scss']
})
export class ProjectsPdfComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
