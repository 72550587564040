<header class="page-header">
    <div class="row">
        <div class="col-8">
            <h1>Instellingen</h1>
        </div>
    </div>
</header>

<section>
    <div class="row">
        <div class="col-4">
            <section class="card">
                <div class="card-body">
                    <h5 class="card-title">Project instellingen</h5>
                    <a routerLink="/settings/calculation-templates" class="d-block"
                        style="text-decoration:none;margin-top:30px;color:#000000">
                        <span class="d-block" style="font-size:120%;">Calculatie templates</span>
                        <small class="text-muted">Beheer de prijzen en onderdelen van meerdere templates.</small>
                    </a>
                </div>
                <div class="card-body clickable">
                    <a (click)="openCategories()" class="d-block"
                        style="text-decoration:none;margin-top:30px;color:#000000">
                        <span class="d-block" style="font-size:120%;">Categorieën beheren</span>
                        <small class="text-muted">Categorieën aanmaken of verwijderen.</small>
                    </a>
                </div>
                <div class="card-body clickable">
                    <a (click)="openCertificateTypes()" class="d-block"
                        style="text-decoration:none;margin-top:30px;color:#000000">
                        <span class="d-block" style="font-size:120%;">Certificaat types</span>
                        <small class="text-muted">Certificaat types aanmaken of verwijderen.</small>
                    </a>
                </div>
            </section>
        </div>
        <div class="col-4">
            <section class="card">
                <div class="card-body">
                    <h5 class="card-title">Systeem instellingen</h5>
                    <a routerLink="/settings/special-dates" class="d-block"
                        style="text-decoration:none;margin-top:30px;color:#000000">
                        <span class="d-block" style="font-size:120%;">Feestdagen instellingen</span>
                        <small class="text-muted">Beheer de feest- en vrije dagen.</small>
                    </a>
                </div>
            </section>
        </div>
        <div class="col-4">
            <section class="card">
                <div class="card-body">
                    <h5 class="card-title">Bestandsviewer</h5>
                    <a routerLink="/bestandsviewer" class="d-block"
                        style="text-decoration:none;margin-top:30px;color:#000000">
                        <span class="d-block" style="font-size:120%;">Kloens bestandsviewer</span>
                        <small class="text-muted">Uitleg en download.</small>
                    </a>
                </div>
            </section>
        </div>
    </div>
</section>