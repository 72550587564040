<div class="modal-header">
	<h4 class="modal-title" id="modal-basic-title">Jaren selecteren</h4>
	<button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
</div>
<div class="modal-body" style="max-height: 500px; overflow-y: scroll;">
	<ng-container *ngFor="let year of yearSelectOptions">
		<div class="row">
			<div class="col-12 option-row" [ngClass]="selectedYears[year] ? 'active' : ''"
				[ngClass]="{'inactive' : selectedYears[year] == false}"
				style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;">
				<div (click)="toggleYear(year)">
					<i *ngIf="selectedYears.includes(year)" class="bi bi-check-square"
						style="position:relative;top:2px;margin-right:5px;"></i>
					<i *ngIf="!selectedYears.includes(year)" class="bi bi-square"
						style="position:relative;top:2px;margin-right:5px;opacity:0.5;"></i>
					{{year}}
				</div>
			</div>
		</div>
	</ng-container>
</div>
<div class="modal-footer">
	<button (click)="cancel()" type="submit" class="btn btn btn-link"
		style="text-decoration:none ! important;color:#000000;">Annuleren</button>
	<button (click)="confirm()" type="submit" class="btn btn-success" id="delete-btn">Opslaan</button>
</div>