<div class="modal-header">
	<h5 class="modal-title">Filter: <span *ngIf="activeFilterOption">{{activeFilterOption ['label']}}</span></h5>
    <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
</div>
<div class="modal-body">
    <div *ngIf="activeFilterOption['type'] === 'STRING'">
        <div class="row">
            <div class="col-12">
                <input class="form-control" type="text" name="keyword" placeholder="Zoekterm" [(ngModel)]="keyword" />
            </div>
        </div>
    </div>
    <div *ngIf="activeFilterOption['type'] === 'SELECT'">
        <div class="row">
            <div class="col-12" style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:15px;">
                <small>Selecteer een filter uit de onderstaande opties</small>
            </div>
        </div>
        <div class="row" *ngFor="let option of activeFilterOption.options">
            <div class="col-12 option-row" [ngClass]="selectedItems[option['key']] ? 'active' : ''"  style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;">
                <div (click)="toggleList(option['key'])">
                    <i *ngIf="selectedItems[option['key']]" class="bi bi-check" style="position:relative;top:1px;"></i> {{option['value']}}
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="activeFilterOption['type'] === 'DATERANGE'">
        <div class="row">
            <div class="col-12" style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:15px;">
                <small>Vul een datumbereik in</small>
            </div>
        </div>
        <div class="row mb-3 mt-1">
            <div class="col-4 pt-2">
                Startdatum
            </div>
            <div class="col-7">
                <input class="form-control" type="date" [(ngModel)]="startDate" />
            </div>
        </div>
        <div class="row">
            <div class="col-4 pt-2">
                Einddatum
            </div>
            <div class="col-7">
                <input class="form-control" type="date" [(ngModel)]="endDate" />
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
	<button *ngIf="activeFilter" (click)="remove(activeFilterOption ['key'])" type="button" tabindex="-1" style="text-decoration: none ! important;" class="btn btn btn-link text-danger">Verwijderen</button>
	<button (click)="confirm()" type="button" tabindex="-1" class="btn btn-primary">Toepassen</button>
</div>
