import { Component, OnInit, Renderer2 } from '@angular/core';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';

import { AuthenticationService } from 'src/app/services/core/authentication.service';
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";

import { FilePreviewComponent } from 'src/app/modals/file-preview/file-preview.component';
import { DOCUMENT } from "@angular/common";
import { Inject } from "@angular/core";

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit {

  currentUser;

  files = null;

  constructor(
    private api: ApiConnectionService,
    private appService: AppService,
    public renderer: Renderer2,
    private authenticationService: AuthenticationService,
    private modalService: NgbModal,
    @Inject(DOCUMENT) private document: Document,
  ) {

    let self = this;

    self.authenticationService.getCurrentUser();
    self.authenticationService.data.subscribe((data) => {
      if (Object.keys(data).length) {
        self.currentUser = data;
      } else {
        self.currentUser = false;
      }
    });

    this.api.getPublicHash();
  }

  ngOnInit(): void {
    this.appService.setPageTitle('Algemene bestanden');
    this.appService.setNavKey('files');
    this.getFiles();
  }

  getFiles() {
    let self = this;
    this.api
      .read("managerFiles&checkFileType=1")
      .then((response: any) => {
        self.files = Object.values(response);
        self.files.sort((a, b) => {
          if (a.type === 'directory' && b.type !== 'directory') {
            return -1; // a comes first
          }
          if (a.type !== 'directory' && b.type === 'directory') {
            return 1;  // b comes first
          }
          return 0;   // no change in order
        });
      });
  }

  addHash(url) {
    return url + "&hash=" + this.currentUser['publicHash'];
  }

  toggleDirectory(file, index) {
    if (file.expanded) {
      // Collapse the directory if already expanded
      file.expanded = false;
      return;
    }

    // If not already loading or expanded, load the children
    if (!file.files || file.files.length === 0) {
      this.openDirectory(file, index);
    } else {
      // Simply expand the directory if children already loaded
      file.expanded = true;
    }
  }

  openDirectory(file, index) {
    let path = file.full_path;
    file.loading = true;

    path = path.replaceAll(" ", "+");

    this.api.read("managerFiles&checkFileType=1&path=" + path)
      .then((response: any) => {
        file.files = Object.values(response);  // Load child files
        
        file.loading = false;
        file.expanded = true;  // Expand after loading

        file.files.sort((a, b) => {
          if (a.type === 'directory' && b.type !== 'directory') {
            return -1; // a comes first
          }
          if (a.type !== 'directory' && b.type === 'directory') {
            return 1;  // b comes first
          }
          return 0;   // no change in order
        });
      })
      .catch(() => {
        file.loading = false;
      });
  }

  getFileExtension(filename) {
    let extension = filename.split('.').pop();
    if(extension == "jpeg") {
      extension = "jpg";
    }

    return "bi-filetype-" + extension;
  }

  showPreview(file) {
    // let extension = file.name.split('.').pop();
    // const imgExtensions = ['png', 'jpg', 'jpeg', 'svg'];

    // let link =
    //   '<iframe src="https://api.kloens.app/managerFiles/get&filename='
    //   file["full_path"] +
    //   '&hash=' + this.currentUser['publicHash'] + '" style="width:100%;height:600px;"></iframe>';
    // if (imgExtensions.includes(extension)) {
    //   link =
    //     '<img src="https://api.kloens.app/managerFiles/get&filename='
    //   file["full_path"] +
    //   '&hash=' + this.currentUser['publicHash'] + '" style="height:100%;">';
    // }c

    const modal = this.modalService.open(FilePreviewComponent, {
      size: "xl",
      backdropClass: 'second-modal-backdrop',
      windowClass: 'second-modal',
    });

    modal.componentInstance.mobile = false;
    modal.componentInstance.file = file;
    modal.componentInstance.fileList = [];
    modal.result.then((result) => { }).catch((error) => { this.document.body.classList.remove('fullscreen-modals'); });
  }
}
