import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserGroupsFormComponent } from '../user-groups-form/user-groups-form.component';
import { AppService } from 'src/app/services/app.service';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { ModalConfirmDeleteComponent } from 'src/app/modals/modal-confirm-delete/modal-confirm-delete.component';

@Component({
  selector: 'app-user-groups-index',
  templateUrl: './user-groups-index.component.html',
  styleUrls: ['./user-groups-index.component.scss']
})
export class UserGroupsIndexComponent implements OnInit {
  items = [];
  successmessage;
  errormessage;
  addedmessage;
  deletemessage;

  constructor(
    private modalService: NgbModal,
    private activeModal: NgbActiveModal,
    private api: ApiConnectionService,
	  private appService: AppService,
  ) { }

  ngOnInit(): void {
	  this.appService.setPageTitle('Ploegen');
	  this.appService.setNavKey('users');
    this.resetMessages();
    this.fetchItems();
  }

  resetMessages ()
  {
    this.successmessage = false;
    this.errormessage = false;
    this.addedmessage = false;
    this.deletemessage = false;
  }

  fetchItems ()
  {
		let self = this;
		this.api.read('userGroups&data[sort]=name').then((response) => {		
			self.items = response['items'];
		});
  }

  edit(item) {
	  this.openForm(item['id']);
  }

	delete (object) {
	  let self = this;
	  this.api.delete('userGroups/delete/'+object.id+'').then((response) => {
		  self.fetchItems();
      self.resetMessages();
      self.deletemessage = true;
	  }).catch (function (error) {
	  });	
	}
	
	confirmDelete(object) {
		let self = this;
		
	  const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
	  deleteModal.componentInstance.message = 'Weet je zeker dat je '+object.name+' wilt verwijderen?';
	  deleteModal.result.then((result) => {
		  if (result) {
			  this.delete(object);
		  }
	  });
	}
	

  openForm (id)
  {
    let self = this;
    const filterModal = this.modalService.open(UserGroupsFormComponent, {
      windowClass: 'modal-right',
      size: 'lg'
    });
	  filterModal.componentInstance.id = id;
    filterModal.result.then((result) => {
      self.resetMessages();
      
      if (result['type'] == 'added')
      {
        self.addedmessage = true;
      }
      if (result['type'] == 'edited')
      {
        self.successmessage = true;
      }
      // if (result && result.length > 0) {
        self.fetchItems();
      // }
    })
    .catch((error) => {
    });
  }

	close() {
		this.activeModal.close(false);
	}

	cancel() {
		this.activeModal.close(false);
	}
  
	dismiss () {
		this.activeModal.close(false);
	}
}
