<div [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="modal-header">
        <h4 class="modal-title" style="font-size:16px;">Verstuur mail P{{project['number']}}</h4>
        <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
    </div>
    <div class="modal-body p-1 pe-3" *ngIf="data">
        <div class="py-5 text-center" *ngIf="showSuccess == true">
            <p>Termijnstaat is succesvol verstuurd.</p>
        </div>

        <div style="display: flex;">
            <div *ngIf="showSuccess == false" style="width: 50%;">
                <div class="row mt-2 mb-1">
                    <div class="col-2 pt-2 text-end">
                        <label for="offerte" class="main-label">Van</label>
                    </div>
                    <div class="col-10">
                        <select class="form-control" [(ngModel)]="from" (change)="setAuthorName()">
                            <option value="{{currentUser['email']}}">{{currentUser['email']}}</option>
                            <option value="werkvoorbereiding@kloens.nl">werkvoorbereiding@kloens.nl</option>
                            <option value="{{project['authorEmail']}}">{{project['authorEmail']}}</option>
                        </select>
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-2 pt-2 text-end">
                        <label for="offerte" class="main-label">Naam verzender</label>
                    </div>
                    <div class="col-10">
                        <input type="text" id="from-input" class="form-control" [(ngModel)]="data['fromName']">
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-2 pt-2 text-end">
                        <label for="offerte" class="main-label">Aan</label>
                    </div>
                    <div class="col-10">        
                        <div style="display: flex; margin-bottom: 5px;">
                            <p class="tag" *ngFor="let email of receivers">
                                {{email}} <i class="bi bi-x-circle-fill" style="cursor: pointer;"
                                    (click)="removeEmail(email)"></i>
                            </p>
                            <p *ngIf="receivers.length == 0" class="tag disabled">Selecteer ontvanger(s)</p>
                        </div>
                        <select2 [data]="to" [displaySearchStatus]="'always'" (update)="updateReceiver($event)" [value]="value"> </select2>  
                    </div>
                </div>

                <div class="row mb-1">
                    <div class="col-2 pt-2 text-end">
                        <label for="offerte" class="main-label">CC</label>
                    </div>
                    <div class="col-10">
                        <div style="display: flex; margin-bottom: 5px;">
                            <p class="tag" *ngFor="let email of cc">
                                {{email}} <i class="bi bi-x-circle-fill" style="cursor: pointer;"
                                    (click)="removeCC(email)"></i>
                            </p>
                            <p *ngIf="cc.length == 0" class="tag disabled">Selecteer ontvanger(s)</p>
                        </div>
                        <select2 [data]="to" [displaySearchStatus]="'always'" (update)="updateCC($event)" [value]="value"> </select2>  
                    </div>
                </div>

                <div class="row mb-1">
                    <div class="col-2 pt-2 text-end">
                        <label for="offerte" class="main-label">BCC</label>
                    </div>
                    <div class="col-10">
                        <div style="display: flex; margin-bottom: 5px;">
                            <p class="tag" *ngFor="let email of bcc">
                                {{email}} <i class="bi bi-x-circle-fill" style="cursor: pointer;"
                                    (click)="removeBCC(email)"></i>
                            </p>
                            <p *ngIf="bcc.length == 0" class="tag disabled">Selecteer ontvanger(s)</p>
                        </div>
                        <select2 [data]="to" [displaySearchStatus]="'always'" (update)="updateBCC($event)" [value]="value"> </select2>  
                    </div>
                </div>
                <div class="row mb-1">
                    <div class="col-2 pt-2 text-end">
                        <label for="offerte" class="main-label">Onderwerp</label>
                    </div>
                    <div class="col-10">
                        <input type="text" class="form-control" [(ngModel)]="data['subject']">
                    </div>
                </div>
                <div class="row mt-2 mb-1">
                    <div class="col-2 pt-2 text-end">
                        <label for="offerte" class="main-label">Bericht</label>
                    </div>
                    <div class="col-10">
                        <textarea class="form-control" style="height:300px;" [(ngModel)]="data['body']"></textarea>
                    </div>
                </div>
            </div>
            <div id="fileContainer" *ngIf="showSuccess == false">
                <h4 style="font-size: 14px; display: flex; justify-content: space-between; width: 100%;">Selecteer
                    e-mail bijlagen</h4>

                <div [style.order]="categoryPositions[cat.key]" *ngFor="let cat of filteredProjectFiles | keyvalue">
                    <p style="margin: 0;     margin: 0;
                    font-weight: bold; 
                    font-size: 16px;
                    background: #ddd;
                    padding: 5px;">{{cat.key}} <svg [ngClass]="this.showCategories[cat.key] ? 'rotate' : '' "
                            (click)="toggle(cat.key)" xmlns="http://www.w3.org/2000/svg" width="17" height="17"
                            viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round" class="ai ai-ChevronDown"
                            style="cursor: pointer;">
                            <path d="M4 9l8 8 8-8" />
                        </svg></p>

                    <ng-container *ngIf="this.showCategories[cat.key]">
                        <div *ngFor="let file of cat.value">
                            <label style="margin-left: 5px; margin-top: 5px; margin-bottom: 5px;"><input type="checkbox" style="margin-right: 5px;"
                                    (change)="changeFiles(file.id)" [value]="file.id">{{file.name}} - {{file.sizeMb}}</label>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer text-end" *ngIf="showSuccess == false">
        <a class="btn btn-success with-icon" (click)="send()">Verstuur <i class="bi bi-arrow-right"></i></a>
    </div>
</div>