import { Component, Renderer2, Inject } from '@angular/core';
import { AuthenticationService } from './services/core/authentication.service';
import { ConfigService } from './services/core/config.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ApiConnectionService } from './services/core/api-connection.service';
import { UsersService } from './services/users.service';
import { TitleTagService } from './services/core/title-tag.service';
import { AppService } from './services/app.service';
import { Meta, Title } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { version } from 'src/environments/version';

import { DOCUMENT } from '@angular/common';
import { UserCategoryComponent } from './components/users/user-category/user-category.component';
import { ModalSelectYearComponent } from './modals/modal-select-year/modal-select-year.component';

import { FileOpenerService, FileOpenerStatus } from './services/file-opener.service';
import { ModalContentComponent } from './modals/modal-content/modal-content.component';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = '';
	navKey = 'dashboard';
	pageTitle = 'Dashboard';
	showModal: boolean;
	submitted = false;
	profileDropdown = false;

	SYSTEM_NAME = this.configService.get('title');
	urlParameters = {};
	checkLogin = true;
	loaderTimeout;
	public loader = false;

	systemMessageText = '';
	systemMessageLoader = false;
	systemMessageType = 'success';
	systemMessageTimeout;

	warningMessage = '';
	warningTimeout;
	globalTimeout = null;

	managerDDVisible = false;
	managerDDKeyword = "";
	searchResult = [];

	fileOpenerStatus: FileOpenerStatus = FileOpenerStatus.CONNECTING;

	mobileMenuVisible: boolean = false;

	darkMode: boolean = false;

	public currentUser: any;

	public applicationVersion = "";

	activeYears: any = [];

	constructor(
		private fileOpenerService: FileOpenerService,
		private documentTitle: Title,
		private documentMeta: Meta,
		private modalService: NgbModal,
		public api: ApiConnectionService,
		public configService: ConfigService,
		public usersService: UsersService,
		private router: Router,
		private appService: AppService,
		private authenticationService: AuthenticationService,
		private ngxLoader: NgxUiLoaderService,
		public renderer: Renderer2,
		private titleTagService: TitleTagService,
		@Inject(DOCUMENT) private document: Document
	) {
		let self = this;

		self.checkHashArguments().then(function () {
			self.setMetaTags();
		});

		this.fileOpenerService.status$.subscribe(status => {
			this.fileOpenerStatus = status;
		});

		this.fileOpenerService.setupWebsocket();

		self.applicationVersion = version;

		this.appService.setDefaultTitle(configService.get('page_title'));
		this.appService.setDefaultDescription(configService.get('page_description'));
		this.setPageDataListener();

		this.authenticationService.getCurrentUser().then(function (data) {
			self.checkLogin = false;

			self.authenticationService.data.subscribe(data => {
				if (Object.keys(data).length) {
					self.currentUser = data;
				} else {
					self.currentUser = false;
				}

				if (self.activeYears.length == 0 && data['id'] !== undefined) {
					self.getActiveYears(data['id']);
				}
			});

			self.authenticationService.authState.subscribe(data => {
				if (data === false) {
					// self.router.navigate(['login']);
				}
			});

		}).catch(function (data) {
			// self.router.navigate(['login']);
			self.authenticationService.data.subscribe(data => {
				if (Object.keys(data).length) {
					self.currentUser = data;
				} else {
					self.currentUser = false;
				}
			});

			self.authenticationService.authState.subscribe(data => {
				if (data === false) {
					// self.router.navigate(['login']);
				}
			});
		});
		this.renderer.listen('window', 'click', (evt) => {
			if (!evt.target.classList.contains('profile-button')) {
				self.profileDropdown = false;
			}

			if (this.managerDDVisible) {
				this.managerDDVisible = false;
			}
		});
	}

	getActiveYears(userId) {
		this.api.read("users/activeYears/" + userId).then((data) => {
			if (data !== null) {
				this.activeYears = data;
			}
		})
	}

	openSelectYears() {
		const self = this;

		const yearModal = this.modalService.open(ModalSelectYearComponent, {
			backdrop: 'static',
			size: 'sm'
		});

		yearModal.componentInstance.selectedYears = this.activeYears;

		yearModal.result.then((result) => {
			if (result) {

				let userData = self.currentUser;
				userData['activeYears'] = result.join();
				self.activeYears = result;

				self.api.save("/users/save", userData).then((data) => {
					window.location.reload();
				});
			}
		});
	}

	requestReconnectFileOpener() {
		const modal = this.modalService.open(ModalContentComponent, {
			backdrop: 'static'
		});
		modal.componentInstance.title = 'Bestandsviewer';

		if (this.fileOpenerStatus == "FAILED") {
			modal.componentInstance.message = "We hebben momenteel geen verbinding met de Kloens-bestandsviewer. Controleer of de applicatie gestart is en druk op de knop 'opnieuw proberen'. De bestandsviewer nog niet gedownload? Ga naar 'instellingen' voor meer informatie.";
			modal.componentInstance.button = 'Opnieuw proberen';
		} else {
			modal.componentInstance.message = "De Kloens-portal heeft succesvol verbinding kunnen maken met de Kloens-bestandsviewer applicatie. Gebruik de 'open bestand' knop om bestanden direct te openen vanuit de K-schijf.";
			modal.componentInstance.button = 'Sluiten';
		}

		modal.result.then((result) => {
			this.fileOpenerService.requestReconnect();
		})
			.catch((error) => {

			});
	}

	switchDarkMode() {
		this.darkMode = !this.darkMode;
		if (this.darkMode) {
			self.document.body.classList.add("darkMode")
		} else {
			self.document.body.classList.remove("darkMode")
		}
		localStorage.setItem("darkmode", JSON.stringify({ enabled: this.darkMode }));
	}

	searchProject(keyword) {
		let url = "projects";
		url += '&data[page]=1';
		url += '&data[limit]=999';
		url += '&data[sort]=&data';
		url += '&data[direction]=ASC';

		let filterOption;
		if (keyword.length > 1) {
			url += '&data[keyword]=' + keyword.replace(" ", "+");
		}

		const self = this;

		this.appService.setLoaderStatus(true);

		this.api.save(url, filterOption).then((response) => {
			const searchResultProjects = response['items'];
			// this.appService.setLoaderStatus(false);
			// this.managerDDVisible = true;
			url = "quotes";
			url += '&data[page]=1';
			url += '&data[limit]=999';
			url += '&data[sort]=&data';
			url += '&data[direction]=ASC';

			let filterOption;
			if (keyword.length > 1) {
				url += '&data[keyword]=' + keyword.replace(" ", "+");
			}
			this.api.save(url, filterOption).then((response) => {
				const searchResultQuotes = response['items'];

				self.searchResult = searchResultQuotes.concat(searchResultProjects);
				this.appService.setLoaderStatus(false);
				this.managerDDVisible = true;
			}).catch(function (error) {
			});

		}).catch(function (error) {
		});

		//also get quotes
	}

	doSearch(e) {
		let self = this;
		if (self.globalTimeout != null) {
			clearTimeout(self.globalTimeout);
		}
		self.globalTimeout = setTimeout(function () {
			self.globalTimeout = null;
			self.searchProject(e.target.value);
		}, 500);
	}

	setCategory(event) {
		const idList = this.currentUser['categoryIds'];


		//   if(event != null) {
		// 	this.api.save('users/save', this.currentUser).then((response) => {
		// 	  location.reload();
		// 	});	
		//   }
	}

	setPageDataListener() {
		let self = this;
		self.appService.pageDocumentData.subscribe(data => {
			setTimeout(function () {
				self.documentTitle.setTitle(data['title']);
				self.title = data['title'];
				self.navKey = data['navKey'];
				self.documentMeta.updateTag({ name: 'description', content: data['description'] });

				self.systemMessageText = data['systemMessageText'];
				self.systemMessageType = data['systemMessageType'];

				let loader = data['loading'];
				if (self.loader != loader) {
					if (loader == false) {
						clearTimeout(self.loaderTimeout);
						self.loaderTimeout = setTimeout(function () { self.loader = loader; }, 500);
					}
					else {
						self.loader = loader;
					}
				}

				let systemMessageLoader = data['systemMessageLoading'];
				if (self.systemMessageLoader != systemMessageLoader) {
					if (systemMessageLoader == false) {
						clearTimeout(self.systemMessageTimeout);
						self.systemMessageTimeout = setTimeout(function () { self.systemMessageLoader = systemMessageLoader; }, 2000);
					}
					else {
						self.systemMessageLoader = systemMessageLoader;
						self.appService.setSystemMessageLoading(false);
					}
				}
			}, 100);
		});
	}

	userCategories() {
		let self = this;

		const filterModal = this.modalService.open(UserCategoryComponent, {
			windowClass: 'modal-right',
			backdrop: 'static',
			size: 'lg'
		});
	}

	clearSearch(id) {
		window.open("/projects/detail/" + id, "_self");

		this.managerDDVisible = false;
		this.searchResult = [];
		this.managerDDKeyword = "";
	}

	logout() {
		this.authenticationService.logout();
	}

	toggleDropdown() {
		if (this.profileDropdown === true) {
			this.profileDropdown = false;
		} else {
			this.profileDropdown = true;
		}
	}

	closeMobileMenu() {
		this.mobileMenuVisible = false;
	}
	showMobileMenu() {
		this.mobileMenuVisible = true;
	}

	async checkHashArguments() {
		try {
			let search = location.hash.substring(1);
			let params = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');

			this.urlParameters = params;
		} catch (e) {

		}
	}

	setMetaTags() {
		this.titleTagService.setSocialMediaTags(this.configService.get('front_url'), this.configService.get('system_name'), this.configService.get('page_description'), this.configService.get('intro_image_url'));
	}
}
