import { Component, OnInit, Input, Output, ViewChild } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OrganisationsService } from 'src/app/services/organisations.service';

@Component({
  selector: 'app-modal-select-year',
  templateUrl: './modal-select-year.component.html',
  styleUrls: ['./modal-select-year.component.scss']
})
export class ModalSelectYearComponent implements OnInit {
  @Input() public selectedYears;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  public searchVal = "";

  constructor(
    public activeModal: NgbActiveModal,
    public organisationsService: OrganisationsService
  ) {
  }

  public yearSelectOptions = [];

  ngOnInit(): void {
    this.yearSelectOptions = this.getYearSelectOptions();
  }

  ngAfterViewInit() {
  }

  confirm() {
    this.activeModal.close(this.selectedYears);
  }

  cancel() {
    this.activeModal.close(false);
  }

  close() {
    this.activeModal.close(false);
  }

  toggleArrayItem(a, v) {
    var i = a.indexOf(v);
    if (i === -1)
      a.push(v);
    else
      a.splice(i, 1);
  }

  toggleYear(year) {
    //toggle in array
    this.toggleArrayItem(this.selectedYears, year);
  }

  getYearSelectOptions() {
    const currentYear = new Date().getFullYear() + 1;
    const startYear = 2023;
    const years = [];

    for (let year = currentYear; year >= startYear; year--) {
      years.push(year.toString());
    }

    return years;
  }
}
