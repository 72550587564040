import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiConnectionService } from 'src/app/services/core/api-connection.service';
import { ModalConfirmDeleteComponent } from 'src/app/modals/modal-confirm-delete/modal-confirm-delete.component';
import { ProjectHoursMobileUsersComponent } from './project-hours-mobile-users/project-hours-users.component';
import { AppService } from 'src/app/services/app.service';
import { WeekFormComponent } from 'src/app/components/week-form/week-form.component';

@Component({
  selector: 'app-project-hours-mobile',
  templateUrl: './project-hours-mobile.component.html',
  styleUrls: ['./project-hours-mobile.component.scss']
})
export class ProjectHoursMobileComponent implements OnInit {
  @ViewChild('scrollableTable') scrollableTable!: ElementRef;

  public projectHours;
  @Input() public project;
  public expendedKey = '';
  public lastPeriod = '';
  public edit = false;

  constructor(
    private api: ApiConnectionService,
    private app: AppService,
    private _elementRef: ElementRef,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    const today = new Date();
    const dayOfWeek = today.getDay(); // Sunday: 0, Monday: 1, ..., Saturday: 6
    if (dayOfWeek >= 4 || dayOfWeek == 0) {
      setTimeout(() => {
        const element = this.scrollableTable.nativeElement as HTMLElement;
        element.scrollLeft = element.scrollWidth - element.clientWidth;
      }, 1000);
    }
  }

  ngOnChanges(changes: any) {
    this.all();
  }

  truncateString(str, maxLength) {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "..";
    }
    return str;
  }

  addWeek() {
    let self = this;
    const modal = this.modalService.open(WeekFormComponent, {});
    modal.componentInstance.targetUrl = 'projectHours/addWeek/' + this.project['id'];

    modal.result.then((result) => {
      if (result == false) { return; }
      self.expendedKey = result['period'];
      self.all();
    })
      .catch((error) => {

      });
  }

  setExpended(key) {
    this.expendedKey = key;
  }

  confirmDeleteWeek(item, period) {
    let self = this;

    const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
    deleteModal.componentInstance.message = 'Weet je zeker dat je dit element wilt verwijderen?';
    deleteModal.result.then((result) => {
      if (result) {
        this.deleteWeek(item.id, period);
      }
    });
  }

  deleteWeek(projectId, period) {
    let self = this;
    self.app.setLoaderStatus(true);
    this.api.delete('projectHours/deletePeriod/' + projectId + '/' + period + '').then((response) => {
      self.all();
      self.app.setLoaderStatus(false);
      self.app.setSystemMessageLoading(true);
      self.app.setSystemMessageText('Week verwijderd');
      self.app.setSystemMessageType('DANGER');
    }).catch(function (error) {
      self.app.setLoaderStatus(false);
    });
  }

  confirmDelete(projectHour) {
    let self = this;

    const deleteModal = this.modalService.open(ModalConfirmDeleteComponent);
    deleteModal.componentInstance.message = 'Weet je zeker dat je dit element wilt verwijderen?';
    deleteModal.result.then((result) => {
      if (result) {
        this.delete(projectHour);
      }
    });
  }

  delete(projectHour) {
    let self = this;
    self.app.setLoaderStatus(true);
    this.api.delete('projectHours/delete/' + projectHour.id + '').then((response) => {
      self.all();
      self.app.setLoaderStatus(false);
      self.app.setSystemMessageLoading(true);
      self.app.setSystemMessageText('Medewerker verwijderd');
      self.app.setSystemMessageType('DANGER');
    }).catch(function (error) {
      self.app.setLoaderStatus(false);
    });
  }

  nextRow(hour, userHour, index, day) {
    let self = this;
    this.api.save('projectHours/update/' + userHour.id + '', {
      monday: userHour.monday,
      tuesday: userHour.tuesday,
      wednesday: userHour.wednesday,
      thursday: userHour.thursday,
      friday: userHour.friday,
      saturday: userHour.saturday,
      sunday: userHour.sunday
    }).then(function (response) {
      userHour.total = response['total'];
      hour.total = response['periodTotal'];
    });

    if (this._elementRef.nativeElement.querySelector('#hour-row-' + hour['period'] + '-' + (index + 1) + '-' + day + '')) {
      this._elementRef.nativeElement.querySelector('#hour-row-' + hour['period'] + '-' + (index + 1) + '-' + day + '').focus();
    }
  }

  selectAll(event) {
    event.target.select();
  }

  updateHour(hour, userHour) {
    let self = this;
    self.app.setLoaderStatus(true);
    this.api.save('projectHours/update/' + userHour.id + '', {
      monday: userHour.monday,
      tuesday: userHour.tuesday,
      wednesday: userHour.wednesday,
      thursday: userHour.thursday,
      friday: userHour.friday,
      saturday: userHour.saturday,
      sunday: userHour.sunday
    }).then(function (response) {
      self.app.setLoaderStatus(false);
      userHour.total = response['total'];
      hour.total = response['periodTotal'];
    }).catch(function () {
      self.app.setLoaderStatus(false);
    });
  }

  all() {
    let self = this;
    let id = self.project['id'];

    if (!id) {
      return;
    }

    this.api.read('projects/details/' + id + '/hours').then((response) => {
      self.projectHours = response['items'];
      self.projectHours.forEach(async (item) => {
        if (self.expendedKey === '') {
          self.expendedKey = item['period'];
          self.lastPeriod = item['period'];
        }
      });
    });
  }

  addUserToPeriod(projectHour, week, year) {
    let self = this;

    const modal = this.modalService.open(ProjectHoursMobileUsersComponent, {});

    modal.componentInstance.project = this.project;
    modal.componentInstance.week = week;
    modal.componentInstance.year = year;
    modal.componentInstance.activeUserIds = {};
    projectHour.userIds.forEach(function (value) {
      modal.componentInstance.activeUserIds[value] = value;
    });

    modal.result.then((result) => {
      self.all();
    }).catch((error) => {

    });
  }
  onKeyDownNumeric(event: KeyboardEvent) {
    // Allow backspace, delete, tab, escape, enter, decimal point and numbers only
    if (
      event.keyCode === 46 ||
      event.keyCode === 8 ||
      event.keyCode === 9 ||
      event.keyCode === 27 ||
      event.keyCode === 13 ||
      event.keyCode === 188 ||
      event.keyCode === 190 ||
      event.keyCode === 110 || // Decimal point
      (event.keyCode >= 48 && event.keyCode <= 57) || // Numbers
      (event.keyCode >= 96 && event.keyCode <= 105) // Numpad numbers
    ) {
      // Allow the key press
      return;
    } else {
      // Block the key press
      event.preventDefault();
    }
  }

}