import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from '../../services/core/config.service';
import { Router } from '@angular/router';

import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService {
	public dataSource = new BehaviorSubject({});
	public data = this.dataSource.asObservable();
	public authState = new BehaviorSubject(false);

	isObservingUser = false;
	private userDataPromise: Promise<void> | null = null;

	constructor(
		public router: Router,
		public http: HttpClient,
		public configService: ConfigService
	) {

	}

	load() {
		return this.getCurrentUser().then(function () {
		});
	}

	generateSalt() {
		return Math.random().toString(36).substr(2, 60) + Math.random().toString(36).substr(2, 60);
	}

	isAuthenticated() {
		return this.authState.value;
	}

	setAccessToken(token) {
		localStorage.setItem('accessToken', token);
	}

	setRefreshToken(token) {
		localStorage.setItem('refreshToken', token);
	}

	clearAccessToken() {
		localStorage.removeItem('accessToken');
	}

	clearRefreshToken() {
		localStorage.removeItem('refreshToken');
	}

	getAccessToken() {
		return localStorage.getItem('accessToken');
	}

	getRefreshToken() {
		return localStorage.getItem('refreshToken');
	}

	read(endpoint, retry = true) {
		if (typeof (retry) === 'undefined') {
			retry = true;
		}

		var self = this;

		let apiUrl = this.configService.get('api_url');
		let headers = new HttpHeaders().set('Authorization', 'Bearer: ' + self.getAccessToken() + '');

		return new Promise(function (resolve, reject) {
			self.http.get(apiUrl + '' + endpoint + '', { withCredentials: true, headers: headers })
				.subscribe(data => {
					let response: any = data;
					resolve(response);
				},
					error => {
						if (error.status === 401) {
							self.logout();
							reject(error);
						}
						else if (error.status === 403) {
							self.logout();
							reject(error);
						} else {

						}
					});
		});
	}

	getCurrentUser() {
		if (this.isObservingUser) {
			// If already fetching, return the existing promise
			return this.userDataPromise;
		}

		this.isObservingUser = true;

		// Store the API call promise
		this.userDataPromise = this.read('users/info')
			.then((data) => {
				this.authState.next(true);
				this.dataSource.next(data);
			})
			.catch(() => {
				this.authState.next(false);
			})
			.finally(() => {
				this.isObservingUser = false; // Reset the flag once the call is completed
				this.userDataPromise = null; // Clear the promise reference
			});

		return this.userDataPromise;
	}

	logout() {
		let self = this;

		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');

		self.authState.next(false);
		self.dataSource.next({});
	}

	authenticate(email, password) {
		var self = this;
		let apiUrl = this.configService.get('api_url');
		return new Promise(function (resolve, reject) {
			let headers = new HttpHeaders().set('content-type', 'application/x-www-form-urlencoded');

			self.http.post(apiUrl + 'users/authenticate', "email=" + email + "&password=" + password + "", { headers: headers, withCredentials: true })
				.subscribe(data => {
					let response: any = data;

					self.setAccessToken(data['accessToken']);
					self.setRefreshToken(data['refreshToken']);

					self.getCurrentUser().then(function (data) {
						resolve(response);
					}).catch(function (data) {
						resolve(response);
					});

				},
					error => {
						reject(error);
					});
		});
	}
}
