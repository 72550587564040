<header class="page-header">
	<div class="row">
		<div class="col-12 col-lg-8">
			<h1>Medewerkers</h1>
			<h6>Medewerkers / overzicht</h6>
		</div>
		<div class="col-12 col-lg-4 text-lg-end pt-3">
			<button (click)="openGroups()" class="btn btn-secondary icon-right with-icon me-3"><i class="bi bi-person-gear"></i> Ploegen</button>
			<button (click)="openForm({id: '', organisationId: 'acb1a425-8bdb-11ea-9b3c-002590dfff4c', role: 'USER'})" class="btn btn-success with-icon">Toevoegen <i class="bi bi-plus-circle-fill"></i></button>
		</div>
	</div>
</header>
<app-table-guru
	 [link]="link"
	 [refresh]="refresh"
	 [mapping]="mapping"
	 [editPath]="editPath"
	 [showEditButtons]="true" 
	 (editAction)="editRow($event)"
	 (deleteAction)="deleteRow($event)"
></app-table-guru>