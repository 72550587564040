<section *ngIf="item" [ngClass]="{'loader-overlay': loaderOverlay}">
    <div class="modal-header">
        <h5 class="modal-title">Template toevoegen</h5>
        <button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
    </div>
    <div class="modal-body">
        <div class="form-group mb-2">
            <label for="name" class="pb-1">Naam</label>
            <input type="text" class="form-control" id="name" required [(ngModel)]="item['name']" name="name">
            <div class="error" *ngIf="errors['name']">{{errors['name']}}</div>
        </div>
    </div>
    <div class="modal-footer">
        <button (click)="confirm()" type="button" tabindex="-1" class="btn btn-success">Toevoegen</button>
    </div>
</section>