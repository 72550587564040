<div class="modal-header">
	<h4 class="modal-title" id="modal-basic-title">Verwijderen</h4>
	<button type="button" class="btn-close" (click)="close()" aria-label="Close" tabindex="-1"></button>
</div>
<div class="modal-body" style="max-height: 500px; overflow-y: scroll;">
	{{message}}
	<input type="text" style="opacity:0;">

	<input style="display: inline; width: 100%; margin-top: 15px; margin-bottom: 15px;" type="text"
		class="form-control ng-pristine ng-valid" placeholder="Zoeken op naam" [(ngModel)]="searchVal">

	<ng-container *ngFor="let user of organisations">
		<div class="row" *ngIf="canShow(user['longName'])">
			<div class="col-12 option-row" [ngClass]="selectedOrganisation[user['id']] ? 'active' : ''"
				[ngClass]="{'inactive' : user['active'] == false}"
				style="border-bottom:1px solid #EEEEEE;padding-top:5px;padding-bottom:5px;">
				<div (click)="toggleList(user)">
					<i *ngIf="selectedOrganisation == user" class="bi bi-check-square"
						style="position:relative;top:2px;margin-right:5px;"></i>
					<i *ngIf="selectedOrganisation != user" class="bi bi-square"
						style="position:relative;top:2px;margin-right:5px;opacity:0.5;"></i>
					{{user['longName']}}
				</div>
			</div>
		</div>
	</ng-container>
</div>
<div class="modal-footer">
	<button (click)="cancel()" type="submit" class="btn btn btn-link"
		style="text-decoration:none ! important;color:#000000;">Annuleren</button>
	<button (click)="confirm()" type="submit" class="btn btn-danger" id="delete-btn">Verwijderen</button>
</div>