<section class="p-5" *ngIf="projectHours && projectHours.length < 1">
    <p class="text-center text-muted">Geen urenregistratie beschikbaar</p>
    <p class="text-center">
        <button type="button" class="btn btn-success" (click)="addWeek()">
            <i class="bi bi-plus-lg text-white me-2"></i> Week toevoegen
        </button>
    </p>
</section>
<section class="p-5" *ngIf="!projectHours">
    laden
</section>
<section class="table-grid" *ngIf="projectHours && projectHours.length > 0">
    <section class="table-content"  #scrollableTable>
        <table class="table table-vertical-align" style="margin-bottom:0;">
            <tbody>
                <ng-container *ngFor="let hour of projectHours">
                    <tr class="hour-row">
                        <th style="padding-bottom: 10px;">
                            <i (click)="setExpended('')" class="bi bi-chevron-up icon-pointer me-3 pt-1"
                                *ngIf="expendedKey == hour['period']"></i>
                            <i (click)="setExpended(hour['period'])" class="bi bi-chevron-down icon-pointer me-3 pt-1"
                                *ngIf="expendedKey !== hour['period']"></i>
                            <b>W{{hour['name']}}</b>
                            <br>
                        </th>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <a href="https://api.kloens.app/projectHours/pdf/{{project['id']}}/{{hour['period']}}"
                        target="_blank" class="me-3 d-inline-block" style="position: absolute;
                        right: 65px;"><i class="bi bi-file-earmark-pdf"></i></a>
                        <span (click)="addWeek()" style="    position: absolute;
                        z-index: 3;
                        right: 40px;
                        margin-top: -1px;">
                            <i class="bi bi-plus-lg add2-icon row-icon"></i>
                        </span>
                    </tr>

                    <ng-container *ngFor="let userHour of hour['hours']; let index = index; let last = last;">
                        <tr>
                            <th colspan="4"><span class="userWrapper">{{userHour['userName']}}</span> <b style="display: block;
                                position: absolute;
                                right: 30px;
                                top: 6px;">{{userHour['total']}} uur</b> <span (click)="confirmDelete(userHour)"
                                    class="me-3 row-icon delete2-icon">
                                    <i class="bi bi-trash-fill"></i>
                                </span></th>
                        </tr>
                        <tr class="user-hour-row" [ngClass]="{'d-none': expendedKey !== hour['period'],  last: last}">
                            <td>
                                <div class="wrap">
                                    <small>Ma</small><input type="text"
                                        id="hour-row-{{hour['period']}}-{{index}}-monday" (focus)="selectAll($event)"
                                        (keyup.enter)="nextRow(hour, userHour, index, 'monday')"
                                        (keydown)="onKeyDownNumeric($event)" (blur)="updateHour(hour, userHour)"
                                        [(ngModel)]="userHour['monday']">
                                </div>
                            </td>
                            <td>
                                <div class="wrap">
                                    <small>Di</small><input type="text"
                                        id="hour-row-{{hour['period']}}-{{index}}-tuesday" (focus)="selectAll($event)"
                                        (keyup.enter)="nextRow(hour, userHour, index, 'tuesday')"
                                        (keydown)="onKeyDownNumeric($event)" (blur)="updateHour(hour, userHour)"
                                        [(ngModel)]="userHour['tuesday']">
                                </div>
                            </td>
                            <td>
                                <div class="wrap">
                                    <small>Wo</small><input type="text"
                                        id="hour-row-{{hour['period']}}-{{index}}-wednesday" (focus)="selectAll($event)"
                                        (keyup.enter)="nextRow(hour, userHour, index, 'wednesday')"
                                        (keydown)="onKeyDownNumeric($event)" (blur)="updateHour(hour, userHour)"
                                        [(ngModel)]="userHour['wednesday']">
                                </div>
                            </td>
                            <td>
                                <div class="wrap"><small>Do</small><input type="text"
                                        id="hour-row-{{hour['period']}}-{{index}}-thursday" (focus)="selectAll($event)"
                                        (keyup.enter)="nextRow(hour, userHour, index, 'thursday')"
                                        (keydown)="onKeyDownNumeric($event)" (blur)="updateHour(hour, userHour)"
                                        [(ngModel)]="userHour['thursday']"></div>
                            </td>
                            <td>
                                <div class="wrap"><small>Vr</small><input type="text"
                                        id="hour-row-{{hour['period']}}-{{index}}-friday" (focus)="selectAll($event)"
                                        (keyup.enter)="nextRow(hour, userHour, index, 'friday')"
                                        (keydown)="onKeyDownNumeric($event)" (blur)="updateHour(hour, userHour)"
                                        [(ngModel)]="userHour['friday']"></div>
                            </td>
                            <td>
                                <div class="wrap">
                                    <small>Za</small><input type="text"
                                        id="hour-row-{{hour['period']}}-{{index}}-saturday" (focus)="selectAll($event)"
                                        (keyup.enter)="nextRow(hour, userHour, index, 'saturday')"
                                        (keydown)="onKeyDownNumeric($event)" (blur)="updateHour(hour, userHour)"
                                        [(ngModel)]="userHour['saturday']">
                                </div>
                            </td>
                            <td>
                                <div class="wrap">
                                    <small>Zo</small><input type="text"
                                        id="hour-row-{{hour['period']}}-{{index}}-sunday" (focus)="selectAll($event)"
                                        (keyup.enter)="nextRow(hour, userHour, index, 'sunday')"
                                        (keydown)="onKeyDownNumeric($event)" (blur)="updateHour(hour, userHour)"
                                        [(ngModel)]="userHour['sunday']">
                                </div>
                            </td>
                        </tr>
                    </ng-container>
                    <tr class="user-hour-row" [ngClass]="{'d-none': expendedKey !== hour['period']}">
                        <th colspan="2" (click)="addUserToPeriod(hour, hour['week'], hour['year'])"
                            style="font-weight: normal;" class="cursor-pointer">+
                            Werknemer</th>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </section>
</section>