<section class="table-content" *ngIf="!items">
	<div class="text-center py-5">
		<img style="width:140px;" src="assets/images/loader.gif">
	</div>
</section>

<section class="table-grid" *ngIf="items">
	<section class="table-filter">
		<div class="row">
			<div class="col-6 col-lg-8 d-flex">
				<div class="d-none d-md-block dropdown mb-3 me-2"
					*ngIf="currentUser['role'] == 'ADMIN' && this.path == 'projects' && this.filters.length > 0">
					<button class="btn btn-dark dropdown-toggle" type="button" data-bs-toggle="dropdown"
						(click)="toggleDropdown()">
						Bulk status instellen naar
					</button>
					<ul class="dropdown-menu" [ngClass]="{
					'show': showDropDown == true
					}" style="margin-top:0;margin-left:0;">

						<ng-container *ngFor="let status of statusOptions">
							<a class="dropdown-item" href="javascript:;"
								(click)="bulkSetStatus(status.value)">{{status.name}}</a>
						</ng-container>
					</ul>
				</div>
				<section class="filter-container">
					<span class="btn btn-filter btn-filter-add btn-white with-icon icon-right me-2"
						style="font-weight:600;" (click)="addFilter()"><i class="bi bi-plus text-success"
							style="font-weight:bold;"></i> Filter</span>
					<span class="btn btn-filter py-0 me-2" style="font-weight:600;" *ngFor="let filter of filters">
						<span class="edit-filter" (click)="editFilter(filter['key'])">
							<i class="bi bi-sliders2 text-success with-icon icon-right me-2"></i> <span>
								{{filter['label']}} <small class="text-muted">{{filter['valueText']}}</small></span>
						</span>
						<span class="remove-filter" (click)="removeFilter(filter['key'])"><i
								class="bi bi-trash"></i></span>
					</span>
				</section>
			</div>
			<div class="col-6 col-lg-4 text-end">
				<input type="search" [(ngModel)]="keyword" (input)="doSearch($event)" class="form-control"
					placeholder="Zoeken ... ">
			</div>
		</div>
	</section>
	<section class="table-content">
		<div class="text-center py-5" *ngIf="items && items.length < 1">
			<small>Geen resulaten</small>
		</div>
		<table class="table mb-0" *ngIf="items && items.length > 0">
			<thead class="thead-dark">
				<tr>
					<th *ngFor="let map of mapping" [ngClass]="{'col-sort': map['sort'] === true}"
						(click)="setSort(map['key'], map)" [style.width]="map['width']">
						{{map['label']}}
						<span class="sort-btn" [ngClass]="{'active': map['key'] === sort}">
							<!-- <i class="bi bi-caret-down-fill" *ngIf="direction === 'ASC' && map['key'] === sort"></i>
							<i class="bi bi-caret-up-fill" *ngIf="direction === 'DESC' && map['key'] === sort"></i>
							<i class="bi bi-caret-down-fill" *ngIf="map['key'] !== sort"></i> -->
							<svg xmlns="http://www.w3.org/2000/svg" *ngIf="direction === 'ASC' && map['key'] === sort"
								width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#000" stroke-width="2"
								stroke-linecap="round" stroke-linejoin="round" class="ai ai-ChevronDown">
								<path d="M4 9l8 8 8-8" />
							</svg>
							<svg xmlns="http://www.w3.org/2000/svg" *ngIf="direction === 'DESC' && map['key'] === sort"
								width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#000" stroke-width="2"
								stroke-linecap="round" stroke-linejoin="round" class="ai ai-ChevronUpSmall">
								<path d="M6 14l6-6 6 6" />
							</svg>
							<svg xmlns="http://www.w3.org/2000/svg" *ngIf="map['key'] !== sort" width="16" height="16"
								viewBox="0 0 24 24" fill="none" stroke="#000" stroke-width="2" stroke-linecap="round"
								stroke-linejoin="round" class="ai ai-ChevronDown">
								<path d="M4 9l8 8 8-8" />
							</svg>
						</span>
					</th>
					<th *ngIf="showEditButtons == true">&nbsp;</th>
				</tr>
			</thead>
			<tbody *ngIf="showEditButtons == true">
				<tr *ngFor="let item of items; let i = index">
					<td *ngFor="let map of mapping" class="align-middle">
						<!-- Check if link is included in the mapping -->
						<a *ngIf="map['link']" class="clickable" [href]="map['link'] + item[map['linkParam']]"
							[innerHTML]="transform(item[map['key']])">
						</a>
						<span *ngIf="!map['link'] && !map['click']" [innerHTML]="transform(item[map['key']])">
						</span>
						<span *ngIf="!map['link'] && map['click']" (click)="map['click']($event, item)"
							[innerHTML]="transform(item[map['key']])">
						</span>
					</td>
					<td class="align-middle text-end">
						<span class="table-guru-edit-link" (click)="edit(item)"><i class="bi bi-pencil"></i></span>
						<span class="table-guru-delete-link delete-link" (click)="delete(item)"><i
								class="bi bi-trash"></i></span>
					</td>
				</tr>

				<tr *ngIf="totals != null">
					<td *ngFor="let map of mapping">
						<p *ngIf="totals[map.key]">
							<b>Totaal:</b> {{totals[map.key]}}
						</p>
					</td>
					<td></td>
				</tr>
			</tbody>

			<tbody *ngIf="showEditButtons == false">
				<tr *ngFor="let item of items; let i = index" class="router-link"
					routerLink="/{{editPath}}{{item[editParam]}}">
					<td *ngFor="let map of mapping" class="align-middle">
						<span *ngIf="!map['click']" [innerHTML]="transform(item[map['key']])"></span>
						<span *ngIf="map['click']" (click)="map['click']($event, item)"
							[innerHTML]="transform(item[map['key']])"></span>
					</td>
				</tr>

				<tr *ngIf="totals != null">
					<td *ngFor="let map of mapping">
						<p *ngIf="totals[map.key]">
							<b>Totaal:</b> {{totals[map.key]}}
						</p>
					</td>
				</tr>
			</tbody>
		</table>
	</section>
	<section class="table-pagination pt-4">
		<div class="row">
			<div class="col-6">
				<p><b>Resultaten: {{firstIndex}} - {{lastIndex}} van {{total}}</b></p>
				<div class="d-flex">
					<p style="margin-bottom: 0; margin-top: 2px;"><b>Resultaten per pagina:</b></p>
					<select class="form-select form-select-sm" style="width: 100px; height: 30px; margin-left: 10px;"
						[(ngModel)]="limit" (change)="changeLimit($event)">
						<option *ngFor="let option of limitOptions" [value]="option">{{option}}</option>
					</select>
				</div>
			</div>
			<div class="col-6 justify-content-end d-flex">
				<nav>
					<ul class="pagination">
						<li class="page-item " [ngClass]="{'disabled': page < 2}">
							<span class="page-link" (click)="getPrevious()"><i
									class="bi bi-chevron-double-left"></i></span>
						</li>
						<li *ngFor="let pageNumber of pagesCounter" class="page-item"
							[ngClass]="{'active': pageNumber === page}">
							<span class="page-link" (click)="getPage(pageNumber)">{{pageNumber}}</span>
						</li>
						<li class="page-item" [ngClass]="{'disabled': page == pages}">
							<span class="page-link" (click)="getNext()"><i
									class="bi bi-chevron-double-right"></i></span>
						</li>
					</ul>
				</nav>
			</div>
		</div>
	</section>
</section>