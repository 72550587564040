import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	Renderer2,
	ViewChildren,
	QueryList,
	ElementRef
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ModalConfirmDeleteComponent } from "src/app/modals/modal-confirm-delete/modal-confirm-delete.component";
import { FilePreviewComponent } from "src/app/modals/file-preview/file-preview.component";
import { AppService } from "src/app/services/app.service";
import { ApiConnectionService } from "src/app/services/core/api-connection.service";
import { ConfigService } from "src/app/services/core/config.service";
import { ProjectPermitStatusComponent } from "../project-permit-status/project-permit-status.component";
import { EditFileNameComponent } from "./edit-file-name/edit-file-name.component";
import { AuthenticationService } from "src/app/services/core/authentication.service";
import { DOCUMENT } from "@angular/common";
import { Inject } from "@angular/core";
import {
	CdkDragDrop,
	CdkDragEnter,
	CdkDragMove,
	moveItemInArray,
} from '@angular/cdk/drag-drop';
import { PictureExportComponent } from "./picture-export/picture-export.component";

@Component({
	selector: "app-project-files",
	templateUrl: "./project-files.component.html",
	styleUrls: ["./project-files.component.scss"],
})
export class ProjectFilesComponent implements OnInit {
	@Input() public project;
	@Input() public hideMeta = false;
	@Input() public reload;
	@Input() public mobile;
	@Input() public onlyFiles;
	@Input() public target = "GENERAL";
	@Input() public type = "NORMAL";
	@Input() public fileType = "projectFiles";
	@Input() public isPictureTable = false;
	@Output() reloadAction = new EventEmitter();

	loading = false;
	loaderOverlay = false;
	showDropDown = false;

	files: File[] = [];
	projectFiles;
	projectFilesImages;
	selectedFiles = {};

	selectedFileList = [];

	dropzoneHovered = false;

	fileCategoryOptions;
	targetCategory = "";
	showCategoryChange = false;

	lastCheckbox = null;

	currentUser;

	shuffleMode = false;

	items = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

	imageIndex;

	constructor(
		private appService: AppService,
		private route: ActivatedRoute,
		private authenticationService: AuthenticationService,
		private sanitizer: DomSanitizer,
		private config: ConfigService,
		private api: ApiConnectionService,
		private render: Renderer2,
		private modalService: NgbModal,
		private renderer: Renderer2,
		@Inject(DOCUMENT) private document: Document,
	) {
		this.fileCategoryOptions = [];

		let self = this;
		this.render.listen("window", "click", (evt) => {
			if (!evt.target.classList.contains("dropdown-toggle")) {
				self.showDropDown = false;
			}
		});
		self.authenticationService.getCurrentUser();
		self.authenticationService.data.subscribe((data) => {
			if (Object.keys(data).length) {
				self.currentUser = data;
			} else {
				self.currentUser = false;
			}
		});
	}

	@ViewChildren('inputButton') inputButton: QueryList<ElementRef>;

	handleDragOver(event: DragEvent) {
		event.preventDefault(); // Prevent the default behavior to stop file from opening in browser
		this.dropzoneHovered = true;
	}

	handleFileDrop(event: DragEvent) {
		event.preventDefault(); // Prevent the default behavior on drop

		const files = event.dataTransfer?.files;
		if (files && files.length > 0) {
			this.onSelect({ addedFiles: Array.from(files) }); // Pass the files to your onSelect method
		}
	}

	getCategories() {
		let self = this;
		if (!self.project) {
			return;
		}

		let id = self.project["id"];
		let target = self.target === "GENERAL" ? "" : self.target;

		this.api
			.read("projectFiles/categories/" + id + "")
			.then((response) => {
				self.fileCategoryOptions = response;
			});
	}

	toggleSelectFile(photoObj) {
		const index = this.selectedFileList.indexOf(photoObj);

		if (index === -1) {
			// String is not in the array, so add it
			this.selectedFileList.push(photoObj);
		} else {
			// String is already in the array, so remove it
			this.selectedFileList.splice(index, 1);
		}
	}

	deleteSelectedPictures() {
		const deleteModal = this.modalService.open(ModalConfirmDeleteComponent, {
			windowClass: "second-modal",
			backdropClass: "second-modal-backdrop",
		});
		if (this.selectedFileList.length == 1) {
			deleteModal.componentInstance.message =
			"Weet je zeker dat je de geselecteerde foto wilt verwijderen?";
		} else {
			deleteModal.componentInstance.message =
				"Weet je zeker dat je de " + this.selectedFileList.length + " geselecteerde foto's wilt verwijderen?";
		}
		deleteModal.result.then((result) => {
			if (result) {
				//loop and delete
				this.selectedFileList.forEach(photo => {
					this.delete(photo);
				});

				this.selectedFileList = [];
			}
		});
	}

	transformDate(dateString) {
		const [year, month, day] = dateString.split('-');
		const formattedMonth = parseInt(month) < 10 ? `0${parseInt(month)}` : month;
		const formattedDay = parseInt(day) < 10 ? `0${parseInt(day)}` : day;
		const formattedDate = `${formattedDay}-${formattedMonth}-${year}`;
		return formattedDate;
	}

	ngOnInit(): void {
		this.getFiles();
	}

	moveArrItem(arr, currentIndex, newIndex) {
		if (newIndex >= arr.length) {
			newIndex = arr.length - 1;
		}
		if (currentIndex < 0 || newIndex < 0 || currentIndex >= arr.length) {
			return arr; // Invalid indices, return original array
		}

		const item = arr.splice(currentIndex, 1)[0]; // Remove the item from the current index
		arr.splice(newIndex, 0, item); // Insert the item at the new index
		return arr;
	}


	movePhoto(event: CdkDragDrop<string[]>) {
		// let date = this.getImages()[event.currentIndex];

		// this.loading = true;

		this.projectFilesImages[this.imageIndex] = this.moveArrItem(this.projectFilesImages[this.imageIndex], event.previousIndex, event.currentIndex);

		const objectValues: any = Object.values(this.projectFilesImages)

		// event.item.data.position = event.currentIndex;

		console.log(objectValues.flat())

		let photoIds = [];

		objectValues.flat().forEach(photo => {
			photoIds.push(photo['id']);
		});

		console.log(photoIds)
		this.api.save('projectPhotos/updatePositions', {
			"photoIds": photoIds
		}).then((data) => {

		});
	}

	ngOnChanges(changes: any) {
		this.getCategories();
		this.getFiles();
	}

	transform(value) {
		return this.sanitizer.bypassSecurityTrustHtml(value);
	}

	toggleDropdown() {
		if (this.showDropDown === false) {
			this.showDropDown = true;
		} else {
			this.showDropDown = false;
		}
	}

	handleCategoryChange(event) {
		this.targetCategory = event.target.value;
	}

	setShowCategory(value) {
		this.showCategoryChange = value;
		this.showDropDown = false;
	}

	saveCategoryChange() {
		this.loaderOverlay = true;
		this.showDropDown = false;

		this.showCategoryChange = false;
		this.showDropDown = false;

		console.log(this.targetCategory)

		let self = this;
		for (var prop in this.selectedFiles) {
			if (Object.prototype.hasOwnProperty.call(this.selectedFiles, prop)) {
				self.appService.setLoaderStatus(true);
				self.appService.setSystemMessageType("SUCCESS");
				self.appService.setSystemMessageText("Categorie gewijzigd");

				self.api
					.read(
						"" + self.fileType + "/changeCategory/" +
						prop +
						"/" +
						self.targetCategory +
						""
					)
					.then((response) => {
						self.appService.setLoaderStatus(false);
						self.appService.setSystemMessageLoading(true);

						delete self.selectedFiles[prop];
						self.saveCategoryChange();
					})
					.catch(function (error) {
						self.appService.setLoaderStatus(false);
						delete self.selectedFiles[prop];
						self.getFiles();
						self.loaderOverlay = false;
					});
				return true;
			}
		}
		this.loaderOverlay = false;
		self.getFiles();
	}

	sortByDate(items) {
		var sortedItems = {};

		items.forEach(function (item) {
			//filteredDate
			if (item.filterDate != undefined) {
				var dateKey = item.projectJournalDate ? item.projectJournalDate.substring(0, 10) : item.filterDate.substring(0, 10);

				if (!sortedItems[dateKey]) {
					sortedItems[dateKey] = [];
				}

				sortedItems[dateKey].push(item);
			}
		});

		return sortedItems;
	}

	getFiles() {
		let self = this;
		let id = self.project["id"];
		if (!self.project) {
			return;
		}
		self.projectFiles = [];
		self.dropzoneHovered = false;
		let target = self.target === "GENERAL" ? "" : self.target;
		let type = (self.fileType === 'projectPhotos' ? "photos" : "files");
		this.api
			.read("projects/details/" + id + "/" + type + "/" + target + "")
			.then((response) => {
				self.projectFiles = response["items"];
				self.projectFilesImages = response["items"];

				self.projectFilesImages = this.sortByDate(self.projectFilesImages);

				this.imageIndex = Object.keys(self.projectFilesImages)[0];
			});
	}

	getImages() {
		if (this.imageIndex !== undefined) {
			let images = this.projectFilesImages[this.imageIndex];
			// images.sort((a, b) => a.position - b.position);
			return images;
		} else {
			return null;
		}
	}

	addHash(url) {
		return url + "&hash=" + this.currentUser['publicHash'];
	}

	selectImageIndex(index) {
		let self = this;

		if (this.imageIndex == index) {
			const filterModal = this.modalService.open(PictureExportComponent, {
				size: "lg",
				windowClass: "second-modal",
				backdropClass: "second-modal-backdrop",
				backdrop: "static",
			});

			filterModal.componentInstance.pictures = self.getImages();
			filterModal.componentInstance.project = self.project;
			filterModal.componentInstance.selectedPictures = self.selectedFileList;
			filterModal.result.then((result) => {
			}).catch((error) => { });
		}

		this.imageIndex = index;

		this.selectedFileList = [];
	}

	onSelect(event) {
		let self = this;
		this.loading = true;
		this.files.push(...event.addedFiles);

		const formData = new FormData();

		for (var i = 0; i < this.files.length; i++) {
			formData.append("file[]", this.files[i]);
		}

		self.loaderOverlay = true;
		self.appService.setLoaderStatus(true);
		this.api
			.saveFile(
				"" + self.fileType + "/upload/" + this.project["id"] + "/" + self.target + "",
				formData
			)
			.then(function (response) {
				self.files = [];
				self.loading = false;
				self.getFiles();
				self.loaderOverlay = false;
				self.appService.setLoaderStatus(false);
				self.appService.setSystemMessageLoading(true);
				self.appService.setSystemMessageText("Bestanden zijn opgeslagen");
				self.appService.setSystemMessageType("SUCCESS");
				self.dropzoneHovered = false;
				self.reloadAction.emit(self.project);
			});
	}

	onRemove(event) {
		this.files.splice(this.files.indexOf(event), 1);
	}

	delete(object) {
		let self = this;
		self.appService.setLoaderStatus(true);

		this.api
			.delete("" + self.fileType + "/delete/" + object.id + "")
			.then((response) => {
				this.projectFilesImages[this.imageIndex] = this.projectFilesImages[this.imageIndex].filter(item => item.id !== object.id);
				self.appService.setLoaderStatus(false);
				self.appService.setSystemMessageLoading(true);
				self.appService.setSystemMessageType("DANGER");
				self.appService.setSystemMessageText("Bestand verwijderd");
				self.reloadAction.emit(self.project);
			})
			.catch(function (error) {
				self.appService.setLoaderStatus(false);
			});
	}

	deleteList() {
		this.loaderOverlay = true;
		this.showDropDown = false;
		let self = this;
		for (var prop in this.selectedFiles) {
			if (Object.prototype.hasOwnProperty.call(this.selectedFiles, prop)) {
				self.appService.setLoaderStatus(true);
				self.api
					.delete("" + self.fileType + "/delete/" + prop + "")
					.then((response) => {
						self.appService.setLoaderStatus(false);
						self.appService.setSystemMessageLoading(true);
						self.appService.setSystemMessageType("DANGER");
						self.appService.setSystemMessageText("Bestand verwijderd");
						self.reloadAction.emit(self.project);

						delete self.selectedFiles[prop];
						self.deleteList();
					})
					.catch(function (error) {
						self.appService.setLoaderStatus(false);
						delete self.selectedFiles[prop];
						self.getFiles();
						self.loaderOverlay = false;
					});
				return true;
			}
		}
		this.loaderOverlay = false;
		self.getFiles();
	}

	confirmDelete2(object) {
		let self = this;

		const deleteModal = this.modalService.open(ModalConfirmDeleteComponent, {
			windowClass: "second-modal",
			backdropClass: "second-modal-backdrop",
		});
		deleteModal.componentInstance.message =
			"Weet je zeker dat je bestanden wilt verwijderen?";
		deleteModal.result.then((result) => {
			if (result) {
				self.delete(object);
			}
		});
	}

	confirmDelete() {
		let self = this;

		const deleteModal = this.modalService.open(ModalConfirmDeleteComponent, {
			windowClass: "second-modal",
			backdropClass: "second-modal-backdrop",
		});
		deleteModal.componentInstance.message =
			"Weet je zeker dat je bestanden wilt verwijderen?";
		deleteModal.result.then((result) => {
			if (result) {
				self.deleteList();
			}
		});
	}

	onFilesAdded(event) {
		this.files.push(...event.addedFiles);
		this.dropzoneHovered = false;
		this.readFile(this.files[0]).then((fileContents) => { });
	}

	private async readFile(file: File): Promise<string | ArrayBuffer> {
		return new Promise<string | ArrayBuffer>((resolve, reject) => {
			const reader = new FileReader();

			reader.onload = (e) => {
				return resolve((e.target as FileReader).result);
			};

			reader.onerror = (e) => {
				console.error(`FileReader failed on file ${file.name}.`);
				return reject(null);
			};

			if (!file) {
				console.error("No file to read.");
				return reject(null);
			}

			reader.readAsDataURL(file);
		});
	}

	toggleSelect(e, fileId, spanElement: ElementRef) {
		if (this.selectedFiles && this.selectedFiles[fileId]) {
			delete this.selectedFiles[fileId];
		} else {
			this.selectedFiles[fileId] = fileId;

			if (e.shiftKey) {
				const buttonArr = this.inputButton.toArray();
				const inputButtonArray = buttonArr.map(elRef => elRef.nativeElement);

				let start = inputButtonArray.indexOf(this.lastCheckbox) + 1;
				let end = inputButtonArray.indexOf(spanElement);

				for (let i = start; i < end; i++) {
					const buttonElement = buttonArr[i]?.nativeElement;
					if (buttonElement) {
						buttonElement.click();
					}
				}
			}
		}

		if (!e.shiftKey) {
			this.lastCheckbox = spanElement;
		} else {
			const selection = window.getSelection();
			selection.removeAllRanges();
		}
	}

	showPreview(file, fileList) {
		let link =
			'<iframe src="' +
			file["viewUrl"] +
			'&hash=' + this.currentUser['publicHash'] + '" style="width:100%;height:600px;"></iframe>';
		if (file["isImage"]) {
			link =
				'<img src="' + file["viewUrl"] +
				'&hash=' + this.currentUser['publicHash'] + '" style="height:100%;">';
		}
		const modal = this.modalService.open(FilePreviewComponent, {
			size: "xl",
			backdropClass: 'second-modal-backdrop',
			windowClass: 'second-modal',
		});

		modal.componentInstance.mobile = this.mobile;
		modal.componentInstance.message = link;
		modal.componentInstance.file = file;
		modal.componentInstance.fileList = fileList;
		modal.result.then((result) => { }).catch((error) => { this.document.body.classList.remove('fullscreen-modals'); });
	}

	sanitize(url: string) {
		return this.sanitizer.bypassSecurityTrustUrl(url);
	}

	toggleShuffleMode() {
		this.shuffleMode = !this.shuffleMode;
	}

	editPageProjectPermitStatus(project, type) {
		let self = this;

		const filterModal = this.modalService.open(ProjectPermitStatusComponent, {
			size: "xl",
			backdrop: "static"
		});

		filterModal.componentInstance.mobile = self.mobile;
		filterModal.componentInstance.type = type;
		filterModal.componentInstance.project = project;
		filterModal.result
			.then((result) => {
				this.reloadAction.emit(project);
				self.getFiles();
			})
			.catch((error) => {
				this.reloadAction.emit(project);
			});
	}

	editFilename(project, projectFile) {
		let self = this;

		const filterModal = this.modalService.open(EditFileNameComponent, {
			size: "lg",
			windowClass: "second-modal",
			backdropClass: "second-modal-backdrop",
			backdrop: "static",
		});

		filterModal.componentInstance.project = project;
		filterModal.componentInstance.target = "" + self.fileType + "";
		filterModal.componentInstance.projectFile = projectFile;
		filterModal.result.then((result) => { }).catch((error) => { });
	}
}
